<template>
  <v-card>
    <v-form>
      <v-card-text class="mb-3">
        <v-row>
          <v-col cols="12" sm="2">
            <v-autocomplete v-model="filters.code" :items="customers" :label="$t('code')" deletable-chips hide-details="auto" dense clearable autocomplete="nope" outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="filters.businessName" outlined clearable dense hide-details="auto" @keyup.enter="doApplyFilters" autocomplete="nope" :label="$t('businessName', {r:''})"></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="filters.shopName" outlined clearable dense hide-details="auto" @keyup.enter="doApplyFilters" autocomplete="nope" :label="$t('shopName', {r:''})"></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="filters.email" outlined clearable dense hide-details="auto" @keyup.enter="doApplyFilters" autocomplete="nope" :label="$t('email', {r:''})"></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="filters.vatNumber" outlined clearable dense hide-details="auto" @keyup.enter="doApplyFilters" autocomplete="nope" :label="$t('vatNumber', {r:''})"></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="filters.fiscalCode" outlined clearable dense hide-details="auto" @keyup.enter="doApplyFilters" autocomplete="nope" :label="$t('fiscalCode', {r:''})"></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select v-model="filters.active" dense outlined clearable hide-details="auto" autocomplete="nope" :items="dropdownActive" :label="$t('active')"></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="filters.activationDate" @keyup.enter="doApplyFilters" :label="$t('activationDate')" outlined dense hide-details="auto" @click:append-outer="clearedActivationDate()" append-outer-icon="mdi-calendar"></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete v-model="filters.priceCatalogId" :items="priceCatalogDropdown" :label="$t('priceCatalog')" chips deletable-chips hide-details="auto" dense clearable autocomplete="nope" outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select v-model="filters.potential" dense outlined clearable hide-details="auto" autocomplete="nope" :items="dropdownPotential" :label="$t('potential')"></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select v-model="filters.isExecutiveClient" dense outlined clearable hide-details="auto" autocomplete="nope" :items="dropdownExecutive" :label="$t('executiveCustomers')"></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete v-model="filters.parentCode" :items="parents" :label="$t('parentCode')" deletable-chips hide-details="auto" dense clearable autocomplete="nope" outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" class="px-0 mx-0 py-0 my-0">
            <GeonameSelector
                @update:country="filters.geonameCountryCode = $event"
                @update:region="filters.geonameRegionCode = $event"
                @update:province="filters.geonameProvinceCode = $event"
                @update:city="filters.city = $event"
            ></GeonameSelector>
          </v-col>
          <!-- <v-col cols="12" sm="2">
            <v-select v-model="filters.businessType" :items="businessTypes" :label="$t('businessType')" multiple chips hide-details="auto" dense autocomplete="nope" clearable outlined></v-select>
          </v-col> -->
          <v-col cols="12" sm="2">
            <v-select v-model="filters.salesSectors" :items="dropdownSectors" :label="$t('sector')" multiple chips hide-details="auto" dense autocomplete="nope" clearable outlined></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select v-model="filters.salesChannels" :items="dropdownChannels" :label="$t('channel')" multiple chips hide-details="auto" dense autocomplete="nope" clearable outlined></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete v-model="filters.customerStatisticalTag" :items="dropdownGroupIdentifiers" :label="$t('groupIdentifier')" multiple chips deletable-chips hide-details="auto" dense clearable autocomplete="nope" outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete v-model="filters.tag" :items="dropdownTags" :label="$t('tags')" multiple chips deletable-chips hide-details="auto" dense clearable autocomplete="nope" outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
                v-model="filters.hasEngravers"
                :items="threeWays"
                :label="$t('hasEngravers')"
                hide-details="auto"
                dense
                autocomplete="nope"
                clearable
                outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.ownedByAgents"
                :items="agents"
                :label="$t('agent')"
                multiple
                chips
                deletable-chips
                autocomplete="nope"
                hide-details="auto"
                dense
                clearable
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.ownedBySupervisorAgents"
                :items="agents"
                :label="$t('supervisorAgent')"
                multiple
                chips
                deletable-chips
                hide-details="auto"
                dense
                clearable
                autocomplete="nope"
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.ownedByChiefAgents"
                :items="agents"
                :label="$t('chiefAgent')"
                multiple
                chips
                deletable-chips
                hide-details="auto"
                dense
                clearable
                autocomplete="nope"
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.ownedByCommercialSpecialists"
                :items="commercialSpecialists"
                :label="$t('commercialSpecialist')"
                multiple
                chips
                deletable-chips
                hide-details="auto"
                dense
                autocomplete="nope"
                clearable
                outlined
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="2">
            <v-autocomplete multiple chips deletable-chips v-model="filters.nearestDistributors" :items="allCustomers" :label="$t('nearestDistributor')" hide-details="auto" dense clearable autocomplete="nope" outlined></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="2">
            <v-switch v-model="showMarketingFilters" :label="$t('marketingFilters')"></v-switch>
          </v-col>
          <v-col cols="12" sm="8" v-if="showMarketingFilters">
            <v-card outlined>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="2">
                    <v-select v-model="filters.hasMarketingEmail" dense outlined clearable hide-details="auto" autocomplete="nope" :items="dropdownMarketingEmail" :label="$t('marketingEmail')"></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field v-model="filters.marketingEmail" outlined clearable dense hide-details="auto" autocomplete="nope" :label="$t('marketingEmail')" @keyup.enter="doApplyFilters"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select v-model="filters.hasWebsite" dense outlined clearable autocomplete="nope" hide-details="auto" :items="dropdownWebsite" :label="$t('website')"></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field v-model="filters.website" outlined clearable dense hide-details="auto" autocomplete="nope" :label="$t('website')" @keyup.enter="doApplyFilters"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select v-model="filters.hasFacebookPage" dense outlined clearable hide-details="auto" :items="dropdownFacebook" autocomplete="nope" :label="$t('facebook')"></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field v-model="filters.facebookPage" outlined clearable dense hide-details="auto" autocomplete="nope" :label="$t('facebook')" @keyup.enter="doApplyFilters"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select v-model="filters.hasInstagramAccount" dense outlined clearable autocomplete="nope" hide-details="auto" :items="dropdownInstagram" :label="$t('instagram')"></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field v-model="filters.instagramAccount" outlined clearable dense autocomplete="nope" hide-details="auto" :label="$t('instagram')" @keyup.enter="doApplyFilters"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                      <v-text-field v-model="filters.brandUpdateCheck" outlined clearable dense autocomplete="nope" hide-details="auto" @keyup.enter="doApplyFilters" :label="$t('brandCheck')"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-spacer v-else />
          <v-col cols="12" sm="1">
            <v-btn class="float-right" @click="clearFilters" v-t="'clear'"></v-btn>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn block @click="doApplyFilters" v-t="'apply'"></v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {call, get, sync} from "vuex-pathify";
import GeonameSelector from './GeonameSelector.vue';

export default {
  name: "CustomerFilters",
  components: {
    GeonameSelector
  },
  data: () => ({
    showMarketingFilters: false,
  }),
  computed: {
    families: get('families/dropdownFamilies'),
    customers: get('customers/dropdownAllCustomersCode'),
    allCustomers: get('customers/dropdownAllCustomers'),
    parents: get('customers/dropdownParents'),
    groups: get('groups/dropdownGroups'),
    collections: get('collections/dropdownCollections'),
    businessTypes: get('customers/dropdownBusinessTypes'),
    dropdownGroupIdentifiers: get('customers/dropdownGroupIdentifiers'),
    dropdownTags: get('tags/dropdownTags'),
    priceCatalogDropdown: get("priceCatalogs/dropdownPriceCatalogs"),
    agents: get('agents/dropdownActiveAgents'),
    commercialSpecialists: get('commercialSpecialists/dropdownCommercialSpecialists'),
    showSubAgent: get('tenantConfiguration/showSubAgent'),
    dropdownChannels: get("customers/dropdownChannels"),
    dropdownSectors: get("customers/dropdownSectors"),
    filters: sync("customers/filters"),
    dropdownActive(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('active'), value: true},
        {text: this.$t('inactive'), value: false}
      ]
    },
    dropdownPotential(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('onlyPotentialLeads'), value: true},
        {text: this.$t('onlyEffectiveCustomers'), value: false}
      ]
    },
    dropdownExecutive(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('onlyExecutiveCustomers'), value: true},
        {text: this.$t('notExecutiveCustomers'), value: false}
      ]
    },
    dropdownMarketingEmail(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('withParam', {param:this.$t('marketingEmail')}), value: true},
        {text: this.$t('withoutParam', {param:this.$t('marketingEmail')}), value: false}
      ]
    },
    dropdownWebsite(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('withParam', {param:this.$t('website')}), value: true},
        {text: this.$t('withoutParam', {param:this.$t('website')}), value: false}
      ]
    },
    dropdownFacebook(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('withParam', {param:this.$t('facebook')}), value: true},
        {text: this.$t('withoutParam', {param:this.$t('facebook')}), value: false}
      ]
    },
    dropdownInstagram(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('withParam', {param:this.$t('instagram')}), value: true},
        {text: this.$t('withoutParam', {param:this.$t('instagram')}), value: false}
      ]
    },
    threeWays(){
      return [
        {text: this.$t('unset'), value: null},
        {text: this.$t('yes'), value: true},
        {text: this.$t('no'), value: false}
      ]
    }
  },
  mounted() {
    this.initBusinessTypeCollection();
    this.initFamilyCollection();
    this.initGroupCollection();
    this.initCollectionCollection();
    this.apiCustomerStatisticalTagGet();
    this.initTagCollection();
    this.initPriceCatalogCollection();
    this.initAgentCollection();
    this.initCommercialSpecialistCollection();
    this.initChannelCollection();
    this.initSectorCollection();
  },
  methods: {
    doApplyFilters() {
      this.applyFilters(this.filters);
      this.$emit('applyFilterEvent');
    },
    clearFilters() {
      this.filters = {
        code: null,
        description: null,
        city: null,
        codbase: null,
        color: null,
        size: null,
        barcodePrefix: null,
        barcodeSuffix: null,
        barcode: null,
        active: null,
        obsolete: null,
        decimal: null,
        weight: null,
        collection: null,
        homoCategory: null,
        family: null,
        group: null,
        hasMarketingEmail: null,
        hasWebsite: null,
        hasFacebookPage: null,
        hasInstagramAccount: null,
        parentCode: null
      };
      this.$emit('clearFilterEvent');
      this.applyFilters(this.filters);
    },
    clearedActivationDate() {
      let startDate = this.$moment().startOf('month'),
          todayDate = this.$moment();
      this.filters.activationDate = (this.filters.activationDate ?? '').length > 8? null : `${startDate.format("DDMMYYYY")}..${todayDate.format("DDMMYYYY")}`;
      this.$forceUpdate();
    },
    applyFilters: call('customers/applyFilters'),
    ...call('customers/*'),
    ...call('families', ['initFamilyCollection']),
    ...call('groups', ['initGroupCollection']),
    ...call('collections', ['initCollectionCollection']),
    ...call('tags', ['initTagCollection']),
    ...call('priceCatalogs', ['initPriceCatalogCollection']),
    ...call('agents', ['initAgentCollection']),
    ...call('commercialSpecialists', ['initCommercialSpecialistCollection']),
  }
}
</script>
