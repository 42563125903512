<template>
  <div>
    <v-progress-linear 
      indeterminate 
      color="primary" 
      v-if="loading"
    ></v-progress-linear>
    <v-autocomplete 
      v-else-if="isEdit || disabled" 
      style="display:block;" 
      dense 
      v-model="internalValue" 
      :items="items"
      :clearable="clearable" 
      :placeholder="placeholder"
      :disabled="disabled"
    ><v-icon v-if="inError" class="pulse-round" slot="append" color="red">mdi-asterisk</v-icon></v-autocomplete>
    <p v-else> {{ textValue || this.text }} </p>
  </div>
</template>

<script>
export default {
  name: "AutoComplete",
  props: {
    disabled: Boolean,
    value: [String, Number],
    loading: Boolean,
    isEdit: Boolean,
    field: String,
    items: Array,
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder:{ type: String, default: "" },
  },
  data: () => ({
    text: '----',
  }),
  computed: {
    inError() {
        return this.placeholder.includes('*') && !this.internalValue;
    },
    internalValue: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit('emitUpdate', {field: this.field, value: newValue})
      }
    },
    textValue() {
      return this.items?.find(x => x.value === this.value)?.text;
    }
  },
  inject: {
    theme: {
      default: {isDark: false},
    },
  },
}
</script>

