<template>
  <div>
    <!-- <v-row>
      <v-col cols="12" md="4" v-if="customerId !== 'new' && !customerLocations.length">
        <v-alert border="bottom" colored-border type="warning" elevation="2"> MISSING ADDDRESS </v-alert>
      </v-col>
      <v-col cols="12" md="4" v-if="customerId !== 'new' && !priceCatalogs.length">
        <v-alert border="bottom" colored-border type="warning" elevation="2"> MISSING PRICE CATALOG </v-alert>
      </v-col>
      <v-col cols="12" md="4" v-if="customerId !== 'new' && isInRole(['SupervisorAgent', 'CommercialSpecialist']) && !customerOwnership.length">
        <v-alert border="bottom" colored-border type="warning" elevation="2"> MISSING OWNERSHIP </v-alert>
      </v-col>
      <v-col cols="12" md="12" v-if="customerId !== 'new' && !checkMail()">
        <v-alert border="bottom" colored-border type="warning" elevation="2">EMAIL DOESN'T MEET STANDARDS - UPDATE IT ASAP
        </v-alert>
      </v-col>
    </v-row> -->
    <v-container class="header-container">
      <v-row class="card-container">
        <v-col md="4" sm="12" cols="12">
          <v-card class="pa-5 card-content" outlined flat elevation="3">
            <v-row>
              <v-col md="12" sm="12" cols="12" class="pa-2 std-col">
                <v-badge top overlap color="red" content="Potential" v-if="getCustomer().potential">
                  <v-avatar size="150" :class="{ 'border-isActive': getCustomer().active, 'border-notActive': !getCustomer().active }">
                    <v-img :src="getPic()"></v-img>
                  </v-avatar>
                </v-badge>
                <v-avatar v-else size="150" :class="{ 'border-isActive': getCustomer().active, 'border-notActive': !getCustomer().active }">
                  <v-img :src="getPic()"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="mt-3 std-col">
                <label v-t="'code'"></label>
                <div class="inf-font ml-2">
                  <TextField :value="getCustomer()?.code"></TextField>
                </div>
              </v-col>
              <v-col md="12" sm="12" cols="12" class="std-col">
                <TextField :value="getCustomer()?.businessName"></TextField>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="std-col" v-if="!!getCustomer().fiscalCode">
                <label>{{ $t('fiscalCode', {r:''}) }}</label>
                <div class="inf-font ml-2">
                  <TextField :value="getCustomer()?.fiscalCode"></TextField>
                </div>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="std-col" v-if="!!getCustomer().vatNumber">
                <label>{{ $t('vatNumber', {r:''}) }}</label>
                <div class="inf-font ml-2">
                  <TextField :value="getCustomer()?.vatNumber"></TextField>
                </div>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="std-col">
                <div class="inf-font">
                  <TextField :value="getCustomer()?.email"></TextField>
                </div>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="std-col">
                <div>
                  <div class="inf-font">
                    <TextField :value="getCustomer()?.phone"></TextField>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="std-col">
                <div>
                  <div class="inf-font">
                    <AutoComplete :items="dropdownGroupIdentifiers"
                      :disabled="!getCustomer()?.customerGroupIdentifierTagId"
                      :value="customerGroupIdentifierTagId"></AutoComplete>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="12" class="std-col pb-5">
                <v-row>
                  <v-col style="text-align: right">
                    <v-btn color="primary" @click="openExternal('web')" fab>
                      <v-icon>mdi-web</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col style="text-align: left">
                    <v-btn color="primary" @click="openGoogleSearch()" fab>
                      <v-icon>mdi-google</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12" class="std-col pb-5" v-if="getCustomer().businessTypeId == 2">
                <v-simple-table v-if="!!loadedChainInfo.currenctChildren">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Chain Shops</th>
                        <th class="text-left">Reached Shops</th>
                        <th class="text-left">Missing Shops</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <v-text-field
                            v-model="loadedChainInfo.expectedChildren"
                            dense
                            type="number"
                            hide-details="auto"
                            label=""
                            autocomplete="nope"
                            @change="doApplyChainInfo"
                            @keyup.enter="doApplyChainInfo">
                          </v-text-field>
                        </td>
                        <td>{{ loadedChainInfo.currenctChildren }}</td>
                        <td>{{ loadedChainInfo.missingChildren }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="12" md="12" class="std-col pb-5 mt-5">
                <v-row>
                  <v-col cols="12" md="12">
                    <span>{{ $t('expectedStores') }}: {{ getCustomer().expectedChildren || 1 }}</span>
                  </v-col>
                  <!-- <v-col cols="12" md="12" v-if="getCustomer().potential">
                    <v-btn class="white--text" color="primary" depressed>
                      Self-registered
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="std-col" v-if="!!getCustomer().nikitaTimeZoneId">
                <div class="inf-font">
                  <label v-t="'customerTimeZone'"></label>
                  <TextField :value="getTimeZoneString(getCustomer()?.nikitaTimeZoneId)"></TextField>
                  <label v-t="'yourTime'"></label>
                  <TextField :value="this.currentTimeYours"></TextField>
                  <label v-t="'customerTime'"></label>
                  <TextField :value="this.currentTimeCustomer"></TextField>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col md="8" sm="12" cols="12">
          <v-card class="pa-5 card-content" outlined flat elevation="3">
            <v-row>
              <v-col md="12" sm="12" cols="12">
                <v-alert class="pulse" border="right" color="red" outlined prominent icon="mdi-anchor" text type="warning" v-for="anchor in anchorCollection" :key="anchor.id">
                  <v-row align="center">
                    <v-col class="grow">
                      {{ `${anchor.motivationCode ?? 'CUSTOM'} - ${anchor.motivationDescription}` }}
                    </v-col>
                    <v-col class="shrink">
                      <v-btn v-if="!showOTP && isInRole(['ChiefAgent', 'CommercialSpecialist', 'ServiceAdmin'])" color="error" text @click="showOTP = !showOTP" v-t="'dismiss'"></v-btn>
                      <MFClientOTP 
                        :show="!!showOTP" 
                        :typeText="true" 
                        :callback="isDone"
                        :param="anchor.id" 
                        :textBtn="$t('dropAnchor')" 
                        colorBtn="red lighten-2"
                      ></MFClientOTP>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col md="12" sm="12" cols="12">
                <MFCustomerTagsWrapper :customerId="customerId"></MFCustomerTagsWrapper>
              </v-col>
              <v-col md="12" sm="12" cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <div class="inf-font">
                  <label v-t="'address'"></label>
                  <span v-html="getLocationString()"></span>
                </div>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <label v-t="'ownership'"></label>
                <div class="inf-font">
                  <span v-html="getOwnershipString()"> </span>
                </div>
              </v-col>
              <v-col md="12" sm="12" cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <label v-t="'notes'"></label>
                <div class="inf-font">
                  <TextAreaField :value="getCustomer()?.notes"></TextAreaField>
                </div>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <label v-t="'internalNotes'"></label>
                <div class="inf-font">
                  <TextAreaField :value="getCustomer()?.administrativeNotes"></TextAreaField>
                </div>
              </v-col>
              <v-col md="12" sm="12" cols="12">
                <v-divider></v-divider>
              </v-col>

              <v-col md="5" sm="12" cols="12">
                <label v-t="'priceCatalog'"></label>
                <div class="inf-font" v-for="catalog in priceCatalogs" :key="catalog.id">
                  <div v-if="getFamily(catalog.familyId) == 'MYFAMILY'">
                    <span>{{ getPriceListName(catalog.priceCatalogId) }}</span>
                  </div>
                </div>
              </v-col>
              <v-col md="5" sm="12" cols="12">
                <div class="inf-font">
                  <AutoComplete :value="getCustomer()?.salesSectorId" :items="dropdownSectors"></AutoComplete>
                  <AutoComplete :value="getCustomer()?.salesChannelId" :items="dropdownChannels"></AutoComplete>
                </div>
              </v-col>
              <v-col md="2" sm="12" cols="12">
                <label>B2B</label>
                <div class="inf-font">
                  <span>
                    <CheckBoxField :value="getCustomer()?.isB2b"></CheckBoxField>
                  </span>
                </div>
              </v-col>

              <v-col md="5" sm="12" cols="12">
                <label v-t="'purchaseCategory'"></label>
                <div class="inf-font">
                  <AutoComplete :items="customerPurchaseCategoriesDropdown"
                    :disabled="!getCustomer()?.customerPurchaseCategoryId"
                    :value="getCustomer()?.customerPurchaseCategoryId"></AutoComplete>
                </div>
              </v-col>
              <v-col md="5" sm="12" cols="12">
                <label>{{ $t('invoicingCustomer', {r:''}) }}</label>
                <div class="inf-font">
                  <AutoComplete :items="dropdownCustomers" :field="'defaultInvoicingCustomerId'"
                    :value="getCustomer()?.defaultInvoicingCustomerId"></AutoComplete>
                </div>
              </v-col>
              <v-col md="2" sm="12" cols="12">
                <label v-t="'potential'"></label>
                <div class="inf-font">
                  <CheckBoxField :value="getCustomer()?.potential"></CheckBoxField>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="page-bg" id="body">
      <v-tabs fixed-tabs class="page-bg" v-model="tab">
        <v-tab class="page-bg" href="#info">
          <div v-if="!$vuetify.breakpoint.mobile">
            <v-icon left>mdi-account</v-icon>
            {{ $t('info') }}
          </div>
          <div v-else>
            <v-icon left> mdi-account </v-icon>
          </div>
        </v-tab>
        <v-tab class="page-bg" href="#task">
          <div v-if="!$vuetify.breakpoint.mobile">
            <v-icon left>mdi-checkbox-marked-circle-plus-outline</v-icon>
            {{ $t('taskAndMessages')}}
          </div>
          <div v-else>
            <v-icon left> mdi-checkbox-marked-circle-plus-outline </v-icon>
          </div>
        </v-tab>
        <v-tab class="page-bg" href="#tabl">
          <div v-if="!$vuetify.breakpoint.mobile">
            <v-icon left> mdi-table-large </v-icon>
            {{ $t('tables')}}
          </div>
          <div v-else>
            <v-icon left> mdi-table-large </v-icon>
          </div>
        </v-tab>
    <v-tabs-items v-model="tab">
        <v-tab-item class="page-bg pa-5" value="info">
          <v-row>
            <v-col cols="12" md="7">
              <material-card :heading="$t('addresses')" color="primary" v-if="customerId !== 'new'">
                <v-card-text>
                  <v-row justify="center">
                    <v-data-table :headers="locationsHeaders" :items="customerLocations" :items-per-page="5"
                      class="elevation-1">
                      
                      <template v-slot:item.locationNumber="{ item }">
                        <v-tooltip bottom v-if="haveSomeIssues(item)">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="error" dark v-bind="attrs" v-on="on">mdi-alert</v-icon>
                          </template>
                          <span v-t="'missingFields'"></span>
                        </v-tooltip>
                        {{ item.locationNumber }}
                      </template>

                      <template v-slot:item.default="{ item }">
                        <BooleanIcon :booleanValue="item.default" />
                      </template>
                      <template v-slot:item.provinceName="{ item }">
                        {{ item.provinceName }}
                        <!-- {{ getProvince(item.geonameProvinceCode) }} -->
                      </template>
                      <template v-slot:item.countryName="{ item }">
                        {{ item.countryName }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <MFButtonPill :collection="getCollections(item)"></MFButtonPill>
                      </template>
                    </v-data-table>
                    <v-dialog persistent v-model="locationDialog" width="70vw">
                      <v-card :class="{ blur: isLoadingPostalCodes }">
                        <v-card-title class="headline grey lighten-2" v-t="'location'"></v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12" class="mb-3"></v-col>
                            <v-col cols="12" sm="4">
                              <div>
                                <label>{{ $t('country', {r:''}) }}</label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedLocation.geonameCountryCode" :items="dropdownCountries">
                                  </AutoComplete>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <div>
                                <label v-t="'postalCode'"></label>
                                <div class="inf-font">
                                  <AutoComplete v-if="mappedPostalCodes.length > 0" class="address-text"
                                    :value="selectedLocation.postalCode" :loading="isLoadingPostalCodes"
                                    :items="mappedPostalCodes"></AutoComplete>
                                  <TextField v-else class="address-text" :value="selectedLocation.postalCode"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <div>
                                <label v-t="'city'"></label>
                                <div class="inf-font">
                                  <TextField :value="selectedLocation.city"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <div>
                                <label style="text-transform: capitalize">{{ $t(geonameAdmin1Description) }}</label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedLocation.geonameRegionCode" :items="dropdownRegions">
                                  </AutoComplete>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <div>
                                <label style="text-transform: capitalize">{{ $t(geonameAdmin2Description) }}</label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedLocation.geonameProvinceCode" :items="dropdownProvinces">
                                  </AutoComplete>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <div>
                                <label v-t="'address'"></label>
                                <div class="inf-font">
                                  <TextField :value="selectedLocation.address"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <div>
                                <label v-t="'contactName'"></label>
                                <div class="inf-font">
                                  <TextField :value="selectedLocation.name"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <div>
                                <label v-t="'phone'"></label>
                                <div class="inf-font">
                                  <TextField :value="selectedLocation.phone"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <div>
                                <label>{{ $t('email', {r:''}) }}</label>
                                <div class="inf-font">
                                  <TextField :value="selectedLocation.email"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <div>
                                <label v-t="'active'"></label>
                                <div class="inf-font">
                                  <CheckBoxField :value="selectedLocation.active"></CheckBoxField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <div>
                                <label v-t="'default'"></label>
                                <div class="inf-font">
                                  <CheckBoxField :value="selectedLocation.default"></CheckBoxField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <div>
                                <label v-t="'visible'"></label>
                                <div class="inf-font">
                                  <CheckBoxField :value="selectedLocation.visibility"></CheckBoxField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <div>
                                <label v-t="'hasEngraver'"></label>
                                <div class="inf-font">
                                  <CheckBoxField :value="selectedLocation.engrave"></CheckBoxField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <div>
                                <label v-t="'notes'"></label>
                                <div class="inf-font">
                                  <TextAreaField :value="selectedLocation.note"></TextAreaField>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="locationDialog = false" v-t="'close'"></v-btn>
                          <v-btn v-if="editModeStatus" color="primary" text @click="saveLocation" v-t="'confirm'"></v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                  <v-btn v-if="editModeStatus" color="primary" absolute top right fab @click="addLocation">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-text>
              </material-card>
            </v-col>

            <v-col cols="12" md="5">
              <material-card :heading="$t('priceCatalogs')" color="primary" v-if="customerId !== 'new'">
                <v-card-text>
                  <v-row justify="center">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" v-t="'family'"></th>
                            <th class="text-left" v-t="'priceCatalog'"></th>
                            <th class="text-left" v-if="editModeStatus" v-t="'actions'"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="catalog in priceCatalogs" :key="catalog.id">
                            <td>{{ getFamily(catalog.familyId) }}</td>
                            <td>{{ getPriceListName(catalog.priceCatalogId) }}</td>
                            <td v-if="editModeStatus && isInRole(['SupervisorAgent', 'CommercialSpecialist'])">
                              <v-btn color="primary" fab small @click="openCatalog(catalog)">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn color="error" fab small @click="deleteCatalog(catalog)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <v-dialog persistent v-model="catalogDialog" width="50vw">
                      <v-card>
                        <v-card-title class="headline grey lighten-2" v-t="'priceCatalog'"></v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="6">
                              <div>
                                <label v-t="'family'"></label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedCatalog.familyId" :items="getFamiliesMapped()">
                                  </AutoComplete>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" md="6">
                              <div>
                                <label v-t="'priceCatalog'"></label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedCatalog.catalogId" :items="priceCatalogDropdown">
                                  </AutoComplete>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="catalogDialog = false" v-t="'close'"></v-btn>
                          <v-btn v-if="editModeStatus" color="primary" text @click="saveCatalog" v-t="'confirm'"></v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                  <v-btn v-if="
                    editModeStatus &&
                    isInRole(['SupervisorAgent', 'CommercialSpecialist']) &&
                    !!this.getFamilies().length
                  " color="primary" absolute top right fab @click="addCatalog">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" :md="!!showIban ? 8 : 12" v-if="isInRole(['SupervisorAgent', 'CommercialSpecialist'])">
              <material-card :heading="$t('ownership')" color="primary" v-if="customerId !== 'new'">
                <v-card-text>
                  <v-row justify="center">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" v-t="'family'"></th>
                            <th class="text-left" v-if="showSubAgent" v-t="'subAgent'"></th>
                            <th class="text-left" v-t="'agent'"></th>
                            <th class="text-left" v-t="'supervisorAgent'"></th>
                            <th class="text-left" v-t="'chiefAgent'"></th>
                            <th class="text-left" v-t="'commercialSpecialist'"></th>
                            <th class="text-left" v-if="editModeStatus" v-t="'actions'"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="ownership in customerOwnership" :key="ownership.id">
                            <td>{{ getFamily(ownership.familyId) }}</td>
                            <td v-if="showSubAgent">{{ getAgent(ownership.subagentId) }}</td>
                            <td>{{ getAgent(ownership.agentId) }}</td>
                            <td>{{ getAgent(ownership.supervisorAgentId) }}</td>
                            <td>{{ getAgent(ownership.chiefAgentId) }}</td>
                            <td>{{ getCommercialSpecialist(ownership.commercialSpecialistId) }}</td>
                            <td v-if="editModeStatus">
                              <v-btn color="primary" fab small @click="openOwnership(ownership)">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <v-dialog persistent v-model="ownershipDialog" width="70vw">
                      <v-card>
                        <v-card-title class="headline grey lighten-2" v-t="'ownsership'"></v-card-title>

                        <v-card-text>
                          <v-row v-if="!selectedOwnership.id">
                            <v-col cols="12" sm="12">
                              <div>
                                <label v-t="'family'"></label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedOwnership.familyId" :items="getFamilies()"></AutoComplete>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="3">
                              <div>
                                <label v-t="'subAgent'"></label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedOwnership.subagentId" :items="agentsDropdown">
                                  </AutoComplete>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <div>
                                <label v-t="'agent'"></label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedOwnership.agentId" :items="agentsDropdown"></AutoComplete>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <div>
                                <label v-t="'supervisorAgent'"></label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedOwnership.supervisorAgentId" :items="agentsDropdown">
                                  </AutoComplete>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <div>
                                <label v-t="'chiefAgent'"></label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedOwnership.chiefAgentId" :items="agentsDropdown">
                                  </AutoComplete>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <div>
                                <label v-t="'commercialSpecialist'"></label>
                                <div class="inf-font">
                                  <AutoComplete :value="selectedOwnership.commercialSpecialistId"
                                    :items="commercialSpecialistsDropdown"></AutoComplete>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="ownershipDialog = false" v-t="'close'"></v-btn>
                          <v-btn v-if="editModeStatus" color="primary" text @click="saveOwnership" v-t="'confirm'"></v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                  <v-btn v-if="editModeStatus && !!this.getFamilies().length" color="primary" absolute top right fab
                    @click="addOwnership">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-text>
              </material-card>
            </v-col>

            <v-col cols="12" md="4" v-if="!!showIban">
              <material-card :heading="$t('iban', {r:''})" color="primary" v-if="customerId !== 'new'">
                <v-card-text>
                  <v-row justify="center">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">#</th>
                            <th class="text-left">{{ $t('iban', {r:''}) }}</th>
                            <th class="text-left" v-if="editModeStatus" v-t="'actions'"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="el in customerIbans" :key="el.id">
                            <td>{{ el.id }}</td>
                            <td>{{ el.fullAddress || `${el?.abi ?? 'ABI'}/${el?.cab ?? 'CAB'}` }}</td>
                            <td v-if="editModeStatus">
                              <v-btn color="primary" fab small @click="openIban(el)">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn color="error" fab small @click="deleteIban(el)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <v-dialog persistent v-model="ibanDialog" width="70vw">
                      <v-card>
                        <v-card-title class="headline grey lighten-2">{{ $t('iban', {r:''}) }}</v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="2">
                              <div>
                                <label>{{ $t('country', {r:''}) }}</label>
                                <div class="inf-font">
                                  <TextField :value="selectedIban.country"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="2">
                              <div>
                                <label>CHK</label>
                                <div class="inf-font">
                                  <TextField :value="selectedIban.chk"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="2">
                              <div>
                                <label>CIN</label>
                                <div class="inf-font">
                                  <TextField :value="selectedIban.cin"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="2">
                              <div>
                                <label>ABI</label>
                                <div class="inf-font">
                                  <TextField :value="selectedIban.abi"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="2">
                              <div>
                                <label>CAB</label>
                                <div class="inf-font">
                                  <TextField :value="selectedIban.cab"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="2">
                              <div>
                                <label>CC</label>
                                <div class="inf-font">
                                  <TextField :value="selectedIban.cc"></TextField>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <div>
                                <label>Full Address</label>
                                <div class="inf-font">
                                  <TextField :value="selectedIban.fullAddress"></TextField>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="ibanDialog = false" v-t="'close'"></v-btn>
                          <v-btn v-if="editModeStatus" color="primary" text @click="saveIban" v-t="'confirm'"></v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                  <v-btn v-if="editModeStatus" color="primary" absolute top right fab @click="addIban">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <material-card :heading="$t('generalData')" color="primary">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="3">
                      <div>
                        <label v-t="'code'"></label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.code"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>{{ $t('businessName', {r:''}) }}
                          <span class="required"
                            v-if="isRequired('businessName') && !getCustomer().businessName">*
                          </span>
                        </label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.businessName"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <!-- ex field description -->
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>{{ $t('shopName', {r:''}) }}</label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.shopName"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>{{ $t('email', {r:''}) }}</label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.email"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'phone'"></label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.phone"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'cellphone'"></label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.cellphone"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'fax'"></label>
                        <div class="inf-font">
                          <TextField :field="'fax'" :value="getCustomer()?.fax"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'gender'"></label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.gender"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>{{ $t('fiscalCode', {r:''}) }}
                          <span class="required"
                            v-if="isRequired('fiscalCode') && !getCustomer().fiscalCode">*
                          </span>
                        </label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.fiscalCode"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>{{ $t('vatNumber', {r:''}) }}
                          <span class="required"
                            v-if="isRequired('vatNumber') && !getCustomer().vatNumber">*</span></label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.vatNumber"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'sdi'"></label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.sdiCode"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'parentCode'"></label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.parentCode"></TextField>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <material-card :heading="$t('default')" color="primary">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>{{ $t('paymentTerm', {r:""}) }}
                          <span class="required"
                            v-if="isRequired('defaultPaymentTermId') && !getCustomer().defaultPaymentTermId">*</span></label>
                        <div class="inf-font">
                          <AutoComplete :items="paymentTermsDropdown" :value="getCustomer()?.defaultPaymentTermId">
                          </AutoComplete>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3" v-if="!!showPaymentMethods">
                      <div class="mt-4">
                        <label>{{ $t('paymentMethod', {r:''}) }}
                          <span class="required"
                            v-if="isRequired('defaultPaymentMethodId') && !getCustomer().defaultPaymentMethodId">*</span></label>
                        <div class="inf-font">
                          <AutoComplete :items="paymentMethodsDropdown" :value="getCustomer()?.defaultPaymentMethodId">
                          </AutoComplete>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>{{ $t('vatRate', {r:''}) }}
                          <span class="required"
                            v-if="isRequired('defaultVatRate') && !getCustomer().defaultVatRate">*</span></label>
                        <div class="inf-font">
                          <AutoComplete :items="dropdownVatPercentages" :value="getCustomer()?.defaultVatRate">
                          </AutoComplete>
                        </div>
                      </div>
                    </v-col>

                    <!-- <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'purchaseCategory'"></label>
                        <div class="inf-font">
                          <AutoComplete :items="customerPurchaseCategoriesDropdown"
                            :disabled="!getCustomer()?.customerPurchaseCategoryId"
                            :value="getCustomer()?.customerPurchaseCategoryId"></AutoComplete>
                        </div>
                      </div>
                    </v-col> -->
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>{{ $t('invoicingCustomer', {r:''}) }}</label>
                        <div class="inf-font">
                          <AutoComplete :items="dropdownCustomers" :field="'defaultInvoicingCustomerId'"
                            :value="getCustomer()?.defaultInvoicingCustomerId"></AutoComplete>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'invoicingCustomerLocation'"></label>
                        <div class="inf-font">
                          <AutoComplete :items="invoicingCustomerLocationsDropdown"
                            :disabled="!getCustomer()?.defaultInvoicingCustomerId"
                            :value="getCustomer()?.defaultInvoicingCustomerLocationId"></AutoComplete>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'shippingCustomer'"></label>
                        <div class="inf-font">
                          <AutoComplete :items="dropdownCustomers" :value="getCustomer()?.defaultShippingCustomerId">
                          </AutoComplete>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'shippingCustomerLocation'"></label>
                        <div class="inf-font">
                          <AutoComplete :items="shippingCustomerLocationsDropdown"
                            :disabled="!getCustomer()?.defaultShippingCustomerId"
                            :value="getCustomer()?.defaultShippingCustomerLocationId"></AutoComplete>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <material-card :heading="$t('contractualData')" color="primary" v-if="showContractualData">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'physicalPerson'"></label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.isPhysicalPerson"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>B2b</label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.isB2b"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'executiveClient'"></label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.isExecutiveClient"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'hasPaymentProblems'"></label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.hasPaymentProblems"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'hasEngravers'"></label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.hasEngravers"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'orderLanguage'"></label>
                        <div class="inf-font">
                          {{ getCustomer().orderLanguage ?? '----' }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'checkCoupon'"></label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.checkCoupon"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'excludeFromStats'"></label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.skipStats"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'confirmOrderState'"></label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.checkOrderState"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <label v-t="'potential'"></label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.potential"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'leadStatus'"></label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.leadStatus"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'leadValue'"></label>
                        <div class="inf-font">
                          <NumericField :value="getCustomer()?.leadValue"></NumericField>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'sector'">
                          <span class="required" v-if="isRequired('salesSectorId') && !getCustomer().salesSectorId">*</span></label>
                        <div class="inf-font">
                          <AutoComplete :value="getCustomer()?.salesSectorId" :items="dropdownSectors"></AutoComplete>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'channel'">
                          <span class="required" v-if="isRequired('salesChannelId') && !getCustomer().salesChannelId">*</span></label>
                        <div class="inf-font">
                          <AutoComplete :value="getCustomer()?.salesChannelId" :items="dropdownChannels">
                          </AutoComplete>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'mailOnOrder'"></label>
                        <div class="inf-font">
                          <CheckBoxField :value="getCustomer()?.mailOnOrder"></CheckBoxField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label>{{ $t('accountingEmail', {r:''}) }}</label>
                        <div class="inf-font">
                          <TextField :value="getCustomer()?.accountingEmail"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'minimumOrder'"></label>
                        <div class="inf-font">
                          <NumericField :value="getCustomer()?.minOrder"></NumericField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="mt-4">
                        <label v-t="'purchaseCategory'"></label>
                        <div class="inf-font">
                          <AutoComplete :items="customerPurchaseCategoriesDropdown"
                            :disabled="!getCustomer()?.customerPurchaseCategoryId"
                            :value="getCustomer()?.customerPurchaseCategoryId"></AutoComplete>
                        </div>
                      </div>
                    </v-col>

                    <v-col md="3" cols="12">
                      <label v-t="'nearestDistributor'"></label>
                      <div class="inf-font">
                        <AutoComplete :items="dropdownCustomers" :field="'nearestDistributorId'"
                          :value="getCustomer()?.nearestDistributorId"></AutoComplete>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <material-card :heading="$t('marketingData')" color="primary" v-if="!!showMarketingData">
                <v-card-text>
                  <v-row v-if="editModeStatus">
                    <v-col cols="12" md="3">
                      <div>
                        <label v-t="'marketingEmail'"></label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                            :field="'marketingEmail'" :value="getCustomer()?.marketingEmail" :loading="false"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <label v-t="'website'"></label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                            :field="'website'" :value="getCustomer()?.website" :loading="false"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <label>Facebook</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                            :field="'facebookPage'" :value="getCustomer()?.facebookPage" :loading="false"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <label>Instagram</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                            :field="'instagramAccount'" :value="getCustomer()?.instagramAccount" :loading="false">
                          </TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-menu v-model="menu" :close-on-content-click="true" :nudge-right="40" min-width="auto" offset-y
                        transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :value="brandUpdateFormatted" v-bind="attrs" v-on="on" :label="$t('brandUpdate')"
                            prepend-icon="mdi-calendar" readonly></v-text-field>
                        </template>
                        <v-date-picker v-model="internalBrandUpdate" @change="menu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" sm="12">
                      <span style="display: block">
                        <v-btn class="ma-2" small outlined fab color="primary" @click="openExternal('mail')">
                          <v-icon>mdi-at</v-icon> </v-btn>{{ getCustomer().marketingEmail }}</span>
                      <span style="display: block">
                        <v-btn class="ma-2" small outlined fab color="primary" @click="openExternal('web')">
                          <v-icon>mdi-web</v-icon> </v-btn>{{ getCustomer().website }}</span>
                      <span style="display: block">
                        <v-btn class="ma-2" small outlined fab color="primary" @click="openExternal('facebook')">
                          <v-icon>mdi-facebook</v-icon> </v-btn>{{ getCustomer().facebookPage }}</span>
                      <span style="display: block">
                        <v-btn class="ma-2" small outlined fab color="primary" @click="openExternal('instagram')">
                          <v-icon>mdi-instagram</v-icon> </v-btn>{{ getCustomer().instagramAccount }}</span>
                      <span style="display: block">
                        <v-btn class="ma-2" small outlined fab color="primary" disabled>
                          <v-icon>mdi-calendar-check-outline</v-icon>
                        </v-btn>
                        Last Brand Update checked on {{ brandUpdateFormatted }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="page-bg pa-5" value="task">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="7">
              <material-card :heading="$t('task')" color="primary">
                <v-card-text>
                  <v-sheet>
                    <MFChats :customerId="+this.customerId"></MFChats>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="5">
              <material-card :heading="$t('messages')" color="primary">
                <v-card-text>
                  <v-sheet>
                    <MFMessagesAndLogs :customerId="+this.customerId" :action="openModal"></MFMessagesAndLogs>
                    <WYSIWYG 
                      @emitUpdate="handleLogsAndMessages" 
                      :text="'Confirm'" 
                      :value="value" 
                      :action="comment" 
                      :dialog="showModal" 
                      :closeModal="closeModal"
                    ></WYSIWYG>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12">
              <material-card heading="EDITOR" color="primary">
                <v-card-text>
                  <v-sheet>
                    <TelerikEditor></TelerikEditor>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row> -->
          <!--<v-row>
            <v-col cols="12">
              <material-card heading="CALENDAR" color="primary">
                <v-card-text>
                  <v-sheet>
                    <MFCalendar :customerId="+this.customerId"></MFCalendar>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row> -->
        </v-tab-item>
        <v-tab-item class="page-bg pa-5" value="tabl">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="7">
              <material-card :heading="$t('orders')" color="primary">
                <v-card-text>
                  <v-sheet>
                    <v-data-table :headers="ordersObj.ordersReduceHeader" :items="ordersObj.ordersReduce">
                      <template v-slot:item.acc="{ item }">
                        {{ item.acc | tenantCurrency }}
                      </template>
                      <template v-slot:item.avg="{ item }">
                        {{ item.acc / item.cont | tenantCurrency }}
                      </template>
                    </v-data-table>
                  </v-sheet>
                  <v-sheet>
                    <v-data-table :headers="ordersObj.headers" :items="ordersObj.customerOrders">
                      <template v-slot:item.orderDate="{ item }">
                        {{ item.orderDate | formatDate }}
                      </template>
                      <template v-slot:item.documentCurTotal="{ item }">
                        {{ item.documentCurTotal | tenantCurrency }}
                      </template>
                      <template v-slot:item.code="{ item }">
                        <v-icon small @click="openOrderInNewTab(item)">mdi-open-in-new</v-icon>
                        {{ item.code }}
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="5">
              <material-card :heading="$t('customersChildren')" color="primary">
                <v-card-text>
                  <v-sheet>
                    <v-card-title>
                      <v-text-field v-model="childrenTable.search" append-icon="mdi-magnify" label="Search" single-line
                        hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="childrenTable.headers" :items="getChildren()" :search="childrenTable.search"
                      class="elevation-1">
                      <template v-slot:item.orders="{ item }">
                        <v-chip :color="getColor(item.orders)" dark>{{ item.orders }}</v-chip>
                      </template>
                      <template v-slot:item.businessName="{ item }">
                        <v-icon small @click="openChildInNewTab(item)">mdi-open-in-new</v-icon>
                        {{ item.businessName }}
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <material-card :heading="$t('merchandising')" color="primary">
                <v-card-text>
                  <v-sheet>
                    <v-card-title>
                      <v-text-field v-model="merchTable.search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="merchTable.headers" :items="merch" :search="merchTable.search" class="elevation-1">
                      <template v-slot:item.orderCode="{ item }">
                        <div style="display:flex; flex-direction: row;">
                          <v-icon small @click="openOrderInNewTab(item)">mdi-open-in-new</v-icon>
                          <span>{{ defaultTenant? item.orderCode.substr(-5) : item.orderCode }}</span>
                        </div>
                      </template>
                      <template v-slot:item.orderDate="{ item }">
                        <td style="word-break: keep-all">{{ item.orderDate | formatDate }}</td>
                      </template>
                      <template v-slot:item.itemCode="{ item }">
                        <td>{{ item.itemCode }}</td>
                      </template>
                      <template v-slot:item.itemDescription="{ item }">
                        <td>{{ item.itemDescription }}</td>
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <material-card :heading="$t('machines')" color="primary">
                <v-card-text>
                  <v-sheet>
                    <v-card-title>
                      <v-text-field v-model="macTable.search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="macTable.headers" :items="mac" :search="macTable.search" class="elevation-1">
                      <template v-slot:item.orderCode="{ item }">
                        <div style="display:flex; flex-direction: row;">
                          <v-icon small @click="openOrderInNewTab(item)">mdi-open-in-new</v-icon>
                          <span>{{ defaultTenant? item.orderCode.substr(-5) : item.orderCode }}</span>
                        </div>
                      </template>
                      <template v-slot:item.orderDate="{ item }">
                        <td style="word-break: keep-all">{{ item.orderDate | formatDate }}</td>
                      </template>
                      <template v-slot:item.itemCode="{ item }">
                        <td>{{ item.itemCode }}</td>
                      </template>
                      <template v-slot:item.itemDescription="{ item }">
                        <td>{{ item.itemDescription }}</td>
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>
        </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import { call, get, sync } from "vuex-pathify";
import TextField from "@/components/utility/TextField";
import NumericField from "@/components/utility/NumericField";
import CheckBoxField from "@/components/utility/CheckBoxField";
import TextAreaField from "@/components/utility/TextAreaField";
import AutoComplete from "@/components/utility/AutoComplete";
import MFButtonPill from "@/components/utility/MFButtonPill";
import MFChats from "@/components/utility/MFChats";
import MFMessagesAndLogs from "@/components/utility/MFMessagesAndLogs";
import MFCustomerTagsWrapper from "@/components/utility/MFCustomerTagsWrapper";
import WYSIWYG from "@/components/utility/WYSIWYG";
import MFCalendar from '@/components/utility/MFCalendar';
// import TelerikEditor from '@/components/TelerikEditor';

export default {
  props: ["customerId"],
  components: {
    TextField,
    TextAreaField,
    NumericField,
    CheckBoxField,
    AutoComplete,
    MFButtonPill,
    MFChats,
    MFMessagesAndLogs,
    MFCustomerTagsWrapper,
    WYSIWYG,
    MFCalendar,
    // TelerikEditor,
  },
  name: "CustomerView",
  data: () => ({
    tab: false,
    showOTP: false,
    anchorCollection: [],
    loadedChainInfo: {},
    value: null,
    brandUpdateFormatted: "",
    internalBrandUpdate: "",
    menu: null,
    showModal: false,
    currentCustomer: {
      businessName: null,
      active: true,
      email: null,
      cellphone: null,
    },
    viewedItem: {},
    mappedPostalCodes: [],
    originalPostalCodes: [],
    customerIbans: [],
    merch: [],
    mac: [],
    timer: null,
    locationDialog: false,
    ownershipDialog: false,
    catalogDialog: false,
    ibanDialog: false,
    editModeStatus: false,
    obiettivo: { target: 100000, prc: 34, gained: 34000, animate: true },
    selectedOwnership: {},
    selectedCatalog: {},
    priceCatalogs: [],
    selectedLocation: {},
    locationModel: { locationNumber: -1, name: "", address: "", city: "", county: "", country: "", note: "", phone: "", email: "", active: true, visibility: true, geometry: "", engrave: false, default: false, postalCode: "", enesiLocationId: null, geonameCountryCode: null, geonameRegionCode: null, geonameProvinceCode: null },
    isLoadingPostalCodes: false,
    selectedIban: {},
    ibanModel: { country: "", chk: "", cin: "", abi: "", cab: "", cc: "", fullAddress: "" },
    dropdownLanguages: ["IT", "EN", "ES", "FR", "DE", "PT"],
    customerLocations: [],
    invoicingCustomerLocations: [],
    shippingCustomerLocations: [],
    customerGroupIdentifierTagId: 0,
    currentTimeYours: '',
    currentTimeCustomer: ''
  }),
  created() {
    if (this.customerId === "new") {
      this.editModeStatus = true;
    }
    this.initialize();

    this.updateTime();
    setInterval(this.updateTime, 2000); // Update time every 10 seconds
  },
  methods: {
    doApplyChainInfo: function () {
      this.apiCustomersIdPut({...this.currentCustomer, ...this.loadedChainInfo})
      .finally(() => {
        this.loadedChainInfo = {};
        setTimeout(() => {
          this.apiCustomersIdChildrenStatusGet(this.customerId)
            .then((res) => {
              this.loadedChainInfo.expectedChildren = res.expectedChildren;
              this.loadedChainInfo.currenctChildren = res.currenctChildren;
              this.loadedChainInfo.missingChildren = res.missingChildren;
            })
        }, 1000);
      });
    },  
    checkMail: function () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(this.getCustomer().email);
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    handleLogsAndMessages(obj) {
      this.value = obj.value;
    },
    comment() {
      if (!this.value) return;
      this.apiMessagesCustomerCustomerIdPost({
        title: "",
        body: this.value,
        createdBy: this.$store.state?.auth?.userProfile?.id,
        createdAt: new Date(),
        entityId: +this.customerId
      })
        .then(() => {
          this.value = null;
        })
      this.closeModal();
    },
    getChildren() {
      return this.customers.filter(el => el.parentCode == this.getCustomer().code);
    },
    openOrderInNewTab(item) {
      window.open(`/orders/${item.id || item.orderId}`, '_blank');
    },
    openChildInNewTab(item) {
      window.open(`/customers/${item.code}`, '_blank');
    },
    getColor(param) {
      if (param == 0) return "error";
      else if (param < 4) return "warning";
      else return "success";
    },
    getPic() {
      return !!this.editAgent && !!this.editAgent.picture
        ? this.editAgent.picture
        : require("@/assets/profile_empty.svg");
    },
    getCustomer() {
      if (this.customerId === "new") {
        if (Object.keys(this.currentCustomer).length === 0) {
          this.currentCustomer = {
            businessName: null,
            active: false,
            email: null,
            cellphone: null,
            ...this.userDefaultParameters,
          };
        }
      } else {
        this.currentCustomer = this.currentCustomer || this.customers.find((el) => el.id == this.customerId);
      }
      return this.currentCustomer || { businessName: "" };
    },
    getCollections: function (record) {
      let tempCollection = [
        {
          icon: "mdi-pencil",
          color: "primary",
          action: () => this.openLocation(record),
        },
      ];
      if (this.editModeStatus)
        tempCollection.push({
          icon: "mdi-delete",
          color: "error",
          action: () => this.deleteLocation(record),
        });
      return tempCollection;
    },
    getFamilies: function () {
      return this.families
        .filter((el) => !this.customerOwnership.find((x) => x.familyId == el.id))
        .map((el) => ({ text: `${el.code} - ${el.description}`, value: el.id }));
    },
    getFamiliesMapped: function () {
      return this.families.map((el) => ({ text: `${el.code} - ${el.description}`, value: el.id }));
    },
    getFamily: function (familyId) {
      return this.families.find((el) => el.id == familyId)?.description;
    },
    getAgent: function (id) {
      return this.agents.find((el) => el.id == id)?.name;
    },
    getCommercialSpecialist: function (id) {
      return this.commercialSpecialists.find((el) => el.id == id)?.name;
    },
    getFormattedProvince: function (provinceId) {
      return !provinceId ? "----" : this.apiGeonameProvinceIdGet(provinceId) || provinceId;
    },
    getProvince: function (provinceId) {
      if (!provinceId) return;
      return this.$store.get("geoname/province", provinceId)?.provinceName;
    },
    getAnchors: function() {
      this.apiCustomersIdAnchorsGet(this.customerId)
      .then(res => {
        this.anchorCollection.length = 0;
        this.anchorCollection.push(...res.filter(x => !x.done));
      });
    },
    getAuthUser: function (uuid) {
      const authUser = this.authUsers.find(x => x.uuid === uuid);
      if (!!authUser) {
        return `${authUser.givenName} ${authUser.familyName}`;
      } else {
        return "-";
      }
    },
    isDone: function(id) {
      this.apiAnchorIdIsDonePut(id)
      .then(res => {
        this.getAnchors();
      })
    },
    initialize: function () {

      setTimeout(() => {
        
        if(this.$window.location.hash == '#tags') {
          this.tab = "task";
          let x = document.getElementById("body")?.offsetTop;
          window.scrollTo(0, x);
        }

      }, 3000)

      this.initTimeZonesCollection();

      this.initCustomerCollection().then(() => {
        if (this.customerId !== "new") {

          this.getAnchors();

          this.apiCustomersIdChildrenStatusGet(this.customerId)
          .then((res) => {
            this.loadedChainInfo.expectedChildren = res.expectedChildren;
            this.loadedChainInfo.currenctChildren = res.currenctChildren;
            this.loadedChainInfo.missingChildren = res.missingChildren;
          })

          this.currentCustomer = this.currentCustomer.id
            ? this.currentCustomer
            : this.customers.find((el) => el.id == this.customerId);

          this.loadInvoicingCustomerLocations();
          this.loadShippingCustomerLocations();
          this.initChannelCollection();
          this.initSectorCollection();

          if (this.isInRole(["SupervisorAgent", "CommercialSpecialist"])) { this.apiCustomersIdOwnershipGet(+this.customerId) }

          this.apiMessagesCustomerCustomerIdGet(+this.customerId);

          this.apiCustomersIdIbansGet(+this.customerId).then((response) => {
            this.customerIbans.length = 0;
            this.customerIbans.push(...response);
          });

          this.apiCustomersIdLocationsGet(+this.customerId).then((response) => {
            response.map((el) => !!el.provinceId && this.apiGeonameProvinceIdGet(el.provinceId));
            this.handleLocationsGet(response);
          });

          this.apiCustomerStatisticalDataCustomerTagsGet(+this.customerId).then((tags) => {
            const customerGroupIdentifier = tags.find((x) => x.key === "CustomerGroup");
            if (!!customerGroupIdentifier) {
              this.customerGroupIdentifierTagId = customerGroupIdentifier.id;
            }
          });

          this.apiOrderCustomerIdGet([+this.customerId]).then(res => {
            this.ordersObj.customerOrders.length = 0;
            this.ordersObj.customerOrders.push(...res.orders.sort((a, b) => a.code < b.code ? 1 : -1));
            this.ordersObj.ordersReduce.length = 0;
            this.ordersObj.ordersReduce.push(...Object.values(res.orders.reduce((a, b) => {
              a = a || {};
              let year = `${this.$moment(b.orderDate).get('year')}`;
              a[year] = a[year] ?? { acc: 0, cont: 0, year: year };
              a[year].cont += 1;
              a[year].acc += b.documentCurTotal ?? b.accountingCurSubtotal ?? 0;
              return a;
            }, {})).sort((a, b) => +a.year < +b.year ? 1 : -1));
          });
        }
      });
      this.initOrderCollection();
      this.initCountryCollection();
      this.initPaymentTermCollection();
      this.initPaymentMethodCollection();
      this.initAgentCollection();
      this.initAuthUserCollection();
      this.initPriceCatalogCollection();
      this.apiOrderRowDisplayAndMerchandisingByCustomerCustomerIdGet(this.customerId)
      .then((res) => {
        this.merch.length = 0;
        this.merch.push(...res);
      });
      this.apiOrderRowTagMachinesByCustomerCustomerIdGet(this.customerId)
      .then((res) => {
        this.mac.length = 0;
        this.mac.push(...res);
      });
      this.initFamilyCollection().then((families) => {
        if (this.customerId !== "new") {
          this.setUpCustomersIdPriceCatalogFamilyIdGet(families);
        }
      });
      this.initCommercialSpecialistCollection();
      this.initBusinessTypeCollection();
      this.initPurchaseCategoryCollection();
      this.initFiscalProfileCollection();
      this.apiCustomerStatisticalTagGet();

      this.currentCustomer = {
        ...this.currentCustomer,
        ...this.userDefaultParameters,
      };

      this.locationsHeaders.find((el) => el.value == "provinceName").text =
        this.geonameAdmin2Description.charAt(0).toUpperCase() + this.geonameAdmin2Description.slice(1);
    },
    handleLocationsGet: function (response) {
      this.customerLocations.length = 0;
      this.customerLocations.push(...response.sort((a, b) => +a.locationNumber - +b.locationNumber));
      this.selectedLocation = this.customerLocations[0] || {};
    },
    invertEditMode: function () {
      this.editModeStatus = !this.editModeStatus;
    },
    addOwnership: function () {
      this.selectedOwnership = {};
      this.ownershipDialog = true;
    },
    openOwnership: function (param) {
      this.selectedOwnership = param;
      this.ownershipDialog = true;
    },
    saveOwnership: function () {
      if (!this.selectedOwnership.familyId) return;
      this.apiCustomersIdOwnershipFamilyIdPut({
        customerId: this.customerId,
        familyId: this.selectedOwnership.familyId,
        opts: this.selectedOwnership,
      }).finally(() => {
        this.selectedOwnership = {};
        this.apiCustomersIdOwnershipGet(+this.customerId);
        this.ownershipDialog = !this.ownershipDialog;
      });
    },
    setUpCustomersIdPriceCatalogFamilyIdGet: function (families) {
      Promise.all(
        families.map((fam) =>
          this.apiCustomersIdPriceCatalogFamilyIdGet({
            customerId: +this.customerId,
            familyId: fam.id,
          })
        )
      ).then((response) => {
        this.priceCatalogs.length = 0;
        this.priceCatalogs.push(...response.flat());
      });
    },
    addCatalog: function () {
      this.selectedCatalog = {};
      this.catalogDialog = true;
    },
    openCatalog: function (param) {
      this.selectedCatalog = { ...param };
      this.catalogDialog = true;
    },
    saveCatalog: function () {
      if (!this.selectedCatalog.familyId || !this.selectedCatalog.priceCatalogId) return;
      this.apiCustomersIdPriceCatalogPut({
        customerId: +this.customerId,
        ...this.selectedCatalog,
        isDefault: true,
      }).finally(() => {
        this.selectedCatalog = {};
        this.catalogDialog = !this.catalogDialog;
        this.setUpCustomersIdPriceCatalogFamilyIdGet(this.families);
      });
    },
    deleteCatalog: function (row) {
      this.apiCustomersIdPriceCatalogPriceCatalogIdDelete({
        customerId: +this.customerId,
        priceCatalogId: row.id,
      }).then(() => {
        this.setUpCustomersIdPriceCatalogFamilyIdGet(this.families);
      });
    },
    getPriceListName: function (id) {
      return this.priceCatalogCollection.find((el) => el.id == id)?.description;
    },

    addLocation: function () {
      this.selectedLocation = Object.assign({ customerId: +this.customerId }, this.locationModel);
      this.locationDialog = true;
    },
    openLocation: function (location) {
      this.selectedLocation = location;
      if (!!this.selectedLocation.geonameCountryCode)
        this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
      if (!!this.selectedLocation.geonameRegionCode)
        this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
      this.locationDialog = true;
    },
    deleteLocation: function (el) {
      this.customerLocationsDelete(el.id)
        .then(() => this.apiCustomersIdLocationsGet(this.customerId))
        .then((response) => {
          this.handleLocationsGet(response);
        });
    },
    saveLocation: function () {
      this.selectedLocation.country =
        this.countries.find((el) => el.iso == this.selectedLocation.geonameCountryCode)?.country ||
        this.selectedLocation.country;
      if (typeof this.selectedLocation.postalCode == "number") {
        let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
        this.selectedLocation.city = postalCode.placeName;
        this.selectedLocation.geometry = `{ "type" : 'Point', "coordinates" : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
        this.selectedLocation.geonameRegionCode =
          this.selectedLocation.geonameRegionCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
        this.selectedLocation.geonameProvinceCode =
          this.selectedLocation.geonameProvinceCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
        this.selectedLocation.postalCode = postalCode.postalCode;
      }

      this.selectedLocation.locationNumber =
        this.selectedLocation.locationNumber >= 0
          ? this.selectedLocation.locationNumber
          : this.customerLocations.length;

      if (!!this.selectedLocation.id) {
        this.customerLocationsPut(this.selectedLocation)
          .then(() => {
            this.apiCustomersIdLocationsGet(this.customerId).then((response) => {
              this.handleLocationsGet(response);
            });
          })
          .finally(() => {
            this.clearAndCloseModal();
          });
      } else {
        this.customerLocationsPost(this.selectedLocation)
          .then(() => {
            this.apiCustomersIdLocationsGet(this.customerId).then((response) => {
              this.handleLocationsGet(response);
            });
          })
          .finally(() => {
            this.clearAndCloseModal();
          });
      }
    },

    addIban() {
      this.selectedIban = Object.assign({ customerId: +this.customerId }, this.ibanModel);
      this.ibanDialog = true;
    },
    openIban(iban) {
      this.selectedIban = iban;
      this.ibanDialog = true;
    },
    deleteIban(el) {
      this.apiCustomerIbansIdDelete(el.id).then(() => {
        this.handleIbansGet(+this.customerId);
      });
    },
    saveIban() {
      this.selectedIban.customerId = +this.customerId;
      let promise = !!this.selectedIban.id
        ? this.apiCustomerIbansIdPut(this.selectedIban)
        : this.apiCustomerIbansPost(this.selectedIban);

      promise.then(() => {
        this.handleIbansGet(+this.customerId);
        this.ibanDialog = false;
      });
    },
    handleIbansGet: function (id) {
      this.apiCustomersIdIbansGet(+id).then((response) => {
        this.customerIbans.length = 0;
        this.customerIbans.push(...response);
      });
    },
    clearAndCloseModal: function () {
      this.selectedLocation = Object.assign({ customerId: +this.customerId }, this.locationModel);
      this.mappedPostalCodes.length = 0;
      this.locationDialog = !this.locationDialog;
    },
    saveIn() {
      this.$emit("saveIn", this.currentCustomer);
      if (this.isValidRules4customerDefaultParameters2Check(this.currentCustomer)) return this.showError(this.printError4CustomerDefaultParameters2Check(this.currentCustomer));

      if (this.customerId === "new") {
        delete this.currentCustomer.id;
        this.apiCustomersPost(this.currentCustomer).then((res) => {
          this.$router.push(`/customers/${res.id}`);
          setTimeout(() => this.$window.location.reload(), 10);
        });
      } else {
        this.apiCustomersIdPut(this.currentCustomer);
        this.apiCustomerStatisticalDataCustomerIdTagsTagIdPut({
          id: this.currentCustomer.id,
          tagId: this.customerGroupIdentifierTagId,
        });
        this.editModeStatus = !this.editModeStatus;
      }
    },
    handleLocation: function (obj) {
      this.selectedLocation[obj.field] = obj.value;

      switch (obj.field) {
        case "geonameCountryCode":
          if (!!this.selectedLocation.geonameCountryCode) {
            this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
            if (!!this.selectedLocation.geonameRegionCode)
              this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          }
          break;
        case "geonameRegionCode":
          if (!!this.selectedLocation.geonameRegionCode)
            this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          break;
        case "postalCode":
          if (!obj.value || obj.value.length < 4) this.mappedPostalCodes.length = 0;
          else if (!!obj.value && obj.value.length >= 4) {
            this.isLoadingPostalCodes = true;
            window.clearTimeout(this.timer);
            this.timer = window.setTimeout(() => {
              this.apiGeonamePostalCodeGet({
                countryIsoCode: this.selectedLocation.geonameCountryCode,
                postalCode: obj.value,
              }).then((response) => {
                this.isLoadingPostalCodes = false;
                this.selectedLocation.postalCode = response[0]?.id || this.selectedLocation.postalCode;
                this.originalPostalCodes.push(...response);

                if (typeof this.selectedLocation.postalCode == "number") {
                  let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
                  this.selectedLocation.geometry = `{ type : 'Point', coordinates : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
                  this.selectedLocation.geonameRegionCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
                  this.selectedLocation.geonameProvinceCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
                }

                if (!!this.selectedLocation.geonameCountryCode)
                  this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
                if (!!this.selectedLocation.geonameRegionCode)
                  this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);

                let mappedResponse = response.map((el) => ({
                  text: `${el.postalCode} - ${el.placeName}`,
                  value: el.id,
                }));
                this.mappedPostalCodes.push(...mappedResponse);
                this.selectedLocation.city = this.originalPostalCodes.find(
                  (el) => el.id == this.selectedLocation.postalCode
                )?.placeName;
              });
            }, 2000);
          }

          break;
        // default:
      }
    },
    handleAssignment: function (obj) {
      this.currentCustomer[obj.field] = obj.value;
      if (obj.field === "defaultInvoicingCustomerId") {
        this.currentCustomer.defaultInvoicingCustomerLocationId = null;
        this.loadInvoicingCustomerLocations();
      }
      if (obj.field === "defaultShippingCustomerId") {
        this.currentCustomer.defaultShippingCustomerLocationId = null;
        this.loadShippingCustomerLocations();
      }
      if (obj.field === "customerGroupIdentifierTagId") {
        this.customerGroupIdentifierTagId = obj.value;
      }
    },
    handleOwnership: function (obj) {
      this.selectedOwnership[obj.field] = obj.value;
    },
    handleCatalog: function (obj) {
      this.selectedCatalog[obj.field] = obj.value;
    },
    handleIban: function (obj) {
      this.selectedIban[obj.field] = obj.value;
      if (!this.selectedIban.fullAddress)
        this.selectedIban.fullAddress = `${this.selectedIban.country}${this.selectedIban.chk}${this.selectedIban.cin}${this.selectedIban.abi}${this.selectedIban.cab}${this.selectedIban.cc}`;
    },
    loadInvoicingCustomerLocations() {
      if (!!this.currentCustomer && !!this.currentCustomer.defaultInvoicingCustomerId) {
        this.apiCustomersIdLocationsGet(this.currentCustomer.defaultInvoicingCustomerId).then((response) => {
          this.invoicingCustomerLocations = response;
          if (!this.currentCustomer.defaultInvoicingCustomerLocationId && !!this.editModeStatus) {
            this.currentCustomer.defaultInvoicingCustomerLocationId = this.invoicingCustomerLocations.find(
              (x) => x.default
            )?.id;
          }
        });
      }
    },
    loadShippingCustomerLocations() {
      if (!!this.currentCustomer && !!this.currentCustomer.defaultShippingCustomerId) {
        this.apiCustomersIdLocationsGet(this.currentCustomer.defaultShippingCustomerId).then((response) => {
          this.shippingCustomerLocations = response;
          if (!this.currentCustomer.defaultShippingCustomerLocationId && !!this.editModeStatus) {
            this.currentCustomer.defaultShippingCustomerLocationId = this.shippingCustomerLocations.find(
              (x) => x.default
            )?.id;
          }
        });
      }
    },
    isInRole: function (roles) {
      return this.$store.get("auth/isInRole", roles);
    },
    openExternal: function (type) {
      let url = null;
      switch (type) {
        case "mail":
          url = `mailto:${this.getCustomer().marketingEmail}`;
          break;
        case "web":
          url = `${this.getCustomer().website?.includes("http")
              ? this.getCustomer().website
              : `https://${this.getCustomer().website}`
            }`;
          break;
        case "facebook":
          url = `${this.getCustomer().facebookPage?.includes("http")
              ? this.getCustomer().facebookPage
              : `https://${this.getCustomer().facebookPage}`
            }`;
          break;
          break;
        case "instagram":
          const account = this.getCustomer().instagramAccount.replaceAll("@", "");
          url = `https://instagram.com/${account}`;
          break;
      }
      if (!!url) {
        this.$window.open(url, "_blank");
      }
    },
    isRequired: function (param) {
      return !!this.customerDefaultParameters2Check.find((el) =>
        !!el.length ? !!el.find((x) => x.param == param) : el.param == param
      );
    },
    getOwnershipString: function () {
      let ownership = this.customerOwnership.find((el) => el.familyId == "1");
      return !!ownership
        ? `
       ${this.showSubAgent ? `${ this.$t('subAgent')}: ${this.getAgent(ownership.subagentId) || "----"}<br>` : ``}
       ${ this.$t('agent')}: ${this.getAgent(ownership.agentId) || "----"}<br>
       ${ this.$t('supervisorAgent')}: ${this.getAgent(ownership.supervisorAgentId) || "----"}<br>
       ${ this.$t('chiefAgent')}: ${this.getAgent(ownership.chiefAgentId) || "----"}<br>
       ${ this.$t('commercialSpecialist')}: ${this.getCommercialSpecialist(ownership.commercialSpecialistId) || "----"}
      `
        : "----";
    },
    getLocationString: function () {
      let adr = this.customerLocations.find((el) => el.default);
      return !!adr
        ? `<br>${adr.address || "----"}<br>
      ${adr.city || "----"} (${adr.postalCode || "----"})<br>
      ${adr.provinceName || "----"} (${adr.regionName || "----"})<br>
     ${adr.countryName || "----"}
      `
        : "----";
    },
    openGoogleSearch: function () {
      this.$window.open(`https://www.google.com/search?q=${this.getCustomer()?.businessName}`, "_blank");
    },
    getTimeZoneString: function(id) {
      const tz = this.getTimeZone(id);
      return !!tz ? `${[tz.abbreviationSDT,tz.abbreviationDST].filter(x => !!x).join('/')} (${tz.tzIdentifier})` : '';
    },
    getTimeZone: function(id) {
      if (!!this.timeZones) {
        return this.timeZones.find(x => x.id === id);
      }
    },
    updateTime: function() {
      const tz = this.getTimeZone(this.getCustomer().nikitaTimeZoneId);
      if (!!tz) {
        const now = new Date();
        this.currentTimeYours = this.$moment(now).format('YYYY-MM-DD HH:mm Z');
        this.currentTimeCustomer = this.$moment(now).tz(tz.tzIdentifier).format('YYYY-MM-DD HH:mm Z');
      }
    },
    haveSomeIssues: function(x) {
      return !x.active 
        || !x.address 
        || !x.city 
        || !x.geometry 
        || !x.geonameCountryCode 
        || !x.geonameProvinceCode 
        || !x.geonameRegionCode;
    },
    ...call("customers/*"),
    ...call("geoname/*"),
    ...call("families/*"),
    ...call("customerLocations/*"),
    ...call("customerIbans/*"),
    ...call("orders/*"),
    ...call("agents/*"),
    ...call("commercialSpecialists/*"),
    ...call("customerOwnerships/*"),
    ...call("priceCatalogs/*"),
    ...call("app/*"),
    ...call("authUsers/*"),
    ...call("notifications/*"),
  },
  computed: {
    messages: get("notifications/messages"),
    formattedName: get("authUsers/formattedName"),
    customers: sync("customers/allCustomers"),
    dropdownCustomers: get("customers/dropdownCustomers"),
    dropdownCountries: get("geoname/dropdownCountriesIso"),
    dropdownRegions: get("geoname/dropdownRegions"),
    dropdownProvinces: get("geoname/dropdownProvinces"),
    countries: get("geoname/countries"),
    dropdownVatPercentages: get("vats/dropdownVatPercentages"),
    paymentTermsDropdown: get("orders/dropdownPaymentTerms"),
    paymentMethodsDropdown: get("orders/dropdownPaymentMethods"),
    agentsDropdown: get("agents/dropdownActiveAgents"),
    agents: get("agents/agents"),
    commercialSpecialists: get("commercialSpecialists/commercialSpecialists"),
    commercialSpecialistsDropdown: get("commercialSpecialists/dropdownCommercialSpecialists"),
    customerOwnership: get("customers/customerOwnership"),
    businessTypes: sync("customers/businessTypes"),
    priceCatalogCollection: sync("priceCatalogs/priceCatalogs"),
    dropdownGroupIdentifiers: get("customers/dropdownGroupIdentifiers"),
    customerPurchaseCategoriesDropdown: get("customers/customerPurchaseCategoriesDropdown"),
    orders: get("orders/orders"),
    showSubAgent: sync('tenantConfiguration/showSubAgent'),
    authUsers: get("authUsers/authUsers"),
    timeZones: get("customers/timeZones"),
    businessTypesDropdown() {
      return this.businessTypes.map((x) => ({ text: x.description, value: x.id }));
    },
    fiscalProfiles: sync("customers/fiscalProfiles"),
    fiscalProfilesDropdown() {
      return this.fiscalProfiles.map((x) => ({ text: x.description, value: x.id }));
    },
    priceCatalogDropdown() {
      return this.priceCatalogCollection.map((x) => ({ text: x.code, value: x.id }));
    },
    families: get("families/families"),
    invoicingCustomerLocationsDropdown() {
      return this.invoicingCustomerLocations.map((x) => ({ text: `${x.name} - ${x.address}`, value: x.id }));
    },
    shippingCustomerLocationsDropdown() {
      return this.shippingCustomerLocations.map((x) => ({ text: `${x.name} - ${x.address}`, value: x.id }));
    },
    showIban: get("tenantConfiguration/showIbanSelector"),
    showPaymentMethods: get("tenantConfiguration/showPaymentMethods"),
    showMarketingData: get("tenantConfiguration/showMarketingData"),
    showContractualData: get("tenantConfiguration/showContractualData"),
    userDefaultParameters: get("tenantConfiguration/userDefaultParameters"),
    customerDefaultParameters2Check: get("tenantConfiguration/customerDefaultParameters2Check"),
    rules4customerDefaultParameters2Check: get("tenantConfiguration/rules4customerDefaultParameters2Check"),
    isValidRules4customerDefaultParameters2Check: get("tenantConfiguration/isValidRules4customerDefaultParameters2Check"),
    printError4CustomerDefaultParameters2Check: get("tenantConfiguration/printError4CustomerDefaultParameters2Check"),
    geonameAdmin1Description: get("tenantConfiguration/geonameAdmin1Description"),
    geonameAdmin2Description: get("tenantConfiguration/geonameAdmin2Description"),
    target () {
      const value = this[this.type]
      if (!isNaN(value)) return Number(value)
      else return value
    },
    dropdownChannels: get("customers/dropdownChannels"),
    dropdownSectors: get("customers/dropdownSectors"),
    defaultTenant: get("tenantConfiguration/default"),
    ordersObj(){
      return {
        search: "",
        headers: [
          { text: this.$t('code'), align: "start", value: "code" },
          { text: this.$t('date'), value: "orderDate" },
          { text: this.$t('subTotal'), value: "documentCurTotal" }
        ],
        customerOrders: [],
        ordersReduce: [],
        ordersReduceHeader: [
          { text: this.$t('year'), value: "year" },
          { text: "#", value: "cont" },
          { text: "Avg", value: "avg" },
          { text: this.$t('total'), value: "acc" }
        ],
      }
    },
    locationsHeaders(){
      return [
        { text: "#", value: "locationNumber" },
        { text: this.$t('address'), value: "address" },
        { text: this.$t('city'), value: "city" },
        { text: this.$t('province'), value: "provinceName" },
        { text: this.$t('country', {r:''}), value: "countryName" },
        { text: this.$t('default'), value: "default" },
        { text: this.$t('actions'), value: "actions", sortable: false, align: "center" },
      ]
    },
    childrenTable(){
      return {
        search: "",
        headers: [
          { text: this.$t('code'), align: "start", value: "code" },
          { text: this.$t('businessName', {r:''}), value: "businessName" },
        ],
      }
    },
    merchTable(){
      return {
        search: "",
        headers: [
          { text: this.$t('code'), align: "start", value: "orderCode" },
          { text: this.$t('date'), value: "orderDate" },
          { text: this.$t('itemCode'), value: "itemCode" },
          { text: this.$t('description'), value: "itemDescription" },
          { text: this.$t('qty'), value: "quantity" }
        ],
      }
    },
    macTable(){
      return {
        search: "",
        headers: [
          { text: this.$t('code'), align: "start", value: "orderCode" },
          { text: this.$t('date'), value: "orderDate" },
          { text: this.$t('itemCode'), value: "itemCode" },
          { text: this.$t('description'), value: "itemDescription" },
          { text: this.$t('qty'), value: "quantity" }
        ],
      }
    }
  },
};
</script>

<style scoped>
  .page-bg { background-color: #f5f5f5 !important; }
  .std-col { display: flex; justify-content: center; font-weight: 600; text-align: center; padding: 0; }
  .inf-font { font-size: 18px; color: black; font-weight: 400; }
  .address-text { white-space: nowrap; margin-right: 2em; }
  .header-container { width: 100%; max-width: 100%; margin-bottom: 20px; }
  .border-isActive { border: 8px solid green; }
  .border-notActive { border: 8px solid rgb(192, 33, 33); }
  .required { font-weight: 700; }
  .blur { -webkit-filter: blur(5px); -moz-filter: blur(5px); -o-filter: blur(5px); -ms-filter: blur(5px); filter: blur(5px); }
  .card-content { height: 100%; display: flex; align-items: center; }
  .card-container { min-height: 60vh; }
  
  label { font-size: 1.2rem; font-weight: 500; }

  @media screen and (max-width: 960px) {
    .address-container { flex-direction: column; }
    .overwrite { width: 100%; }
  }

</style>
