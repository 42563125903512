<template>
    <div>
        <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
        <v-text-field style="display:block;" v-else-if="isEdit || disabled" dense v-model="internalValue" :required="required" :rules="rules" :placeholder="placeholder" :disabled="disabled">
            <v-icon v-if="inError" class="pulse-round" slot="append" color="red">mdi-asterisk</v-icon>
        </v-text-field>
        <p v-else>{{ this.value ||  this.text }}</p>
    </div>
</template>

<script>
    export default {
        name : "TextField",
        props: {
            disabled: Boolean,
            rules: [],
            value:[String, Number],
            loading: Boolean,
            isEdit: Boolean,
            field:String,
            required:Boolean,
            placeholder:{ type: String, default: "" },
        },
        data: () => ({
            text: '----',
        }),
        computed: {
            inError() {
                return this.placeholder.includes('*') && !this.internalValue;
            },
            internalValue: {
                get: function() {
                    return this.value;
                },
                set: function(newValue) {

                   this.$emit('emitUpdate', { field: this.field, value : newValue})
                //    this.$emit('blur', { field: this.field, value : newValue})
                }
            },
        },
        inject: {
            theme: {
                default: { isDark: false },
            },
        },
    }
</script>

