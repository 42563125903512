<template>
  <td>
    <button v-show="!!kitCollapsing && !!dataItem.kitCode && dataItem.kitCode === dataItem.itemCode"
            class="k-secondary k-button k-grid-info-command"
            @click="explodeKit">
      {{ $t('kit') }}
    </button>
    <button
        class="k-primary k-button k-grid-remove-command"
        @click="removeHandler">
      {{ $t('remove') }}
    </button>
  </td>
</template>

<script>
export default {
  name: "CommandCell",
  props: {
    dataItem: Object,
    kitCollapsing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    removeHandler: function () {
      this.$emit('remove', {dataItem: this.dataItem});
    },
    explodeKit: function () {
      this.$emit('showKit', {dataItem: this.dataItem});
    }
  }
}
</script>