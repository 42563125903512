<template>
    <v-container style="max-width: 600px;">
        <v-timeline dense clipped>
            <v-timeline-item fill-dot class="white--text mb-12" color="orange" large>
                <template v-slot:icon>
                    <span>JL</span>
                </template>
                <v-text-field v-model="input" hide-details flat label="Leave a comment..." solo @keydown.enter="comment" >
                <template v-slot:append>
                    <v-btn class="mx-0" depressed @click="comment">Post</v-btn>
                </template>
                </v-text-field>
            </v-timeline-item>

            <v-slide-x-transition group>
                <v-timeline-item v-for="el in collection" :key="el.id" class="mb-4" :color="getColor(el)" small>
                    <v-row justify="space-between">
                        <v-col cols="8">
                            <v-chip class="white--text ml-0" color="blue" label small>CLOSED</v-chip>
                            <h3>{{ el.title }}</h3>
                            <p>{{ el.description }}</p>
                        </v-col>
                        <v-col class="text-right" cols="4">
                            {{ getTime(el) }}
                        </v-col>
                    </v-row>
                </v-timeline-item>
            </v-slide-x-transition>
        </v-timeline>
    </v-container>
</template>

<script>
    export default {
        name: "TimeLine",
        props: {
            disabled: { type: Boolean, default: false },
            // collection: Array,
            loading: { type: Boolean, default: false },
        },
        data: () => ({
            input: null,
            nonce: 100,
            collection: [
            ],
        }),
        methods: {
            getColor: (el) => el.closed? 'grey' : 'cyan',
            getTime: (el) => `${this.$moment(el.creationTimestamp) | formatDate }`,
            comment () {
                const time = (new Date()).toTimeString()
                this.events.push({
                    id: this.nonce++,
                    text: this.input,
                    time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => ` ${contents.split(' ').map(v => v.charAt(0)).join('')}`),
                });
                this.input = null
            },
        }
    }
</script>

<style></style>
