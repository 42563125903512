<template>
    <div>
        <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
        <v-select style="display:block;" v-else-if="isEdit && !loading && !disabled" dense v-model="internalValue" :items="items" :placeholder="placeholder"></v-select>
        <p  v-else>{{ this.value ||  this.text }}</p>
    </div>
</template>

<script>
    export default {
        name : "SelectField",
        props: {
            disabled: Boolean,
            value: [String, Number],
            loading: Boolean,
            isEdit: Boolean,
            field: String,
            items: Array,
            placeholder:{ type: String, default: "" },
        },
        data: () => ({
            text: '----',
        }),
        computed: {
            internalValue: {
                get: function() {
                    return this.value;
                },
                set: function(newValue) {
                   this.$emit('emitUpdate', { field: this.field, value : newValue})
                }
            },
        },
        inject: {
            theme: {
                default: { isDark: false },
            },
        },
    }
</script>

