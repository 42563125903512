<template>
  <v-data-table
      :headers="headers"
      :items="orderRows"
      :items-per-page="15"
      dense
      class="elevation-1">
    <template v-slot:item.orderDate="{ item }">
      <td style="font-size: 0.9rem;">{{ item.orderDate | formatDate }}</td>
    </template>
    <template v-slot:item.orderCode="{ item }">
      <td style="font-size: 0.9rem;"><v-icon v-if="!item.isHistoricalOrder" small @click="openOrderInNewTab(item)">mdi-open-in-new</v-icon>{{ item.orderCode }}</td>
    </template>
    <template v-slot:item.documentCurValue="{ item }">
      <td class="text-truncate" style="font-size: 0.9rem;">{{ item.documentCurValue | tenantCurrency }}</td>
    </template>
    <!-- <template v-slot:item.accountingCurValue="{ item }">
      <td class="text-truncate" style="font-size: 0.9rem; text-align: right;">{{ item.accountingCurValue | tenantCurrency }}</td>
    </template> -->
    <template v-slot:item.orderStatisticalTag="{ item }">
      <td class="text-truncate" style="font-size: 0.9rem; text-align: center;">
        <v-chip>{{ item.orderStatisticalTag }}</v-chip>
      </td>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "OrderRowsTable",
  data: () => ({
  }),
  computed: {
    headers: {
      get() {
        if (!!this.explodeGroupsCollections) {
          return [
            {value: 'orderDate', text: this.$t('orderDate')},
            {value: 'orderCode', text: this.$t('code')},
            {value: 'itemGroup', text: this.$t('group')},
            {value: 'itemCollection', text: this.$t('collection')},
            {value: 'quantity', text: this.$t('quantity'), align: 'right'},
            {value: 'documentCurValue', text: this.$t('value'), align: 'right'},
            {value: 'orderStatisticalTag', text: this.$t('orderStatisticalTag'), align: 'right'}
          ];
        } else {
          return [
            {value: 'orderDate', text: this.$t('orderDate')},
            {value: 'orderCode', text: this.$t('code')},
            {value: 'quantity', text: this.$t('quantity'), align: 'right'},
            {value: 'documentCurValue', text: this.$t('value'), align: 'right'},
            {value: 'orderStatisticalTag', text: this.$t('orderStatisticalTag'), align: 'right'}
          ];
        }
      }
    }
  },

  methods: {
    openOrderInNewTab(item) {
      window.open(`/orders/${item.orderId}`, '_blank');
    }
  },
  props: {
    orderRows: Array,
    explodeGroupsCollections: Boolean
  },
}
</script>
