<template>
    <div>
        <v-row class="my-0 py-0">
            <v-col cols="12" sm="6" class="mx-0 px-0 center"><b>LOAN</b></v-col>
            <v-col cols="12" sm="6" class="mx-0 px-0 center">
                <Light :actual="actualLoan" :target="targetLoan" :showValue="true"></Light>
            </v-col>
        </v-row>
        <v-row class="my-0 py-0">
            <v-col cols="12" sm="6" class="mx-0 px-0 center"><b>BUY</b></v-col>
            <v-col cols="12" sm="6" class="mx-0 px-0 center">
                <Light :actual="actualBuy" :target="targetBuy" :showValue="true"></Light>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Light from './Light.vue';
export default {
    name: 'MFTrafficLight',
    components: {
        Light
    },
    props: {
        actualLoan: { type: Number, required: true },
        targetLoan: { type: Number, required: true },
        actualBuy: { type: Number, required: true },
        targetBuy: { type: Number, required: true }
    },
}
</script>
<style>
    .center {
        text-align: center;
    }
</style>