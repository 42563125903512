<template>
  <v-card>
    <v-form>
    <v-card-text class="mb-3">
      <v-row>
        <v-col cols="12" sm="2">
          <v-text-field
              v-model="filters.code"
              outlined
              clearable
              dense
              hide-details="auto"
              :label="$t('code')"
              autocomplete="nope"
              @keyup.enter="doApplyFilters">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
              v-model="filters.barcode"
              outlined
              clearable
              dense
              hide-details="auto"
              :label="$t('barcode')"
              autocomplete="nope"
              @keyup.enter="doApplyFilters">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
              v-model="filters.description"
              outlined
              clearable
              dense
              hide-details="auto"
              @keyup.enter="doApplyFilters"
              autocomplete="nope"
              :label="$t('description')">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
              v-model="filters.active"
              dense
              outlined
              clearable
              hide-details="auto"
              :items="dropdownActive"
              autocomplete="nope"
              :label="$t('active')"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
              v-model="filters.obsolete"
              dense
              outlined
              clearable
              hide-details="auto"
              :items="dropdownObsolete"
              autocomplete="nope"
              :label="$t('obsolete')"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" style="display: flex; padding: 0;">
          <FamilyGroupCollectionSelector
            @update:families="filters.family = $event"
            @update:groups="filters.group = $event"
            @update:collections="filters.collection = $event"
          ></FamilyGroupCollectionSelector>
        </v-col>
        <!-- <v-col cols="12" sm="2">
          <v-select
              v-model="filters.family"
              :items="families"
              :label="$t('family')"
              multiple
              chips
              hide-details="auto"
              dense
              clearable
              autocomplete="nope"
              outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
              v-model="filters.group"
              :items="groups"
              :label="$t('group')"
              multiple
              chips
              dense
              clearable
              outlined
              autocomplete="nope"
              hide-details="auto"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
              v-model="filters.collection"
              :items="collections"
              :label="$t('collection')"
              multiple
              chips
              dense
              clearable
              outlined
              autocomplete="nope"
              hide-details="auto"
          ></v-select>
        </v-col> -->
        <v-col cols="12" sm="1" offset-sm="4">
          <v-btn class="float-right" @click="clearFilters" v-t="'clear'"></v-btn>
        </v-col>
        <v-col cols="12" sm="1">
          <v-btn block @click="doApplyFilters" v-t="'apply'"></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {get, call} from "vuex-pathify";
import FamilyGroupCollectionSelector from './FamilyGroupCollectionFilter.vue';

export default {
  name: "ItemFilters",
  components: {
    FamilyGroupCollectionSelector
  },
  data: () => ({
    filters: {
      code: null,
      description: null,
      codbase: null,
      color: null,
      size: null,
      barcodePrefix: null,
      barcodeSuffix: null,
      barcode: null,
      active: null,
      obsolete: null,
      decimal: null,
      weight: null,
      collection: null,
      homoCategory: null,
      family: null,
      group: null
    },
  }),
  computed: {
    dropdownActive(){
      return [
        { text: this.$t('all'), value: null},
        { text: this.$t('active'), value: true},
        { text: this.$t('inactive'), value: false}
      ]
    },
    dropdownObsolete(){
      return [
        { text: this.$t('all'), value: null},
        { text: this.$t('obsolete'), value: true},
        { text: this.$t('notObsolete'), value: false}
      ]
    },
    families: get('families/dropdownFamilies'),
    groups: get('groups/dropdownGroups'),
    collections: get('collections/dropdownCollections')
  },
  methods: {
    doApplyFilters() {
      this.applyFilters(this.filters);
    },
    clearFilters() {
      this.filters = {
        code: null,
        description: null,
        codbase: null,
        color: null,
        size: null,
        barcodePrefix: null,
        barcodeSuffix: null,
        barcode: null,
        active: null,
        obsolete: null,
        decimal: null,
        weight: null,
        collection: null,
        homoCategory: null,
        family: null,
        group: null
      };
      this.$emit('clearFilterEvent');
    },
    applyFilters: call('items/applyFilters'),
  }
}
</script>