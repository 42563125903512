<template>
  <v-container class="MFMessagesAndLogsContainer">
    <v-btn color="primary" v-if="!isOpenFilter" absolute top right fab @click="isOpenFilter = true">
      <v-icon>mdi-comment-search-outline</v-icon>
    </v-btn>
    <v-timeline dense clipped>
      <v-timeline-item v-if="!isOpenFilter" fill-dot class="white--text mb-12" color="primary" large>
        <template v-slot:icon>
          <v-icon color="white" >mdi-message-outline</v-icon>
        </template>
        <v-text-field v-model="input" hide-details flat :label="$t('leaveComment')" solo @keydown.enter="comment" @click="action">
        </v-text-field>
      </v-timeline-item>
      
      <v-timeline-item v-if="!!isOpenFilter" fill-dot class="white--text mb-12" color="primary" large>
        <template v-slot:icon>
          <v-icon color="white" >mdi-comment-search-outline</v-icon>
        </template>
        <v-text-field v-model="input" hide-details flat :label="$t('search')" solo @keydown.enter="search">
          <template v-slot:append>
            <v-btn class="mx-0" outlined @click="isOpenFilter = false" v-t="'close'"></v-btn>
          </template>
        </v-text-field>
      </v-timeline-item>

      <v-slide-x-transition group>
        <v-timeline-item v-for="message in getTimeline().slice().reverse()" :key="message.id" class="mb-4" color="primary" small>
          <v-row justify="space-between">
            <v-col cols="12" class="creator">{{ formattedName(message.createdBy) }}</v-col>
            <v-col cols="7" v-html="message.body"></v-col>
            <v-col class="text-right" cols="5">
                <p class="small">{{ message.createdAt | formatDate }}</p>
                <p class="small">{{ message.createdAt | formatTime }}</p>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-slide-x-transition>

    </v-timeline>
  </v-container>
</template>

<script>
    import {call, get, sync} from "vuex-pathify";
    export default {
        name: "MFMessagesAndLogs",
        props: { 
          customerId: { type: Number },
          action: Function  
        },
        data: () => ({
            input: null,
            isOpenFilter: false,
        }),
        computed: {     
          messages: get("notifications/messages"),
          formattedName: get("authUsers/formattedName"),
        },
        methods: {
            comment () {
              if(!this.input) return;
              this.apiMessagesCustomerCustomerIdPost({
                title: "",
                body: this.input,
                createdBy: this.$store.state?.auth?.userProfile?.id,
                createdAt: new Date(),
                entityId: +this.customerId
              })
              .then(() => {
                this.input = null;
              })
            },
            getTimeline () {
              return !!this.isOpenFilter && !!this.input && this.input.length && this.input.length > 3?
                this.messages.filter(el => 
                  el.body?.toLowerCase().includes(this.input?.toLowerCase()) ||
                  el.title?.toLowerCase().includes(this.input?.toLowerCase()))
                  .map(el => ({
                    ...el,
                    body: this.highlight(el.body, this.input)
                    // ?.toLowerCase().replace(`${this.input.toLowerCase()}`, `<span class="highlight">${this.input.toLowerCase()}</span>`)
                  }))
                : this.messages
            },
            search() {
            },
            highlight(str, find) {
              var reg = new RegExp('('+find+')', 'gi');
              return str.replace(reg, '<span class="highlight">$1</span>');
            },
            ...call("notifications/*"),
        },
        created() {
            this.apiMessagesCustomerCustomerIdGet(+this.customerId)
        }
    }
</script>
<style>
.MFMessagesAndLogsContainer {
  max-width: 600px; 
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.highlight{
  border:1.5px dashed yellowgreen;  
  background-color: rgba(255, 255, 0, 0.5);
}
.creator{
  padding-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: small;
}
.small{
  font-size: small;
  margin-bottom: 0!important;  
}
</style>
