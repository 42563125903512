<template>
    <div style="width:100%;">
        <v-row>
            <v-col cols="12" :md="getWidth()" sm="12">
                <v-textarea ref="textarea" block v-model="internalValue" :placeholder="placeholder"></v-textarea>
            </v-col>
            <v-dialog v-model="refDialog" persistent max-width="290" >
                <v-card>
                    <v-card-title class="text-h5" v-t="'select'"></v-card-title>
                    <v-card-text>
                        <AutoComplete
                            @emitUpdate="handleAssignment"
                            :isEdit="true"
                            :disabled="false"
                            :value="''"
                            :loading="false"
                            :field="'ref'"
                            :items="dropdownUsers"
                        ></AutoComplete>
                        <!-- getValue('ref') -->
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="tagDialog" persistent max-width="290" >
                <v-card>
                    <v-card-title class="text-h5" v-t="'select'"></v-card-title>
                    <v-card-text>
                        <AutoComplete
                            @emitUpdate="handleAssignment"
                            :isEdit="true"
                            :disabled="false"
                            :value="''"
                            :loading="false"
                            :field="'tag'"
                            :items="dropdownTags"
                        ></AutoComplete>
                        <!-- getValue('tag') -->
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-col cols="12" md="4" sm="12" v-if="getCollections().length">
                <v-list dense>
                    <v-list-item-group color="primary">
                        <v-list-item v-for="(item, i) in getCollections()" :key="i">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {call, get, sync} from "vuex-pathify";
    export default {
        name : "TextAreaFieldAutocomplete",
        components: {},
        props: {
            value:String,
            field:String,
            placeholder:{ type: String, default: "" },
            tagCollection: { type: Array, default: [] },
            refCollection: { type: Array, default: [] },
        },
        data: () => ({
            refDialog: false,
            tagDialog: false,
            currentString: "",
        }),
        methods: {
            handleAssignment(response) {
                this[`${response.field}Collection`].length = 0;
                this[`${response.field}Collection`].push(response.value);
                this[`${response.field}Dialog`] = false;
            },
            getWidth() {
                return !!(this.tagCollection.length + this.refCollection.length)?8:12;
            },
            getCollections() {
                return [
                    ...this.tagCollection.map(el => ({ text: this.dropdownTags.find(x => x.value == el)?.text, icon: 'mdi-flag' })),
                    ...this.refCollection.map(el => ({ text: this.dropdownUsers.find(x => x.value == el)?.text, icon: 'mdi-account' }))
                ]
            },
            extractReferences: (text) => (text || this.value).match(/(@[a-zA-Z0-9._-]+)/gi) || [],
            extractTags: (text) => (text || this.value).match(/(#[a-zA-Z0-9._-]+)/gi) || [],
            ...call("authUsers/*"),
            ...call("tags/*")
        },
        computed: {
            dropdownUsers: get("authUsers/dropdownUsers"),
            dropdownTags: get("tags/dropdownTags"),
            internalValue: {
                get: function() {
                    return this.value;
                },
                set: function(newValue) {
                    let response = {
                        field:this.field, 
                        value: {
                            content:newValue,
                            tagCollection:this.tagCollection,
                            refCollection:this.refCollection
                        }
                    };
                    this.$emit('emitUpdate', response);
                }
            },
        },
        inject: {
            theme: {
                default: { isDark: false },
            },
        },
        created() {
            this.initAuthUserCollection();
            this.initTagCollection();
        },
        mounted: function () {
            this._keyListener = function(e) {
                if (e.key === '@') {
                    e.preventDefault();
                    this.refDialog = true;
                }
                if (e.key === '#') {
                    e.preventDefault();
                    this.tagDialog = true;
                }
            };            
            document.addEventListener('keydown', this._keyListener.bind(this));
        },
        beforeDestroy() { 
            document.removeEventListener('keydown', this._keyListener); 
        },
    }
</script>

<style>
    .fake-textarea {
        background-color: aqua;
        min-height: 200px;
        border-bottom: solid 2px darkslategrey;
    }
</style>
