<template>
    <v-card>
      <v-form>
        <v-card-text class="mb-3">
          <v-row>
            <v-col cols="12" sm="2">
              <v-autocomplete
              v-model="filters.itemCodes"
              :items="items"
              :label="$t('items')"
              multiple
              chips
              deletable-chips
              autocomplete="nope"
              hide-details="auto"
              dense
              clearable
              outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" style="display: flex; padding: 0;">
              <FamilyGroupCollectionSelector
                @update:families="filters.itemFamilies = $event"
                @update:groups="filters.itemGroups = $event"
                @update:collections="filters.itemCollections = $event"
              ></FamilyGroupCollectionSelector>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-select
                  v-model="filters.itemCollections"
                  :items="itemCollections"
                  :label="$t('collection')"
                  multiple
                  chips
                  hide-details="auto"
                  dense
                  autocomplete="nope"
                  clearable
                  outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                  v-model="filters.itemGroups"
                  :items="itemGroups"
                  :label="$t('group')"
                  multiple
                  chips
                  hide-details="auto"
                  dense
                  autocomplete="nope"
                  clearable
                  outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                  v-model="filters.itemFamilies"
                  :items="families"
                  :label="$t('family')"
                  multiple
                  chips
                  hide-details="auto"
                  dense
                  autocomplete="nope"
                  clearable
                  outlined
              ></v-select>
            </v-col> -->
            <v-col cols="12" sm="2">
                <v-text-field
                :label="$t('minParam', {param:$t('qty')})"
                hide-details="auto"
                clearable
                outlined
                dense
                v-model="filters.qtyMin"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
                <v-text-field
                :label="$t('maxParam', {oaram:$t('qty')})"
                hide-details="auto"
                clearable
                outlined
                dense
                v-model="filters.qtyMax"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
                <v-text-field
                :label="$t('minParam', {param:$t('discP')})"
                hide-details="auto"
                clearable
                outlined
                dense
                v-model="filters.rowDiscountPercentageMin"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
                <v-text-field
                :label="$t('maxParam', {param:$t('discP')})"
                hide-details="auto"
                clearable
                outlined
                dense
                v-model="filters.rowDiscountPercentageMax"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
                <v-text-field
                :label="$t('minParam', {param:$t('price')})"
                hide-details="auto"
                clearable
                outlined
                dense
                v-model="filters.documentCurRowOriginalPriceMin"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
                <v-text-field
                :label="$t('maxParam', {param:$t('price')})"
                hide-details="auto"
                clearable
                outlined
                dense
                v-model="filters.documentCurRowOriginalPriceMax"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                  v-model="filters.isGift"
                  :items="threeWaysGift"
                  :label="$t('gift')"
                  hide-details="auto"
                  dense
                  autocomplete="nope"
                  clearable
                  outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                  v-model="filters.isLfu"
                  :items="threeWaysLfu"
                  :label="$t('loan')"
                  hide-details="auto"
                  dense
                  autocomplete="nope"
                  clearable
                  outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </template>
  
<script>
  import {call, get, sync} from "vuex-pathify";
  export default {
    name: "OrderRowFilters",
    components: {},
    data: () => ({
    }),
    computed: {
      threeWaysGift(){
        return [
            {text: this.$t('all'), value: null},
            {text: this.$t('gift'), value: true},
            {text: this.$t('notGift'), value: false},
        ]
      },
      threeWaysLfu(){
        return [
            {text: this.$t('all'), value: null},
            {text: this.$t('loan'), value: true},
            {text: this.$t('notLoan'), value: false},
        ]
      },
      families: get('families/dropdownFamilies'),
      items: get('items/dropdownItems'),
      itemGroups: get('groups/dropdownGroups'),
      itemCollections: get('collections/dropdownCollections'),
      filters: sync('orders/orderRowFilter'),
    },
    mounted() {
      this.initialize();
    },
    methods: {
      initialize() {
        this.initFamilyCollection();
        this.initItemCollection();
      },
      doApplyFilters() {
        this.$emit('applyFilterEvent');
      },
      clearFilters() {
        this.filters = {
          code: null,
          orderDate: null,
          orderTypeId: [],
          customerId: [],
          customerSearchString: null,
          customerLocationId: [],
          commercialSpecialistId: [],
          agentId: [],
          supervisorAgentId: [],
          chiefAgentId: [],
          customerPurchaseOrder: null,
          offerExpirationDate: null,
          estimatedDeliveryDate: null,
          paymentTermId: [],
          documentCurSubtotal: null,
          collectionCharges: null,
          deliveryCharges: null,
          documentCurRowsTotal: null,
          taxable: null,
          vatPercentageId: [],
          taxes: null,
          cashOnDeliveryDiscount: null,
          orderDiscountPercentage: null,
          documentCurOrderDiscountAbsolute: null,
          total: null,
          contextualDeposit: null,
          orderStatusId: null,
          notes: null,
          privateNotes: null,
          familyId: [],
          priceCatalogId: [],
          customerStatisticalTagId: []
        };
        this.$emit('clearFilterEvent');
      },
      ...call('items', ['initItemCollection']),
      ...call('families', ['initFamilyCollection']),
      ...call('customers', ['initCustomerCollection', 'apiCustomerStatisticalTagGet']),
      ...call('agents', ['initAgentCollection']),
      ...call('orders', ['orderTypesGet']),
      ...call('commercialSpecialists', ['initCommercialSpecialistCollection']),
      ...call('geoname', ['initZoneCollection']),
      ...call('customers', ['initBusinessTypeCollection'])
    }
  }
  </script>
  