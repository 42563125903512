<template>
  <material-card :heading="$t(title)" color="primary">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" sm="6" v-for="(el, i) in inputCollection" :key="i">
          <label>{{ el.placeholder }}</label>
          <TextField v-if="el.inputType == 'text'" @emitUpdate="el.emitUpdate" :isEdit="true" :disabled="false" :value="el.value" :loading="false" :field="el.field" :placeholder="el.placeholder"></TextField>
          <TextAreaField v-if="el.inputType == 'textarea'" @emitUpdate="el.emitUpdate" :isEdit="true" :disabled="false" :value="el.value" :loading="false" :field="el.field" :placeholder="el.placeholder"></TextAreaField>
          <CheckBoxField v-if="el.inputType == 'checkbox'" @emitUpdate="el.emitUpdate" :isEdit="true" :disabled="false" :value="el.value" :loading="false" :field="el.field" :placeholder="el.placeholder"></CheckBoxField>
          <AutoComplete v-if="el.inputType == 'autocomplete'" @emitUpdate="el.emitUpdate" :isEdit="true" :disabled="false" :value="el.value" :loading="false" :field="el.field" :items="el.collection" :placeholder="el.placeholder"></AutoComplete>
          <NumericField v-if="el.inputType == 'number'" @emitUpdate="el.emitUpdate" :isEdit="true" :disabled="false" :value="el.value" :loading="false" :field="el.field" :placeholder="el.placeholder"></NumericField>
          
        </v-col>
      </v-row>
    </v-card-text>
  </material-card>
</template>

<script>
import TextField from "@/components/utility/TextField";
import TextAreaField from "@/components/utility/TextAreaField";
import CheckBoxField from "@/components/utility/CheckBoxField";
import AutoComplete from "@/components/utility/AutoComplete";
import NumericField from "@/components/utility/NumericField";

export default {
  name: "MFCustomCard",
  components: {
    TextField,
    TextAreaField,
    CheckBoxField,
    AutoComplete,
    NumericField
  },
  props: {
    inputCollection: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    initialize: function () {},
  },
  created() {
    this.initialize();
  },
  data: () => ({}),
};
</script>

<style></style>
