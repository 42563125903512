<template>
  <div class="text-center">
    <v-card>
        <v-tooltip bottom v-for="(el, i) in tags" :key="i">
            <template v-slot:activator="{ on, attrs }">
                <v-chip class="ma-2" color="primary" label v-bind="attrs" v-on="on">{{ el.tag.value }}</v-chip>
            </template>
            <span>{{ el.tag.key.replaceAll("_", " ") }} - {{ el.tag.value }} - {{ $t('since') }} {{ el.attributedOn | formatDate }}</span>
        </v-tooltip>
        <MFCustomerTags :customerId="customerId" @updatedCollection="initialize"></MFCustomerTags>
    </v-card>
  </div>
</template>

<script>
import {call, get, sync} from "vuex-pathify";
import MFCustomerTags from '@/components/utility/MFCustomerTags';
export default {
    name: "MFCustomerTagsWrapper",
    components: { MFCustomerTags },
    props: {
        customerId: {
            type: String || Number,
            required: true
        }
    },
    data: () => ({
        tags: []
    }),
    computed: {
        tagCollection: sync("tags/tags"),
    },
    methods: {
        initialize: function() {
            this.apiCustomersIdTagsGet(this.customerId)
            .then((response) => {
                this.tags.length = 0;
                this.tags.push(...response);
            });
        },
        ...call("customers", ["apiCustomersIdTagsGet"]),
    },
    created(){
        this.initialize();
    }

}
</script>

<style>
</style>