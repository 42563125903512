<template>
  <material-card :heading="title" color="primary">
    <v-card-text>
      <v-row>
        <v-col :key="getKey()" cols="12" :md="showTable? 4 : 12">
          <chart :title-text="title"
                 :legend-visible="false"
                 :series-defaults-type="'column'"
                 :series="series"
                 :value-axis="valueAxis"
                 :series-colors="seriesColors"
                 :theme="'sass'">
          </chart>
          <!-- :tooltip="tooltip" -->
        </v-col>
        <v-col cols="12" md="8" v-if="showTable">
          <v-simple-table class="mt-10">
            <template v-slot:default>
              <tbody>
              <tr>
                <td></td>
                <td v-bind:key="head+x" style="text-align:right;" v-for="(head, x) in getHeaders()">{{ head }}</td>
              </tr>
              <tr v-for="row in getRows()" :key="row">
                <td>{{ row }}</td>
                <td v-bind:key="x" style="text-align:right;" v-for="(value, x) in getValues(row)">
                  <span v-if="!isOdd(x)">€{{ getFormattedPrice(value) }}</span>
                  <span class="text-left" v-else :class="colorized(value)"
                        v-html="getFormattedPercentage(value)"></span>
                </td>
              </tr>
              <tr>
                <td>TOTAL</td>
                <td v-bind:key="x" style="text-align:right;" v-for="(value, x) in getTotalValues()">
                  <span v-if="!isOdd(x)">€{{ getFormattedPrice(value) }}</span>
                  <span class="text-left" v-else :class="colorized(value)"
                        v-html="getFormattedPercentage(value)"></span>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" md="12">
          <v-btn-toggle v-model="toggleButton" class="float-right" dense background-color="primary" multiple>
            <v-btn @click="handleZoom()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn @click="handleTable()">
              <v-icon>mdi-table-eye</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card-text>
  </material-card>
</template>

<script>
import {Chart} from '@progress/kendo-charts-vue-wrapper';

export default {
  name: 'MFBarChartStacked',
  components: {
    'chart': Chart,
  },
  props: {
    title: {type: String, default: ""},
    series: {type: Array || Function, default: [], required: true},
    minSeries: {type: Number || Function, default: 0, required: false},
  },
  data: () => ({
    seriesColors: [
      "#ff7d36", "#ff5100", "#cccccc",
      "#36ffa9", "#00ff3c", "#cccccc",
      "#369fff", "#0089ff", "#cccccc",
      "#9836ff", "#7f19fa", "#cccccc",
      "#ff35b0", "#ff0097", "#cccccc",
      "#fdff36", "#fdff1a", "#cccccc",
    ],
    zoom: false,
    showTable: true,
    toggleButton: [],
  }),
  methods: {
    getHeaders: function () {
      let response = [];
      for (let i = 0; i < this.getYears().length; i++) {
        response.push(this.getYears()[i]);
        if (i < this.getYears().length - 1) response.push("");
      }
      return response;
    },
    getRows: function () {
      return this.series
          .filter(el => el.stack == this.currentYear)
          .reduce((a, b) => {
            if (!a.includes(`${b.name}`)) a.push(`${b.name}`);
            return a
          }, []);
    },
    getValues: function (row) {
      let data = this.series
              .filter(el => el.name == row)
              .map(el => el.data[0]),
          response = [];
      for (let i = 0; i < data.length; i++) {
        if (0 < i) response.push(Math.round((data[i] ? (data[i] - data[i - 1]) / data[i - 1] : 0) * 100) / 100);
        response.push(data[i]);
      }
      return response;
    },
    getTotalValues: function () {
      let data = this.series
              .reduce((a, b) => {
                a[b.stack] = a[b.stack] || 0;
                a[b.stack] += b.data[0];
                return a;
              }, []),
          response = [];
      Object.keys(data).forEach((k, i) => {
        if (0 < i) response.push(Math.round((data[k] ? (data[k] - data[k - 1]) / data[k] : 0) * 100) / 100);
        response.push(data[k]);
      });
      return response;
    },
    getYears: function () {
      return this.series.reduce((a, b) => {
        if (!a.includes(b.stack)) a.push(b.stack);
        return a
      }, []);
    },
    // getHeader: function(anno) {
    //     return this.series.filter(el => el.stack == anno).reduce((a, b) => { if(!a.includes(`${b.name}`)) a.push(`${b.name}`); return a }, []);
    // },
    // getPeriods: function(anno) {
    //     return this.series.filter(el => el.stack == anno).reduce((a, b) => { if(!a.includes(`${b.data[0]}`)) a.push(`${b.data[0]}`); return a }, []);
    // },
    handleZoom: function () {
      this.zoom = !this.zoom
    },
    handleTable: function () {
      this.showTable = !this.showTable
    },
    // getDiff: function(target) {
    //     let currentYear = `${target}`;
    //     let lastYear = `${target-1}`;
    //     return this.getPeriods(`${currentYear}`).map((el, i) => (+el - +this.getPeriods(`${lastYear}`)[i]) / +el);
    // },
    getFormattedPercentage: function (value) {
      return value < 0 ? `${Math.round(value * 100)} % &#9660;` : `+${Math.round(value * 100)} % &#9650;`;
    },
    colorized: function (value) {
      return +value >= 0 ? 'greenText' : "redText";
    },
    isOdd: function (value) {
      return value % 2 !== 0;
    },
    getFormattedPrice: function (value) {
      return `${this.$numerals(value, `0,0`)}`.replaceAll(`,`, `.`);
    },
    getKey: function () {
      return Math.floor(Math.random() * 99999);
    },
  },
  computed: {
    valueAxis: function () {
      return [{
        min: this.zoom ? this.minSeries : 0,
        max: this.zoom ?
            Math.ceil(Object.values(this.series.reduce((a, b) => {
              a[b.stack] = a[b.stack] || 0;
              a[b.stack] += b.data[0];
              return a
            }, {}))
                .reduce((a, b) => Math.max(a, b), 0) / 500000) * 500000
            : null,
        majorUnit: this.zoom ? 500000 : 1000000,
        labels: {template: "#= kendo.format('{0:C} M€', (((value || 0)/1000000)*100)/100) #"}
      }];
    },
    currentYear: function () {
      return this.$moment().year();
    },
  }
}
</script>

<style>
.greenText {
  color: green;
}

.redText {
  color: red;
}
</style>
