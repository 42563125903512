<template>
  <div style="width:100%;">
    <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
    <input style="width:100%;"
           :disabled="isDisabled"
           class="data-pick"
           v-else-if="isEdit && !loading"
           v-model="internalValue"
           type="date" />
    <p v-else>{{ ( this.value || new Date()) | formatDate }}</p>
  </div>
</template>

<script>
export default {
  name : "DatePickerField",
  props: {
    isDisabled: {
      type: Boolean,
      default: function () { return false }
    },
    value: [Date, String],
    loading: {
      type: Boolean,
      default: function () { return false }
    },
    isEdit: {
      type: Boolean,
      default: function () { return true }
    },
    field:String,
    default: {
      type: Date,
      default: function () { return new Date() }
    }
  },
  data: () => ({
    dataFormat: (new Date()).toISOString().substr(0, 10),
    activePicker: null,
    date: null,
    menu: false,
  }),
  computed: {
    internalValue: {
      get: function() {
        return `${new Date(this.value || this.default).toISOString()}`.substr(0, 10);
      },
      set: function(newValue) {
        this.$emit('emitUpdate', { field: this.field,  value : new Date(newValue).toISOString().substr(0, 10) })
      }
    },
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
}
</script>