<template>
  <v-card>
    <v-form>
      <v-card-text >
        <v-row>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="filters.geonameCountryCode"
              :items="dropdownCountriesIso"
              :label="$t('country', {r:''})"
              autocomplete="nope"
              hide-details="auto"
              multiple chips
              dense
              clearable
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select 
              v-model="filters.salesSectors" 
              :items="dropdownSectors" 
              :label="$t('sector')" 
              multiple chips 
              hide-details="auto" 
              dense 
              autocomplete="nope" 
              clearable 
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select 
              v-model="filters.salesChannels" 
              :items="dropdownChannels" 
              :label="$t('channel')" 
              multiple chips 
              hide-details="auto" 
              dense 
              autocomplete="nope" 
              clearable 
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select 
              v-model="filters.customerPurchaseCategoryId" 
              :items="customerPurchaseCategoriesDropdown" 
              :label="$t('purchaseCategory')" 
              multiple chips 
              hide-details="auto" 
              dense 
              autocomplete="nope" 
              clearable 
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete 
              v-model="filters.priceCatalogId" 
              :items="dropdownPriceCatalogs" 
              :label="$t('priceCatalog')" 
              multiple chips
              hide-details="auto" 
              dense 
              autocomplete="nope" 
              clearable 
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              v-model="filters.customerStatisticalTag"
              :items="dropdownGroupIdentifiers"
              :label="$t('groupIdentifier')"
              multiple
              chips
              deletable-chips
              hide-details="auto"
              dense
              clearable
              autocomplete="nope"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select multiple hide-details="auto" clearable chips dense v-model="filters.tag" :items="getOrderStatisticalTagCollection" :label="$t('statisticalTag')" outlined></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
                v-model="filters.parentCode"
                :items="dropdownParents"
                :label="$t('parentCode')"
                hide-details="auto"
                dense
                clearable
                autocomplete="nope"
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
                v-model="filters.customerId"
                :items="dropdownCustomers"
                :label="$t('customer')"
                hide-details="auto"
                dense
                multiple chips
                clearable
                autocomplete="nope"
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select 
              v-model="filters.hasCustomerBudget" 
              :items="hasCustomerBudgetDropdown" 
              :label="$t('hasCustomerBudget')"
              hide-details="auto" 
              dense 
              clearable
              autocomplete="nope"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select 
              v-model="filters.showChildren" 
              :items="dropdownTF" 
              :label="$t('childrenDistri')" 
              hide-details="auto" 
              dense 
              autocomplete="nope" 
              clearable 
              outlined
            ></v-select>
          </v-col>
           <v-col cols="12" sm="1" offset="10">
            <v-btn class="float-right" @click="clearFilters" v-t="'clear'"></v-btn>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn block @click="doApplyFilters" v-t="'apply'"></v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {call, get, sync} from "vuex-pathify";

export default {
  name: "CustomerMonitoringFilter",
  components: {

  },
  data: () => ({
  }),
  computed: {
    dropdownTF(){
      return [
        { text: this.$t('hide'), value: false },  
        { text: this.$t('show'), value: true }
      ]
    },
    filters: sync("customerBudget/filters"),
    dropdownCustomers: get("customers/dropdownCustomers"),
    dropdownCountriesIso: get("geoname/dropdownCountriesIso"),
    dropdownChannels: get("customers/dropdownChannels"),
    dropdownSectors: get("customers/dropdownSectors"),
    dropdownPriceCatalogs: get("priceCatalogs/dropdownPriceCatalogs"),
    customerPurchaseCategoriesDropdown: get("customers/customerPurchaseCategoriesDropdown"),
    dropdownGroupIdentifiers: get('customers/dropdownGroupIdentifiers'),
    orderStatisticalTags: get("customerMonitoring/orderStatisticalTags"),
    orderStatisticalTagCollection: get("statistics/orderStatisticalTagDropdown"),
    dropdownParents: get("customers/dropdownFilteredParents"),
    getOrderStatisticalTagCollection: function() {
      return [{text: this.$t('all'), value: -1}, ...this.orderStatisticalTagCollection]
    },
    hasCustomerBudgetDropdown: function() {
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('withBudget'), value: true},
        {text: this.$t('withoutBudget'), value: false},
      ]
    }
  },
  mounted() {
    this.initCustomerCollection();
    this.initCountryCollection();
    this.initChannelCollection();
    this.initSectorCollection();
    this.initPurchaseCategoryCollection();
    this.initPriceCatalogCollection();
    this.apiCustomerStatisticalTagGet();
    this.apiOrderStatisticalTagGet();
  },
  methods: {
    clearFilters(){
      this.filters = {
        showChildren: false
      };
    },
    doApplyFilters() {
      this.apiCustomerBudgetObjectivesFilteredPost();
    },
    ...call("geoname/*"),
    ...call("customers/*"),
    ...call("priceCatalogs/*"),
    ...call("customerBudget/*"),
    ...call("statistics/*"),
  }
}
</script>
