<template>
    <div style="width:100%;">
        <chart
            :legend-visible="true"
            :legend-position="'top'"
            :series="series"
            :data-source="datasource"
            :chart-area-margin-left="0"
            :chart-area-height="height"
            :value-axis-plot-bands="plotBands"
            :value-axis-major-ticks-visible="false"
            :value-axis-minor-ticks-visible="true"
            :value-axis-min="0"
            :value-axis-max="valueAxisMax"
            :category-axis-major-grid-lines-visible="false"
            :category-axis-major-ticks-visible="false"
            :tooltip-visible="true"
            :tooltip-template="tooltip"
            :theme="'sass'"
        ></chart>
    </div>
</template>

<script>
import { Chart } from '@progress/kendo-charts-vue-wrapper';

export default {
    name: 'MFBullet',
    components: {
        'chart': Chart,
    },
    props: {
        valueAxisMax: { type: Number, required: false, default: null }, 
        actual: { type: Number, required: true },
        target: { type: Number, required: true },
        height: { type: Number, required: false, default: 100 },
        date: { type: Date, required: false, dafault: new Date() }
    },
    data: () => ({
        datasource : [{ current: 0, target: 0 }],
        plotBands: [],
        tooltip: '',
        series: [{ color: 'green', type: "bullet", currentField: "current", targetField: "target", target: { color: 'green', line: { width: 8 }, border: { width: 4, color: 'indianred' }}}],
    }),
    created(){    

        let colors = this.$vuetify.theme.themes.light,
            dayOfYear = this.$moment(this.date).dayOfYear(),
            todayGoal = Math.floor(this.target / 365 * dayOfYear);

        this.series[0].target.color = colors.success;
        this.series[0].target.border.color = colors.success_accent;

        this.datasource[0].current = Math.floor(this.actual);
        this.datasource[0].target = Math.floor(this.target);

        this.series[0].color = todayGoal <= this.datasource[0].current? colors.primary_accent : colors.warning_accent;
        this.plotBands.push({ from: 0, to: todayGoal, color: colors.sparrow_grey, opacity: 0.6 });

        this.tooltip = `Current sales : ${ this.$options.filters.numeral(this.actual, '0,0[.]00 $') }<br />Goal : ${ this.$options.filters.numeral(this.target, '0,0[.]00 $') }`;

    },
}
</script>