<template>
    <div style="display: flex; flex-direction: row;">
        <v-text-field 
            @input="handleInput"
            @change="handleInput"
            :value="model" hide-details="auto" :label="label"
            @keyup.enter="onEnter" @click:clear="onClear" 
            outlined clearable dense
        ></v-text-field>
        <v-menu v-model="orderDateMenu" :close-on-content-click="false" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" dark v-on="on" dense style="min-width: 25px !important; width: 25px !important;" class="px-0">
                  <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
          </template>
          <v-card style="height: 110px !important; overflow: hidden; padding: 18px;">
            <v-row>
              <v-col cols="12" sm="6">
                <DatePickerField :isEdit="true" :isDisabled="orderDateFromDisabled" :value="orderDateFrom" :field="'orderDateFrom'" :loading="false" @emitUpdate="orderDatePopoverChanged"></DatePickerField>
              </v-col>
              <v-col cols="12" sm="6">
                <DatePickerField :isEdit="true" :isDisabled="orderDateToDisabled" :value="orderDateTo" :field="'orderDateTo'" :loading="false" @emitUpdate="orderDatePopoverChanged"></DatePickerField>
              </v-col>
            </v-row>
            <v-row v-if="type=='default'">
              <v-col cols="12" sm="2"><v-btn small @click="lastWeeks(1)">1W</v-btn></v-col>
              <v-col cols="12" sm="2"><v-btn small @click="lastMonths(1)">1M</v-btn></v-col>
              <v-col cols="12" sm="2"><v-btn small @click="lastMonths(3)">3M</v-btn></v-col>
              <v-col cols="12" sm="2"><v-btn small @click="lastMonths(6)">6M</v-btn></v-col>
              <v-col cols="12" sm="2"><v-btn small @click="lastYears(1)">1Y</v-btn></v-col>
            </v-row>
            <v-row v-if="type=='to'">
              <v-col cols="12" sm="2"><v-btn small @click="monthsAgo(1)">1M ago</v-btn></v-col>
              <v-col cols="12" sm="2"><v-btn small @click="monthsAgo(2)">2M ago</v-btn></v-col>
              <v-col cols="12" sm="2"><v-btn small @click="monthsAgo(3)">3M ago</v-btn></v-col>
              <v-col cols="12" sm="2"><v-btn small @click="monthsAgo(6)">6M ago</v-btn></v-col>
              <v-col cols="12" sm="2"><v-btn small @click="monthsAgo(12)">1Y ago</v-btn></v-col>
              <v-col cols="12" sm="2"><v-btn small @click="monthsAgo(24)">2Y ago</v-btn></v-col>
            </v-row>
          </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
  name: "DateFilter",
  props: {
    value: [String, Object],
    label: { type: String, default: "" },
    onEnter: { type: Function, default: () => {}},
    onClear: { type: Function, default: () => {}},
    type: { type: String, default: "default", required: false },
    //! default || to || 
  },
  data: () => ({
    orderDateFrom: null,
    orderDateTo: null,
    orderDateFromDisabled: false,
    orderDateToDisabled: true,
    orderDateMenu: null, 
  }),
  computed: {
    model: function() {
      return this.value;
    }
  },
  methods: {
    orderDatePopoverChanged(e) {
      this[e.field] = new Date(e.value);
      if (this.orderDateFrom === null) {
        this.orderDateToDisabled = true;
      } else {
        this.orderDateToDisabled = false;
        let orderDateFromDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.orderDateFrom),
            orderDateFromMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.orderDateFrom),
            orderDateFromYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.orderDateFrom);
        this.handleInput(`${orderDateFromDay}${orderDateFromMonth}${orderDateFromYear}`);
        if (this.orderDateTo !== null) {
            let orderDateToDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.orderDateTo),
                orderDateToMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.orderDateTo),
                orderDateToYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.orderDateTo);
            this.handleInput(`${orderDateFromDay}${orderDateFromMonth}${orderDateFromYear}..${orderDateToDay}${orderDateToMonth}${orderDateToYear}`);
        }
      }
    },
    setLastWithMomentSubtraction(n = 1, type = 'd') {
      this.orderDateTo = this.$moment().toDate();
      this.orderDateFrom = this.$moment().subtract(n, type).toDate();
      this.orderDatePopoverChanged({});
    },
    lastDays(n = 1) {
      this.orderDateToDisabled = false;
      this.setLastWithMomentSubtraction(n, 'd');
    },
    lastWeeks(n = 1) {
      this.orderDateToDisabled = false;
      this.setLastWithMomentSubtraction(n, 'w');
    },
    lastMonths(n = 1) {
      this.orderDateToDisabled = false;
      this.setLastWithMomentSubtraction(n, 'M');
    },
    lastYears(n = 1) {
      this.orderDateToDisabled = false;
      this.setLastWithMomentSubtraction(n, 'y');
    },
    setUntilWithMomentSubtraction(n = 1) {
      this.orderDateTo = this.$moment().subtract(n, 'M').toDate();
      this.orderDateFrom = this.$moment('01-02-1970').toDate()
      this.orderDatePopoverChanged({});
    },
    monthsAgo(n = 1) {
      this.orderDateFromDisabled = true;
      this.setUntilWithMomentSubtraction(n);
    },
    handleInput (e) {
      this.$emit('input', e);
    }
  }
}
</script>
