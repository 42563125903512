<template>
  <div style="display: flex; align-items: center; flex-direction: row;">
    <v-progress-linear
        indeterminate
        color="red"
        v-if="loading"
    ></v-progress-linear>
    <BooleanIcon v-else-if="!isEdit && !loading" :booleanValue="internalValue"/>
    <v-checkbox :label="label || placeholder" v-model="internalValue" v-else @click="clickOff()" hide-details class="align-center justify-center"
                style="height: 100%; margin-top: 0; padding-top: 0;"></v-checkbox>
    <slot></slot>
  </div>

</template>

<script>
import BooleanIcon from '@/components/app/BooleanIcon';

export default {
  name: "CheckBox",
  components: {
    BooleanIcon,
  },
  props: {
    disabled: Boolean,
    value: Boolean,
    loading: Boolean,
    isEdit: Boolean,
    field: String,
    placeholder: String,
    label: String
  },
  data: () => ({}),
  computed: {
    internalValue: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit('emitUpdate', {field: this.field, value: newValue})
      }
    },
  },
  methods: {

    clickOff: function () {
      this.isProtect = true;
      this.$emit('outEdit')
    },
  }
}
</script>


<style>

</style>