<template>
  <div style="background-color: white">
    <section class="stepper-list">
      <article class="stepper" v-for="(card, i) in cardCollection" :class="{ selected: card.title == selectedCard }" @click="selectedCard = card.title" :key="i">
        <header class="stepper-header">
          <div class="stepper-container">
            <v-icon :class="{ colorSelected: card.title == selectedCard }">{{ card.icon.element }}</v-icon>
            <h2 :class="{ colorSelected: card.title == selectedCard }">{{ card.title }}</h2>
          </div>
        </header>
      </article>
      <article class="stepper confirm" @click="saveIn">
        <header class="stepper-header">
          <div class="stepper-container-confirm">
            <v-icon style="color: white"> mdi-check</v-icon>
            <h2 v-t="'confirm'"></h2>
          </div>
        </header>
      </article>
    </section>
    <MFCustomCard :inputCollection="getSelectedCollection()" :title="selectedCard"></MFCustomCard>
  </div>
</template>

<script>
import { call, get, sync } from "vuex-pathify";
import MFCustomCard from "@/components/utility/MFCustomCard";

export default {
  props: ["agentId"],
  components: {
    MFCustomCard,
  },
  name: "AgentUpdate",
  data: () => ({
    currentAgent: {},
    viewedItem: {},
    mappedPostalCodes: [],
    originalPostalCodes: [],
    timer: null,
    locationDialog: false,
    ownershipDialog: false,
    catalogDialog: false,
    ibanDialog: false,
    editModeStatus: true,
    obiettivo: { target: 100000, prc: 34, gained: 34000, animate: true },
    selectedOwnership: {},
    selectedCatalog: {},
    priceCatalogs: [],
    selectedLocation: {},
    locationModel: {
      locationNumber: -1,
      name: "",
      address: "",
      city: "",
      county: "",
      country: "",
      note: "",
      phone: "",
      email: "",
      active: true,
      visibility: true,
      geometry: "",
      engrave: false,
      default: false,
      postalCode: "",
      enesiLocationId: null,
      geonameCountryCode: null,
      geonameRegionCode: null,
      geonameProvinceCode: null,
    },
    isLoadingPostalCodes: false,
    selectedIban: {},
    ibanModel: {
      country: "",
      chk: "",
      cin: "",
      abi: "",
      cab: "",
      cc: "",
      fullAddress: "",
    },
    dropdownLanguages: ["IT", "EN", "ES", "FR", "DE", "PT"],
    cardCollection: [
      { icon: { color: "", element: "mdi-account-tie" }, title: "Registry" },
      { icon: { color: "", element: "mdi-badge-account-horizontal-outline" }, title: "Main Info" },
      { icon: { color: "", element: "mdi-clipboard-list-outline" }, title: "Notes" },
      { icon: { color: "", element: "mdi-cake-variant" }, title: "Birthday" },
    ],
    selectedCard: "Registry",
    inputCollection: {
      "Main Info": [],
      Notes: [],
      Registry: [],
      Birthday: [],
    },
  }),
  created() {
    if (this.agentId === "new") {
      this.editModeStatus = true;
      this.cardCollection.length = 0;
      this.cardCollection.push(...[
        { icon: { color: "", element: "mdi-account-tie" }, title: "Registry" },
      ])
    }

    this.initialize();
  },
  methods: {
    getColor(param) {
      if (param == 0) return "error";
      else if (param < 4) return "warning";
      else return "success";
    },
    getPic() {
      return !!this.editAgent && !!this.editAgent.picture
        ? this.editAgent.picture
        : require("@/assets/profile_empty.svg");
    },
    getCollections: function (record) {
      return [
        {
          icon: "mdi-pencil",
          color: "primary",
          action: () => this.openIban(record),
        },
        {
          icon: "mdi-delete",
          color: "error",
          action: () => this.deleteIban(record),
        },
      ];
    },
    getAgent: function () {
      if (this.agentId === "new") {
        if (!this.currentAgent || Object.keys(this.currentAgent).length === 0) {
          this.currentAgent = {
            name: null,
            email: null,
            country: null,
            address: null,
            city: null,
            postalCode: null,
            cellphone: null,
          };
        }
      } else {
        this.currentAgent = this.agents.find((el) => el.id == this.agentId) || this.currentAgent;
      }
      return this.currentAgent;
    },
    initialize: function () {
      this.initAgentCollection().then(() => {
        this.currentAgent = this.currentAgent.id ? this.currentAgent : this.agents.find((el) => el.id == this.agentId);
      });
      this.currentAgent = {
        ...this.currentAgent,
        ...this.userDefaultParameters,
      };

      this.loadRes();
      setTimeout(() => {
        this.setUpInputCollection();
        this.unloadRes();
      }, 5000);
    },
    handleAssignment: function (obj) {
      this.currentAgent[obj.field] = obj.value;
    },
    isInRole: function (roles) {
      return this.$store.get("auth/isInRole", roles);
    },
    getSelectedCollection: function () {
      return this.inputCollection[this.selectedCard];
    },
    setUpInputCollection: function () {
      Object.keys(this.inputCollection).forEach((el) => (this.inputCollection[el].length = 0));
      this.inputCollection["Main Info"].push(
        ...[
          {
            field: "name",
            inputType: "text",
            value: this.getAgent()?.name,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('name'),
          },
          {
            field: "email",
            inputType: "text",
            value: this.getAgent()?.email,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('email', {r:''}),
          },
          {
            field: "fiscalCode",
            inputType: "text",
            value: this.getAgent()?.fiscalCode,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('fiscalCode', {r:''}),
          },
          {
            field: "cellphone",
            inputType: "text",
            value: this.getAgent()?.cellphone,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('cellphone'),
          },
          {
            field: "active",
            inputType: "checkbox",
            value: !!this.getAgent()?.active,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('active'),
          },
        ]
      );
      this.inputCollection["Notes"].push(
        ...[
          {
            field: "notes",
            inputType: "textarea",
            value: this.getAgent()?.notes,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('notes'),
          },
        ]
      );
      this.inputCollection["Registry"].push(
        ...[
          {
            field: "name",
            inputType: "text",
            value: this.getAgent()?.name,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('name'),
          },
          {
            field: "city",
            inputType: "text",
            value: this.getAgent()?.city,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('city'),
          },
          {
            field: "fiscalCode",
            inputType: "text",
            value: this.getAgent()?.fiscalCode,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('fiscalCode', {r:''}),
          },
          {
            field: "code",
            inputType: "text",
            value: this.getAgent()?.code,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('code'),
          },
          {
            field: "state",
            inputType: "text",
            value: this.getAgent()?.state,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('state'),
          },
          {
            field: "vatNumber",
            inputType: "text",
            value: this.getAgent()?.vatNumber,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('vatNumber', {r:''}),
          },
          {
            field: "cellphone",
            inputType: "text",
            value: this.getAgent()?.cellphone,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('cellphone'),
          },
          {
            field: "postalCode",
            inputType: "text",
            value: this.getAgent()?.postalCode,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('postalCode'),
          },
          {
            field: "target",
            inputType: "text",
            value: this.getAgent()?.target,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('target'),
          },
          {
            field: "email",
            inputType: "text",
            value: this.getAgent()?.email,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('email', {r:''}),
          },
          {
            field: "country",
            inputType: "text",
            value: this.getAgent()?.country,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('country', {r:''}),
          },
          {
            field: "active",
            inputType: "checkbox",
            value: !!this.getAgent()?.active,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('active'),
          },
          {
            field: "address",
            inputType: "text",
            value: this.getAgent()?.address,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('address'),
          },
          {
            field: "enasarco",
            inputType: "text",
            value: this.getAgent()?.enasarco,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('enasarco'),
          },
        ]
      );
      this.inputCollection["Birthday"].push(
        ...[
          {
            field: "birthday",
            inputType: "text",
            value: this.getAgent()?.birthday,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('birthday'),
          },
          {
            field: "birthCountry",
            inputType: "text",
            value: this.getAgent()?.birthCountry,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('country', {r:''}),
          },
          {
            field: "birthState",
            inputType: "text",
            value: this.getAgent()?.birthState,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('state'),
          },
          {
            field: "birthAddress",
            inputType: "text",
            value: this.getAgent()?.birthAddress,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('address'),
          },
          {
            field: "birthCity",
            inputType: "text",
            value: this.getAgent()?.birthCity,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('city'),
          },
        ]
      );
    },
    saveIn: function () {
      this.$emit("saveAgent", this.currentAgent);
      if (this.agentId === "new") {
        delete this.currentAgent.id;
        this.apiAgentPost(this.currentAgent).then((res) => {
          this.$router.push(`/agents/${res.id}`);
          setTimeout(() => this.$window.location.reload(), 10);
        });
      } else {
        this.apiAgentIdPut(this.currentAgent);
        this.editModeStatus = !this.editModeStatus;
      }
    },
    ...call("utils", ["loadRes", "unloadRes"]),
    ...call("geoname/*"),
    ...call("orders/*"),
    ...call("agents/*"),
    ...call("app/*"),
  },
  computed: {
    agentsDropdown: get("agents/dropdownAgents"),
    agents: get("agents/agents"),
    userDefaultParameters: get("tenantConfiguration/userDefaultParameters"),
    customerDefaultParameters2Check: get("tenantConfiguration/customerDefaultParameters2Check"),
    geonameAdmin1Description: get("tenantConfiguration/geonameAdmin1Description"),
    geonameAdmin2Description: get("tenantConfiguration/geonameAdmin2Description"),
  },
};
</script>

<style>
a {
  text-decoration: none;
}

.stepper-list {
  display: flex;
  padding: 3rem;
  overflow-x: scroll;
}

.stepper-list::-webkit-scrollbar {
  display: none;
}

.stepper {
  cursor: pointer;
  height: 120px;
  width: 100%;
  /* min-width: 200px; */
  padding: 1.5rem;
  border-radius: 16px;
  /* background: #17141d; */
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  margin: 0;
  scroll-snap-align: start;
  clear: both;
  position: relative;
  border: 2px solid rgba(52, 52, 56, 0.2);
}

.stepper:hover {
  background-color: #242f40;
  border-color: #242f40;
  color: #f1f2f2;
}

.selected {
  background-color: #242f40;
  border-color: #242f40;
  color: #f1f2f2;
  transform: translateY(-30px);
}

.colorSelected {
  color: #f1f2f2 !important;
}

.stepper:focus {
  background-color: #242f40;
  color: white;
}

.stepper:focus-within ~ .stepper,
.stepper:hover ~ .stepper {
  transform: translateX(130px);
}

.stepper:not(:first-child) {
  margin-left: -130px;
}

.stepper-header {
  margin-bottom: auto;
}

.stepper-header p {
  font-size: 14px;
  margin: 0 0 1rem;
  color: #7a7a8c;
}

.stepper-header h2 {
  font-size: 20px;
  margin: 0.25rem 0 auto;
  text-decoration: none;
  border: 0;
  display: inline-block;
  cursor: pointer;
}

.stepper-author {
  margin: 3rem 0 0;
  display: grid;
  grid-template-columns: 75px 1fr;
  align-items: center;
  position: relative;
}

.author-avatar {
  grid-area: auto;
  align-self: start;
  position: relative;
  box-sizing: border-box;
}

.author-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: grayscale(100%);
  display: block;
  overflow: hidden;
  margin: 16px 10px;
}

.author-name {
  grid-area: auto;
  box-sizing: border-box;
}

.author-name-prefix {
  font-style: normal;
  font-weight: 700;
  color: #7a7a8c;
}

.half-circle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 48px;
  fill: none;
  stroke: #ff8a00;
  stroke-width: 8;
  stroke-linecap: round;
  pointer-events: none;
}

.tags {
  margin: 1rem 0 2rem;
  padding: 0.5rem 0 1rem;
  line-height: 2;
  margin-bottom: 0;
}

.tags a {
  font-style: normal;
  font-weight: 700;
  font-size: 0.5rem;
  color: #7a7a8c;
  text-transform: uppercase;
  font-size: 0.66rem;
  border-radius: 2rem;
  padding: 0.2rem 0.85rem 0.25rem;
  position: relative;
}

.tags a:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  background-clip: text;
  border-color: white;
}

.table-width {
  width: 100%;
}

.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stepper-container-confirm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.confirm {
  border-color: #3aca21;
  background: #3aca21;
  color: white;
}

.confirm:hover {
  background: rgb(16, 161, 16);
  border-color: rgb(16, 161, 16);
  color: #f1f2f2;
}
</style>
