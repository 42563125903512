<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-t="'logsAndMessages'"></v-card-title>
        <v-card-text class="wysiwyg-text-area"> 
          <tiptap-vuetify v-model="internalContent" :extensions="extensions" autofocus/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal()" v-t="'close'"></v-btn>
          <v-btn color="primary" text @click="action()" > {{this.text}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

export default {
  name: 'WYSIWYG',
  // specify TiptapVuetify component in "components"
  components: { TiptapVuetify },
  props: {
    text: String,
    action: Function,
    value:[String, Number],
    dialog: Boolean,
    closeModal: Function
  },
  data: () => ({
    // declare extensions you want to use
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
  }),
  methods: {},
  computed: {
    internalContent: {
        get: function() {
          return this.value;
        },
        set: function(newValue) {
          this.$emit('emitUpdate', { 'value' : newValue})
        }
    },
  }
}
</script>

<style>
.ProseMirror{
  height: 40vh;
  padding: 20px;
}

.v-card__text{
  padding: 20px 20px 20px 20px !important;
}

.wysiwyg-text-area { overflow-y: scroll; }
.tiptap-vuetify-editor__content { overflow-y: scroll; }
.tiptap-vuetify-editor__content > p { outline: none!important; }
.tiptap-vuetify-editor__content:focus{ outline: none!important; }
.ProseMirror-focused {
  border: none!important;
  outline: none!important;
}

</style>
