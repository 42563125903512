<template>
  <div style="background-color: white">
    <section class="stepper-list">
      <article
        class="stepper"
        v-for="(card, i) in cardCollection"
        :class="{ selected: card.link == selectedCard }"
        @click="selectedCard = card.link"
        :key="i"
      >
        <header class="stepper-header">
          <div class="stepper-container">
            <v-icon :class="{ colorSelected: card.link == selectedCard }">{{ card.icon.element }}</v-icon>
            <h2 :class="{ colorSelected: card.link == selectedCard }">{{ card.title }}</h2>
          </div>
        </header>
      </article>
      <article class="stepper confirm" @click="saveIn">
        <header class="stepper-header">
          <div class="stepper-container-confirm">
            <v-icon style="color:white"> mdi-check</v-icon>
            <h2 v-t="'confirm'"></h2>
          </div>
        </header>
      </article>
    </section>
    <MFCustomCard v-if="isRegular()" :inputCollection="getSelectedCollection()" :title="selectedCard"></MFCustomCard>
    <material-card :heading="$t('addresses')" color="primary" v-if="customerId !== 'new' && selectedCard == 'addresses'">
      <v-card-text>
        <v-row justify="center">
          <v-data-table
            class="table-width elevation-1"
            :headers="locationsHeaders"
            :items="customerLocations"
            :items-per-page="5"
          >
            <template v-slot:item.default="{ item }">
              <BooleanIcon :booleanValue="item.default" />
            </template>
            <template v-slot:item.provinceName="{ item }">
              {{ item.provinceName }}
              <!-- {{ getProvince(item.geonameProvinceCode) }} -->
            </template>
            <template v-slot:item.countryName="{ item }">
              {{ item.countryName }}
            </template>
            <template v-slot:item.actions="{ item }">
              <MFButtonPill :collection="getCollections(item)"></MFButtonPill>
            </template>
          </v-data-table>
          <v-dialog persistent v-model="locationDialog" width="70vw">
            <v-card :class="{ blur: isLoadingPostalCodes }">
              <v-card-title class="headline grey lighten-2" v-t="'location'"></v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" class="mb-3"></v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label>{{ $t('country', {r:''}) }}</label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedLocation.geonameCountryCode"
                          :items="dropdownCountries"
                          :field="'geonameCountryCode'"
                          @emitUpdate="handleLocation"
                          :isEdit="true"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="10" md="3" v-if="mappedPostalCodes.length > 0">
                    <div>
                      <label v-t="'postalCode'"></label>
                      <div class="inf-font">
                        <AutoComplete
                          @emitUpdate="handleLocation"
                          class="address-text"
                          :isEdit="true"
                          clearable
                          :disabled="!selectedLocation.geonameCountryCode"
                          :value="selectedLocation.postalCode"
                          :loading="isLoadingPostalCodes"
                          autocomplete="nope"
                          :field="'postalCode'"
                          :items="mappedPostalCodes"
                          :placeholder="$t('postalCode')"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2" md="1" v-if="mappedPostalCodes.length > 0">
                    <v-icon @click="clearPostalCodes()">mdi-close</v-icon>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" v-else>
                    <div>
                      <label v-t="'postalCode'"></label>
                      <div class="inf-font">
                        <TextField
                          @emitUpdate="handleLocation"
                          class="address-text"
                          clearable
                          :isEdit="true"
                          :disabled="!selectedLocation.geonameCountryCode"
                          :value="selectedLocation.postalCode"
                          autocomplete="nope"
                          :loading="false"
                          :field="'postalCode'"
                          :placeholder="$t('postalCode')"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>  
                  <v-col cols="12" sm="4">
                    <div>
                      <label v-t="'city'"></label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedLocation.city"
                          @emitUpdate="handleLocation"
                          :field="'city'"
                          :isEdit="true"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label style="text-transform: capitalize">{{ $t(geonameAdmin1Description) }}</label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedLocation.geonameRegionCode"
                          :items="dropdownRegions"
                          :field="'geonameRegionCode'"
                          @emitUpdate="handleLocation"
                          :isEdit="true"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label style="text-transform: capitalize">{{ $t(geonameAdmin2Description) }}</label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedLocation.geonameProvinceCode"
                          :items="dropdownProvinces"
                          :field="'geonameProvinceCode'"
                          @emitUpdate="handleLocation"
                          :isEdit="true"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label v-t="'address'"></label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedLocation.address"
                          @emitUpdate="handleLocation"
                          :field="'address'"
                          :isEdit="true"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label v-t="'contactName'"></label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedLocation.name"
                          @emitUpdate="handleLocation"
                          :field="'name'"
                          :isEdit="true"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label v-t="'phone'"></label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedLocation.phone"
                          @emitUpdate="handleLocation"
                          :field="'phone'"
                          :isEdit="true"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label>{{ $t('email', {r:''}) }}</label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedLocation.email"
                          @emitUpdate="handleLocation"
                          :field="'email'"
                          :isEdit="true"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div>
                      <label v-t="'active'"></label>
                      <div class="inf-font">
                        <CheckBoxField
                          :value="selectedLocation.active"
                          @emitUpdate="handleLocation"
                          :field="'active'"
                          :isEdit="true"
                        ></CheckBoxField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div>
                      <label v-t="'default'"></label>
                      <div class="inf-font">
                        <CheckBoxField
                          :value="selectedLocation.default"
                          @emitUpdate="handleLocation"
                          :field="'default'"
                          :isEdit="true"
                        ></CheckBoxField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div>
                      <label v-t="'visible'"></label>
                      <div class="inf-font">
                        <CheckBoxField
                          :value="selectedLocation.visibility"
                          @emitUpdate="handleLocation"
                          :field="'visible'"
                          :isEdit="true"
                        ></CheckBoxField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div>
                      <label v-t="'notes'"></label>
                      <div class="inf-font">
                        <TextAreaField
                          :value="selectedLocation.note"
                          @emitUpdate="handleLocation"
                          :field="'note'"
                          :isEdit="true"
                        ></TextAreaField>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="locationDialog = false" v-t="'close'"></v-btn>
                <v-btn color="primary" text @click="saveLocation" v-t="'confirm'"></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-btn color="primary" absolute top right fab @click="addLocation">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-text>
    </material-card>
    <material-card :heading="$t('priceCatalogs')" color="primary" v-if="customerId !== 'new' && selectedCard == 'priceCatalogs'">
      <v-card-text>
        <v-row justify="center">
          <v-simple-table class="table-width">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" v-t="'family'"></th>
                  <th class="text-left" v-t="'priceCatalog'"></th>
                  <th class="text-left" v-if="editModeStatus" v-t="'actions'"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="catalog in priceCatalogs" :key="catalog.id">
                  <td>{{ getFamily(catalog.familyId) }}</td>
                  <td>{{ getPriceListName(catalog.priceCatalogId) }}</td>
                  <td v-if="editModeStatus && isInRole(['SupervisorAgent', 'CommercialSpecialist'])">
                    <MFButtonPill :collection="getCollections(catalog)"></MFButtonPill>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-dialog persistent v-model="catalogDialog" width="40vw">
            <v-card>
              <v-card-title class="headline grey lighten-2" v-t="'priceCatalog'"></v-card-title>
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <div>
                      <label v-t="'family'"></label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedCatalog.familyId"
                          :items="getFamiliesMapped()"
                          :field="'familyId'"
                          @emitUpdate="handleCatalog"
                          :isEdit="true"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div>
                      <label v-t="'priceCatalog'"></label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedCatalog.priceCatalogId"
                          :items="priceCatalogDropdown"
                          @emitUpdate="handleCatalog"
                          :field="'priceCatalogId'"
                          :isEdit="true"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="catalogDialog = false" v-t="'close'"></v-btn>
                <v-btn v-if="editModeStatus" color="primary" text @click="saveCatalog" v-t="'confirm'"></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-btn v-if="isInRole(['SupervisorAgent', 'CommercialSpecialist']) && !!this.getFamilies().length"
          color="primary" absolute top right fab @click="addCatalog">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-text>
    </material-card>
    <material-card :heading="$t('ownership')" color="primary" v-if="customerId !== 'new' && selectedCard == 'ownerships'">
      <v-card-text>
        <v-row justify="center">
          <v-simple-table class="table-width">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" v-t="'family'"></th>
                  <th class="text-left" v-if="showSubAgent" v-t="'subAgent'"></th>
                  <th class="text-left" v-t="'agent'"></th>
                  <th class="text-left" v-t="'supervisorAgent'"></th>
                  <th class="text-left" v-t="'chiefAgent'"></th>
                  <th class="text-left" v-t="'commercialSpecialist'"></th>
                  <th class="text-left" v-if="editModeStatus" v-t="'actions'"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ownership in customerOwnership" :key="ownership.id">
                  <td>{{ getFamily(ownership.familyId) }}</td>
                  <td v-if="showSubAgent">{{ getAgent(ownership.subagentId) }}</td>
                  <td>{{ getAgent(ownership.agentId) }}</td>
                  <td>{{ getAgent(ownership.supervisorAgentId) }}</td>
                  <td>{{ getAgent(ownership.chiefAgentId) }}</td>
                  <td>{{ getCommercialSpecialist(ownership.commercialSpecialistId) }}</td>
                  <td v-if="editModeStatus">
                    <MFButtonPill :collection="getCollections(ownership)"></MFButtonPill>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-dialog persistent v-model="ownershipDialog" width="70vw">
            <v-card>
              <v-card-title class="headline grey lighten-2" v-t="'ownership'"></v-card-title>

              <v-card-text class="mt-4">
                <v-row v-if="!selectedOwnership.id">
                  <v-col cols="12" sm="12">
                    <div>
                      <label v-t="'family'"></label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedOwnership.familyId"
                          :items="getFamilies()"
                          :field="'familyId'"
                          :isEdit="true"
                          @emitUpdate="handleOwnership"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row><v-col cols="12" sm="3" v-if="showSubAgent">
                    <div>
                      <label v-t="'subAgent'"></label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedOwnership.subagentId"
                          :items="agentsDropdown"
                          :field="'subagentId'"
                          :isEdit="true"
                          @emitUpdate="handleOwnership"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div>
                      <label v-t="'agent'"></label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedOwnership.agentId"
                          :items="agentsDropdown"
                          :field="'agentId'"
                          :isEdit="true"
                          @emitUpdate="handleOwnership"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div>
                      <label v-t="'supervisorAgent'"></label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedOwnership.supervisorAgentId"
                          :items="agentsDropdown"
                          :field="'supervisorAgentId'"
                          :isEdit="true"
                          @emitUpdate="handleOwnership"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div>
                      <label v-t="'chiefAgent'"></label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedOwnership.chiefAgentId"
                          :items="agentsDropdown"
                          :field="'chiefAgentId'"
                          :isEdit="true"
                          @emitUpdate="handleOwnership"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div>
                      <label v-t="'commercialSpecialist'"></label>
                      <div class="inf-font">
                        <AutoComplete
                          :value="selectedOwnership.commercialSpecialistId"
                          :items="commercialSpecialistsDropdown"
                          :field="'commercialSpecialistId'"
                          :isEdit="true"
                          @emitUpdate="handleOwnership"
                        ></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="ownershipDialog = false" v-t="'close'"></v-btn>
                <v-btn v-if="editModeStatus" color="primary" text @click="saveOwnership" v-t="'confirm'"></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-btn v-if="!!this.getFamilies().length" color="primary" absolute top right fab @click="addOwnership">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-text>
    </material-card>
    <material-card :heading="$t('iban', {r:''})" color="primary" v-if="customerId !== 'new' && selectedCard == 'iban'">
      <v-card-text>
        <v-row justify="center">
          <v-simple-table class="table-width">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('iban', {r:''}) }}</th>
                  <th class="text-left" v-if="editModeStatus" v-t="'actions'"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="el in customerIbans" :key="el.id">
                  <td>{{ el.id }}</td>
                  <td>{{ el.fullAddress || `${el?.abi ?? 'ABI'}/${el?.cab ?? 'CAB'}` }}</td>
                  <td v-if="editModeStatus">
                    <!-- <v-btn color="primary" fab small @click="openIban(el)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn color="error" fab small @click="deleteIban(el)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn> -->
                    <MFButtonPill :collection="getCollections(el)"></MFButtonPill>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-dialog persistent v-model="ibanDialog" width="70vw">
            <v-card>
              <v-card-title class="headline grey lighten-2">{{ $t('iban', {r:''}) }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="2">
                    <div>
                      <label>{{ $t('country', {r:''}) }} (2)</label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedIban.country"
                          :field="'country'"
                          :isEdit="true"
                          @emitUpdate="handleIban"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <div>
                      <label>CHK</label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedIban.chk"
                          :field="'chk'"
                          :isEdit="true"
                          @emitUpdate="handleIban"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <div>
                      <label>CIN</label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedIban.cin"
                          :field="'cin'"
                          :isEdit="true"
                          @emitUpdate="handleIban"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <div>
                      <label>ABI</label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedIban.abi"
                          :field="'abi'"
                          :isEdit="true"
                          @emitUpdate="handleIban"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <div>
                      <label>CAB</label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedIban.cab"
                          :field="'cab'"
                          :isEdit="true"
                          @emitUpdate="handleIban"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <div>
                      <label>CC</label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedIban.cc"
                          :field="'cc'"
                          :isEdit="true"
                          @emitUpdate="handleIban"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div>
                      <label v-t="'fullAddress'"></label>
                      <div class="inf-font">
                        <TextField
                          :value="selectedIban.fullAddress"
                          :field="'fullAddress'"
                          :isEdit="true"
                          @emitUpdate="handleIban"
                        ></TextField>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="ibanDialog = false" v-t="'close'"></v-btn>
                <v-btn v-if="editModeStatus" color="primary" text @click="saveIban" v-t="'confirm'"></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-btn color="primary" absolute top right fab @click="addIban">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-text>
    </material-card>
  </div>
</template>

<script>
import { call, get, sync } from "vuex-pathify";
import TextField from "@/components/utility/TextField";
import NumericField from "@/components/utility/NumericField";
import CheckBoxField from "@/components/utility/CheckBoxField";
import TextAreaField from "@/components/utility/TextAreaField";
import AutoComplete from "@/components/utility/AutoComplete";
import MFButtonPill from "@/components/utility/MFButtonPill";
import MFChats from "@/components/utility/MFChats";
import MFMessagesAndLogs from "@/components/utility/MFMessagesAndLogs";
import MFCustomCard from "@/components/utility/MFCustomCard";

export default {
  props: ["customerId"],
  components: {
    TextField,
    TextAreaField,
    NumericField,
    CheckBoxField,
    AutoComplete,
    MFButtonPill,
    MFChats,
    MFMessagesAndLogs,
    MFCustomCard,
  },
  name: "CustomerUpdate",
  data: () => ({
    brandUpdateFormatted: "",
    internalBrandUpdate: "",
    menu: null,
    currentCustomer: {
      businessName: null,
      active: true,
      email: null,
      cellphone: null,
      country: null,
    },
    viewedItem: {},
    mappedPostalCodes: [],
    originalPostalCodes: [],
    customerIbans: [],
    timer: null,
    locationDialog: false,
    ownershipDialog: false,
    catalogDialog: false,
    ibanDialog: false,
    editModeStatus: true,
    obiettivo: { target: 100000, prc: 34, gained: 34000, animate: true },
    selectedOwnership: {},
    selectedCatalog: {},
    priceCatalogs: [],
    selectedLocation: {
      active: true,
      default: true,
      visibility: true,
    },
    locationModel: {
      locationNumber: -1,
      name: "",
      address: "",
      city: "",
      county: "",
      country: "",
      note: "",
      phone: "",
      email: "",
      active: true,
      visibility: true,
      geometry: "",
      engrave: false,
      default: false,
      postalCode: "",
      enesiLocationId: null,
      geonameCountryCode: null,
      geonameRegionCode: null,
      geonameProvinceCode: null,
    },
    isLoadingPostalCodes: false,
    selectedIban: {},
    ibanModel: {
      country: "",
      chk: "",
      cin: "",
      abi: "",
      cab: "",
      cc: "",
      fullAddress: "",
    },
    dropdownLanguages: ["IT", "EN", "ES", "FR", "DE", "PT"],
    customerLocations: [],
    invoicingCustomerLocations: [],
    shippingCustomerLocations: [],
    customerGroupIdentifierTagId: 0,
    selectedCard: "mainInfo",
    inputCollection: {
      mainInfo: [],
      generalData: [],
      financials: [],
      contractualData: [],
      marketingData: [],
      addresses: [],
      priceCatalogs: [],
      ownerships: [],
      iban: [],
    },
  }),
  created() {
    if (this.customerId === "new") {
      this.editModeStatus = true;
    }

    this.initialize();
  },
  methods: {
    getColor(param) {
      if (param == 0) return "error";
      else if (param < 4) return "warning";
      else return "success";
    },
    getPic() {
      return !!this.editAgent && !!this.editAgent.picture
        ? this.editAgent.picture
        : require("@/assets/profile_empty.svg");
    },
    getCustomer() {
      if (this.customerId === "new") {
        if (Object.keys(this.currentCustomer).length === 0) {
          this.currentCustomer = {
            businessName: null,
            active: false,
            email: null,
            cellphone: null,
            ...this.userDefaultParameters,
          };
        }
      } else {
        this.currentCustomer = this.currentCustomer || this.customers.find((el) => el.id == +this.customerId);
      }
      return this.currentCustomer || { businessName: "" };
    },
    getCollections: function (record) {
      if (this.selectedCard == "addresses") {
        return [
          {
            icon: "mdi-pencil",
            color: "primary",
            action: () => this.openLocation(record),
          },
          {
            icon: "mdi-delete",
            color: "error",
            action: () => this.deleteLocation(record),
          },
        ];
      } else if (this.selectedCard == "ownerships") {
        return [
          {
            icon: "mdi-pencil",
            color: "primary",
            action: () => this.openOwnership(record),
          },
          {
            icon: "mdi-delete",
            color: "error",
            action: () => this.deleteOwnership(record),
          },
        ];
      } else if (this.selectedCard == "priceCatalogs") {
        return [
          {
            icon: "mdi-pencil",
            color: "primary",
            action: () => this.openCatalog(record),
          },
          {
            icon: "mdi-delete",
            color: "error",
            action: () => this.deleteCatalog(record),
          },
        ];
      } else {
        return [
          {
            icon: "mdi-pencil",
            color: "primary",
            action: () => this.openIban(record),
          },
          {
            icon: "mdi-delete",
            color: "error",
            action: () => this.deleteIban(record),
          },
        ];
      }
    },
    getFamilies: function () {
      return this.families
        .filter((el) => !this.customerOwnership?.find((x) => x.familyId == el.id))
        .map((el) => ({ text: `${el.code} - ${el.description}`, value: el.id }));
    },
    getFamiliesMapped: function () {
      return this.families.map((el) => ({ text: `${el.code} - ${el.description}`, value: el.id }));
    },
    getFamily: function (familyId) {
      return this.families.find((el) => el.id == familyId)?.description;
    },
    getAgent: function (id) {
      return this.agents.find((el) => el.id == id)?.name;
    },
    getCommercialSpecialist: function (id) {
      return this.commercialSpecialists.find((el) => el.id == id)?.name;
    },
    getFormattedProvince: function (provinceId) {
      return !provinceId ? "----" : this.apiGeonameProvinceIdGet(provinceId) || provinceId;
    },
    getProvince: function (provinceId) {
      if (!provinceId) return;
      return this.$store.get("geoname/province", provinceId)?.provinceName;
    },
    initialize: function () {
      this.initCustomerCollection().then(() => {
        // if (this.customerId !== "new") {
        this.currentCustomer = this.currentCustomer.id
          ? this.currentCustomer
          : this.customers.find((el) => el.id == this.customerId);

        this.loadInvoicingCustomerLocations();
        this.loadShippingCustomerLocations();
        this.initChannelCollection();
        this.initSectorCollection();

        if (this.isInRole(["SupervisorAgent", "CommercialSpecialist"])) {
          this.apiCustomersIdOwnershipGet(+this.customerId);
        }

        this.apiCustomersIdIbansGet(+this.customerId).then((response) => {
          this.customerIbans.length = 0;
          this.customerIbans.push(...response);
        });

        this.apiCustomersIdLocationsGet(+this.customerId).then((response) => {
          response.map((el) => !!el.provinceId && this.apiGeonameProvinceIdGet(el.provinceId));
          this.handleLocationsGet(response);
        });

        this.apiCustomerStatisticalDataCustomerTagsGet(+this.customerId).then((tags) => {
          const customerGroupIdentifier = tags.find((x) => x.key === "CustomerGroup");
          if (!!customerGroupIdentifier) {
            this.customerGroupIdentifierTagId = customerGroupIdentifier.id;
          }
        });
      });
      this.initCountryCollection();
      this.initPaymentTermCollection();
      this.initPaymentMethodCollection();
      this.initAgentCollection();
      this.initPriceCatalogCollection();
      this.initFamilyCollection().then((families) => {
        if (this.customerId !== "new") {
          this.setUpCustomersIdPriceCatalogFamilyIdGet(families);
        }
      });
      this.initCommercialSpecialistCollection();
      this.initBusinessTypeCollection();
      this.initPurchaseCategoryCollection();
      this.initFiscalProfileCollection();
      this.apiCustomerStatisticalTagGet();
      this.initVatCollection();
      this.initTimeZonesCollection();

      this.currentCustomer = {
        ...this.currentCustomer,
        ...this.userDefaultParameters,
      };

      this.locationsHeaders.find((el) => el.value == "provinceName").text =
        this.geonameAdmin2Description.charAt(0).toUpperCase() + this.geonameAdmin2Description.slice(1);

      this.loadRes();
      setTimeout(() => {
        this.setUpInputCollection();
        this.unloadRes();
      }, 5000);
    },
    handleLocationsGet: function (response) {
      this.customerLocations.length = 0;
      this.customerLocations.push(...response.sort((a, b) => +a.locationNumber - +b.locationNumber));
      this.selectedLocation = this.customerLocations[0] || {
        active: true,
        default: true,
        visibility: true,
      };
      this.currentCustomer.country = this.selectedLocation?.countryName;
    },
    addOwnership: function () {
      this.selectedOwnership = {};
      this.ownershipDialog = true;
    },
    openOwnership: function (param) {
      this.selectedOwnership = param;
      this.ownershipDialog = true;
    },
    saveOwnership: function () {
      if (!this.selectedOwnership.familyId) return;
      this.apiCustomersIdOwnershipFamilyIdPut({
        customerId: this.customerId,
        familyId: this.selectedOwnership.familyId,
        opts: this.selectedOwnership,
      }).finally(() => {
        this.selectedOwnership = {};
        this.apiCustomersIdOwnershipGet(+this.customerId);
        this.ownershipDialog = !this.ownershipDialog;
      });
    },

    setUpCustomersIdPriceCatalogFamilyIdGet: function (families) {
      Promise.all(
        families.map((fam) =>
          this.apiCustomersIdPriceCatalogFamilyIdGet({
            customerId: +this.customerId,
            familyId: fam.id,
          })
        )
      ).then((response) => {
        this.priceCatalogs.length = 0;
        this.priceCatalogs.push(...response.flat());
      });
    },
    addCatalog: function () {
      this.selectedCatalog = {};
      this.catalogDialog = true;
    },
    openCatalog: function (param) {
      this.selectedCatalog = { ...param };
      this.catalogDialog = true;
    },
    saveCatalog: function () {
      if (!this.selectedCatalog.familyId || !this.selectedCatalog.priceCatalogId) return;
      this.apiCustomersIdPriceCatalogPut({
        customerId: +this.customerId,
        ...this.selectedCatalog,
        isDefault: true,
      }).finally(() => {
        this.selectedCatalog = {};
        this.catalogDialog = !this.catalogDialog;
        this.setUpCustomersIdPriceCatalogFamilyIdGet(this.families);
      });
    },
    deleteCatalog: function (row) {
      this.apiCustomersIdPriceCatalogPriceCatalogIdDelete({
        customerId: +this.customerId,
        priceCatalogId: row.id,
      }).then(() => {
        this.setUpCustomersIdPriceCatalogFamilyIdGet(this.families);
      });
    },
    getPriceListName: function (id) {
      return this.priceCatalogCollection.find((el) => el.id == id)?.description;
    },
    addLocation: function () {
      this.selectedLocation = Object.assign({ customerId: +this.customerId, 
        active: true,
        default: true,
        visibility: true }, this.locationModel);
      this.locationDialog = true;
    },
    openLocation: function (location) {
      this.selectedLocation = location;
      if (!!this.selectedLocation.geonameCountryCode)
        this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
      if (!!this.selectedLocation.geonameRegionCode)
        this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
      this.locationDialog = true;
    },
    deleteLocation: function (el) {
      this.customerLocationsDelete(el.id)
        .then(() => this.apiCustomersIdLocationsGet(this.customerId))
        .then((response) => {
          this.handleLocationsGet(response);
        });
    },
    saveLocation: function () {
      this.selectedLocation.country =
        this.countries.find((el) => el.iso == this.selectedLocation.geonameCountryCode)?.country ||
        this.selectedLocation.country;
      if (typeof this.selectedLocation.postalCode == "number") {
        let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
        this.selectedLocation.city = postalCode.placeName ?? this.selectedLocation.city;
        this.selectedLocation.geometry = `{ "type" : 'Point', "coordinates" : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
        this.selectedLocation.geonameRegionCode =
          this.selectedLocation.geonameRegionCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
        this.selectedLocation.geonameProvinceCode =
          this.selectedLocation.geonameProvinceCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
        this.selectedLocation.postalCode = postalCode.postalCode;
      }

      this.selectedLocation.locationNumber =
        this.selectedLocation.locationNumber >= 0
          ? this.selectedLocation.locationNumber
          : this.customerLocations.length;

      if (!!this.selectedLocation.id) {
        this.customerLocationsPut(this.selectedLocation)
          .then(() => {
            this.apiCustomersIdLocationsGet(this.customerId).then((response) => {
              this.handleLocationsGet(response);
            });
          })
          .finally(() => {
            this.clearAndCloseModal();
          });
      } else {
        if(this.selectedLocation.default) {
          this.currentCustomer.geonameProvinceCode = this.selectedLocation.geonameProvinceCode;
          this.currentCustomer.geonameRegionCode = this.selectedLocation.geonameRegionCode;
          this.currentCustomer.geonameCountryCode = this.selectedLocation.geonameCountryCode;
          this.apiCustomersIdPut(this.currentCustomer);
        }
        this.customerLocationsPost(this.selectedLocation)
          .then(() => {
            this.apiCustomersIdLocationsGet(this.customerId).then((response) => {
              this.handleLocationsGet(response);
            });
          })
          .finally(() => {
            this.clearAndCloseModal();
          });
      }
    },

    addIban() {
      this.selectedIban = Object.assign({ customerId: +this.customerId }, this.ibanModel);
      this.ibanDialog = true;
    },

    openIban(iban) {
      this.selectedIban = iban;
      this.ibanDialog = true;
    },

    deleteIban(el) {
      this.apiCustomerIbansIdDelete(el.id).then(() => {
        this.handleIbansGet(+this.customerId);
      });
    },

    saveIban() {
      this.selectedIban.customerId = +this.customerId;
      let promise = !!this.selectedIban.id
        ? this.apiCustomerIbansIdPut(this.selectedIban)
        : this.apiCustomerIbansPost(this.selectedIban);

      promise.then(() => {
        this.handleIbansGet(+this.customerId);
        this.ibanDialog = false;
      });
    },

    handleIbansGet: function (id) {
      this.apiCustomersIdIbansGet(+id).then((response) => {
        this.customerIbans.length = 0;
        this.customerIbans.push(...response);
      });
    },

    clearAndCloseModal: function () {
      this.selectedLocation = Object.assign({ customerId: +this.customerId }, this.locationModel);
      this.mappedPostalCodes.length = 0;
      this.locationDialog = !this.locationDialog;
    },
    handleLocation: function (obj) {
      this.selectedLocation[obj.field] = obj.value;
      switch (obj.field) {
        case "geonameCountryCode":
          if (!!this.selectedLocation.geonameCountryCode) {
            this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
            if (!!this.selectedLocation.geonameRegionCode)
              this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          }
          break;
        case "geonameRegionCode":
          if (!!this.selectedLocation.geonameRegionCode)
            this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          break;
        case "postalCode":
          if (!obj.value || obj.value.length < 4) this.mappedPostalCodes.length = 0;
          else if (!!obj.value && obj.value.length >= 4) {
            this.isLoadingPostalCodes = true;
            window.clearTimeout(this.timer);
            this.timer = window.setTimeout(() => {
              this.apiGeonamePostalCodeGet({
                countryIsoCode: this.selectedLocation.geonameCountryCode,
                postalCode: obj.value,
              }).then((response) => {
                this.isLoadingPostalCodes = false;
                this.selectedLocation.postalCode = response[0]?.id || this.selectedLocation.postalCode;
                this.originalPostalCodes.push(...response);

                if (typeof this.selectedLocation.postalCode == "number") {
                  let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
                  this.selectedLocation.geometry = `{ type : 'Point', coordinates : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
                  this.selectedLocation.geonameRegionCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
                  this.selectedLocation.geonameProvinceCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
                }

                if (!!this.selectedLocation.geonameCountryCode)
                  this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
                if (!!this.selectedLocation.geonameRegionCode)
                  this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);

                let mappedResponse = response.map((el) => ({
                  text: `${el.postalCode} - ${el.placeName}`,
                  value: el.id,
                }));
                this.mappedPostalCodes.push(...mappedResponse);
                this.selectedLocation.city = this.originalPostalCodes.find(
                  (el) => el.id == this.selectedLocation.postalCode
                )?.placeName ?? this.selectedLocation.city;
              });
            }, 2000);
          }

          break;
        // default:
      }
    },
    handleAssignment: function (obj) {
      this.currentCustomer[obj.field] = obj.value;
      if (obj.field === "accountingEmail" && !this.currentCustomer.sdiCode) {
        this.currentCustomer.sdiCode = '0000000';
      }
      if (obj.field === "defaultInvoicingCustomerId") {
        this.currentCustomer.defaultInvoicingCustomerLocationId = null;
        this.loadInvoicingCustomerLocations();
      }
      if (obj.field === "salesChannelId") {
        this.currentCustomer.pendingAuthBypass = obj.value == 114; 
      }
      if (obj.field === "defaultShippingCustomerId") {
        this.currentCustomer.defaultShippingCustomerLocationId = null;
        this.loadShippingCustomerLocations();
      }
      if (obj.field === "customerGroupIdentifierTagId") {
        this.customerGroupIdentifierTagId = obj.value;
      }
      this.setUpInputCollection();
    },
    handleOwnership: function (obj) {
      this.selectedOwnership[obj.field] = obj.value;
    },
    handleCatalog: function (obj) {
      this.selectedCatalog[obj.field] = obj.value;
    },
    handleIban: function (obj) {
      this.selectedIban[obj.field] = obj.value;
      if (!this.selectedIban.fullAddress)
        this.selectedIban.fullAddress = `${this.selectedIban.country}${this.selectedIban.chk}${this.selectedIban.cin}${this.selectedIban.abi}${this.selectedIban.cab}${this.selectedIban.cc}`;
    },
    loadInvoicingCustomerLocations() {
      if (!!this.currentCustomer && !!this.currentCustomer.defaultInvoicingCustomerId) {
        this.apiCustomersIdLocationsGet(this.currentCustomer.defaultInvoicingCustomerId).then((response) => {
          this.invoicingCustomerLocations = response;
          if (!this.currentCustomer.defaultInvoicingCustomerLocationId && !!this.editModeStatus) {
            this.currentCustomer.defaultInvoicingCustomerLocationId = this.invoicingCustomerLocations.find(
              (x) => x.default
            )?.id;
          }
        });
      }
    },
    loadShippingCustomerLocations() {
      if (!!this.currentCustomer && !!this.currentCustomer.defaultShippingCustomerId) {
        this.apiCustomersIdLocationsGet(this.currentCustomer.defaultShippingCustomerId).then((response) => {
          this.shippingCustomerLocations = response;
          if (!this.currentCustomer.defaultShippingCustomerLocationId && !!this.editModeStatus) {
            this.currentCustomer.defaultShippingCustomerLocationId = this.shippingCustomerLocations.find(
              (x) => x.default
            )?.id;
          }
        });
      }
    },
    isInRole: function (roles) {
      return this.$store.get("auth/isInRole", roles);
    },
    openExternal: function (type) {
      let url = null;
      switch (type) {
        case "mail":
          url = `mailto:${this.getCustomer().marketingEmail}`;
          break;
        case "web":
          url = `${
            this.getCustomer().website?.includes("http")
              ? this.getCustomer().website
              : `https://${this.getCustomer().website}`
          }`;
          break;
        case "facebook":
          url = `${
            this.getCustomer().facebookPage?.includes("http")
              ? this.getCustomer().facebookPage
              : `https://${this.getCustomer().facebookPage}`
          }`;
          break;
          break;
        case "instagram":
          const account = this.getCustomer().instagramAccount.replaceAll("@", "");
          url = `https://instagram.com/${account}`;
          break;
      }
      if (!!url) {
        this.$window.open(url, "_blank");
      }
    },
    isRequired: function (param) {
      return !!this.customerDefaultParameters2Check.find((el) =>
        !!el.length ? !!el.find((x) => x.param == param) : el.param == param
      );
    },
    getSelectedCollection: function () {
      return this.inputCollection[this.selectedCard];
    },
    setUpInputCollection: function () {
      Object.keys(this.inputCollection).forEach((el) => (this.inputCollection[el].length = 0));
      this.inputCollection["mainInfo"].push(
        ...[
          {
            field: "businessName",
            inputType: "text",
            value: this.getCustomer()?.businessName,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('businessName', {r:''}),
          },
          {
            field: "email",
            inputType: "text",
            value: this.getCustomer()?.email,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('email', {r:''}),
          },
          {
            field: "customerGroupIdentifierTagId",
            inputType: "autocomplete",
            collection: this.dropdownGroupIdentifiers,
            value: this.customerGroupIdentifierTagId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('groupIdentifier'),
          },
          {
            field: "defaultInvoicingCustomerId",
            inputType: "autocomplete",
            collection: this.dropdownAllCustomers,
            value: this.getCustomer()?.defaultInvoicingCustomerId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('invoicingCustomer', {r:''}),
          },
          // {
          //   field: "businessTypeId",
          //   inputType: "autocomplete",
          //   collection: this.businessTypesDropdown,
          //   value: this.getCustomer()?.businessTypeId,
          //   emitUpdate: this.handleAssignment,
          //   placeholder: "Business Type",
          // },
          {
            field: "salesSectorId",
            inputType: "autocomplete",
            collection: this.dropdownSectors,
            value: this.getCustomer()?.salesSectorId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('sector'),
          },
          {
            field: "salesChannelId",
            inputType: "autocomplete",
            collection: this.dropdownChannels,
            value: this.getCustomer()?.salesChannelId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('channel'),
          },
          {
            field: "customerPurchaseCategoryId",
            inputType: "autocomplete",
            collection: this.customerPurchaseCategoriesDropdown,
            value: this.getCustomer()?.customerPurchaseCategoryId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('purchaseCategory'),
          },
          {
            field: "active",
            inputType: "checkbox",
            value: !!this.getCustomer()?.active,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('active'),
          },
          {
            field: "isB2b",
            inputType: "checkbox",
            value: !!this.getCustomer()?.isB2b,
            emitUpdate: this.handleAssignment,
            placeholder: "B2B",
          },
          {
            field: "potential",
            inputType: "checkbox",
            value: !!this.getCustomer()?.potential,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('potential'),
          },
          {
            field: "notes",
            inputType: "textarea",
            value: this.getCustomer()?.notes,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('notes'),
          },
          {
            field: "administrativeNotes",
            inputType: "textarea",
            value: this.getCustomer()?.administrativeNotes,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('internalNotes'),
          },
        ]
      );
      this.inputCollection["generalData"].push(
        ...[
          {
            field: "fiscalCode",
            inputType: "text",
            value: this.getCustomer()?.fiscalCode,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('fiscalCode', {r:''}),
          },
          {
            field: "code",
            inputType: "text",
            value: this.getCustomer()?.code,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('code'),
          },
          {
            field: "businessName",
            inputType: "text",
            value: this.getCustomer()?.businessName,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('businessName', {r:''}),
          },
          {
            field: "shopName",
            inputType: "text",
            value: this.getCustomer()?.shopName,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('shopName', {r:''}),
          },
          {
            field: "email",
            inputType: "text",
            value: this.getCustomer()?.email,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('email', {r:''}),
          },
          {
            field: "phone",
            inputType: "text",
            value: this.getCustomer()?.phone,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('phone'),
          },
          {
            field: "cellphone",
            inputType: "text",
            value: this.getCustomer()?.cellphone,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('cellphone'),
          },
          {
            field: "fax",
            inputType: "text",
            value: this.getCustomer()?.fax,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('fax'),
          },
          {
            field: "gender",
            inputType: "text",
            value: this.getCustomer()?.gender,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('gender'),
          },
          {
            field: "vatNumber",
            inputType: "text",
            value: this.getCustomer()?.vatNumber,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('vatNumber', {r:''}),
          },
          {
            field: "sdiCode",
            inputType: "text",
            value: this.getCustomer()?.sdiCode,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('sdi'),
          },
          {
            field: "accountingEmail",
            inputType: "text",
            value: this.getCustomer()?.accountingEmail,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('accountingEmail', {r:''}),
          },
          {
            field: "parentCode",
            inputType: "text",
            value: this.getCustomer()?.parentCode,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('parent'),
          },
          {
            field: "expectedChildren",
            inputType: "text",
            value: this.getCustomer()?.expectedChildren,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('expectedChildren'),
          },
          {
            field: "forceShowInStoresLocator",
            inputType: "checkbox",
            value: !!this.getCustomer()?.forceShowInStoresLocator,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('showInStoreLocator'),
          },
          {
            field: "nikitaTimeZoneId",
            inputType: "autocomplete",
            collection: this.dropdownTimezones,
            value: this.getCustomer()?.nikitaTimeZoneId,
            emitUpdate: this.handleAssignment,
            placeholder: "Timezone",
          },
        ]
      );
      this.inputCollection["financials"].push(
        ...[
          {
            field: "defaultPaymentTermId",
            inputType: "autocomplete",
            collection: this.paymentTermsDropdown,
            value: this.getCustomer()?.defaultPaymentTermId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('paymentTerm', {r:''}),
          },
          {
            field: "defaultPaymentMethodId",
            inputType: "autocomplete",
            collection: this.paymentMethodsDropdown,
            value: this.getCustomer()?.defaultPaymentMethodId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('paymentMethod', {r:''}),
          },
          {
            field: "defaultVatRate",
            inputType: "autocomplete",
            collection: this.dropdownVatPercentages,
            value: this.getCustomer()?.defaultVatRate,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('vatRate', {r:''}),
          },
          {
            field: "customerGroupIdentifierTagId",
            inputType: "autocomplete",
            collection: this.dropdownGroupIdentifiers,
            value: this.customerGroupIdentifierTagId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('groupIdentifier'),
          },
          {
            field: "defaultInvoicingCustomerId",
            inputType: "autocomplete",
            collection: this.dropdownAllCustomers,
            value: this.getCustomer()?.defaultInvoicingCustomerId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('invoicingCustomer', {r:''}),
          },
          {
            field: "defaultInvoicingCustomerLocationId",
            inputType: "autocomplete",
            collection: this.invoicingCustomerLocationsDropdown,
            value: this.getCustomer()?.defaultInvoicingCustomerLocationId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('invoicingCustomerLocation'),
          },
          {
            field: "defaultShippingCustomerId",
            inputType: "autocomplete",
            collection: this.dropdownAllCustomers,
            value: this.getCustomer()?.defaultShippingCustomerId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('shippingCustomer'),
          },
          {
            field: "defaultShippingCustomerLocationId",
            inputType: "autocomplete",
            collection: this.shippingCustomerLocationsDropdown,
            value: this.getCustomer()?.defaultShippingCustomerLocationId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('shippingCustomerLocation'),
          },
        ]
      );
      this.inputCollection["contractualData"].push(
        ...[
          // {
          //   field: "businessTypeId",
          //   inputType: "autocomplete",
          //   collection: this.businessTypesDropdown,
          //   value: this.getCustomer()?.businessTypeId,
          //   emitUpdate: this.handleAssignment,
          //   placeholder: "Business Type",
          // },
          {
            field: "accountingEmail",
            inputType: "text",
            value: this.getCustomer()?.accountingEmail,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('accountingEmail', {r:''}),
          },
          {
            field: "minOrder",
            inputType: "number",
            value: this.getCustomer()?.minOrder,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('minOrder'),
          },
          {
            field: "customerPurchaseCategoryId",
            inputType: "autocomplete",
            collection: this.customerPurchaseCategoriesDropdown,
            value: this.getCustomer()?.customerPurchaseCategoryId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('purchaseCategory'),
          },
          {
            field: "isPhysicalPerson",
            inputType: "checkbox",
            value: !!this.getCustomer()?.isPhysicalPerson,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('physicalPerson'),
          },
          {
            field: "isB2b",
            inputType: "checkbox",
            value: !!this.getCustomer()?.isB2b,
            emitUpdate: this.handleAssignment,
            placeholder: "B2b",
          },
          {
            field: "isExecutiveClient",
            inputType: "checkbox",
            value: !!this.getCustomer()?.isExecutiveClient,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('executiveClient'),
          },
          {
            field: "hasPaymentProblems",
            inputType: "checkbox",
            value: !!this.getCustomer()?.hasPaymentProblems,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('hasPaymentProblems'),
          },
          {
            field: "hasEngravers",
            inputType: "checkbox",
            value: !!this.getCustomer()?.hasEngravers,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('hasEngravers'),
          },
          {
            field: "orderLanguage",
            inputType: "autocomplete",
            collection: this.dropdownLanguages,
            value: this.getCustomer()?.orderLanguage,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('orderLanguage'),
          },
          {
            field: "checkCoupon",
            inputType: "checkbox",
            value: !!this.getCustomer()?.checkCoupon,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('checkCoupon'),
          },
          {
            field: "skipStats",
            inputType: "checkbox",
            value: !!this.getCustomer()?.skipStats,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('excludeFromStats'),
          },
          {
            field: "checkOrderState",
            inputType: "checkbox",
            value: !!this.getCustomer()?.checkOrderState,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('orderStatus'),
          },
          {
            field: "potential",
            inputType: "checkbox",
            value: !!this.getCustomer()?.potential,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('potential'),
          },
          {
            field: "leadStatus",
            inputType: "checkbox",
            value: !!this.getCustomer()?.leadStatus,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('leadStatus'),
          },
          {
            field: "leadValue",
            inputType: "checkbox",
            value: !!this.getCustomer()?.leadValue,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('leadValue'),
          },
          {
            field: "mailOnOrder",
            inputType: "checkbox",
            value: !!this.getCustomer()?.mailOnOrder,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('mailOnOrder'),
          },
          {
            field: "nearestDistributorId",
            inputType: "autocomplete",
            collection: this.dropdownAllCustomers,
            value: this.getCustomer()?.nearestDistributorId,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('nearestDistributor'),
          },
        ]
      );
      this.inputCollection["marketingData"].push(
        ...[
          {
            field: "marketingEmail",
            inputType: "text",
            value: this.getCustomer()?.marketingEmail,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('marketingEmail'),
          },
          {
            field: "website",
            inputType: "text",
            value: this.getCustomer()?.website,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('website'),
          },
          {
            field: "facebookPage",
            inputType: "text",
            value: this.getCustomer()?.facebookPage,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('facebook'),
          },
          {
            field: "instagramAccount",
            inputType: "text",
            value: this.getCustomer()?.instagramAccount,
            emitUpdate: this.handleAssignment,
            placeholder: this.$t('instagram'),
          },
        ]
      );
    },
    isRegular: function () {
      switch (this.selectedCard) {
        case "addresses":
        case "priceCatalogs":
        case "ownerships":
        case "iban":
          return false;
        default:
          return true;
      }
    },
    deleteOwnership: function (el) {
      this.customerOwnershipsDelete(el.id)
        .then(() => this.apiCustomersIdOwnershipGet(+this.customerId))
        .then((response) => {
          this.handleOwnership(response);
        });
    },
    saveIn: function () {
      this.$emit("saveCustomer", this.currentCustomer);
      if (this.isValidRules4customerDefaultParameters2Check(this.currentCustomer)) 
        return this.showError(this.printError4CustomerDefaultParameters2Check(this.currentCustomer));

      if (this.customerId === "new") {
        delete this.currentCustomer.id;
        this.apiCustomersPost(this.currentCustomer).then((res) => {
          this.$router.push(`/customers/${res.id}`);
          setTimeout(() => this.$window.location.reload(), 10);
        });
      } else {
        this.apiCustomersIdPut(this.currentCustomer);
        this.apiCustomerStatisticalDataCustomerIdTagsTagIdPut({
          id: this.currentCustomer.id,
          tagId: this.customerGroupIdentifierTagId,
        });
        this.editModeStatus = !this.editModeStatus;
      }
    },
    clearPostalCodes: function() {
      this.mappedPostalCodes.length = 0;
      this.selectedLocation.postalCode = null;
      this.selectedLocation = { geonameCountryCode : this.selectedLocation.geonameCountryCode };
    },
    ...call("utils", ["loadRes", "unloadRes"]),
    ...call("customers/*"),
    ...call("geoname/*"),
    ...call("families/*"),
    ...call("customerLocations/*"),
    ...call("customerIbans/*"),
    ...call("orders/*"),
    ...call("agents/*"),
    ...call("commercialSpecialists/*"),
    ...call("customerOwnerships/*"),
    ...call("priceCatalogs/*"),
    ...call("vats/*"),
    ...call("app/*"),
  },
  computed: {
    cardCollection(){
      return [
        { icon: { color: "", element: "mdi-badge-account-horizontal-outline" }, title: this.$t('mainInfo'), link: 'mainInfo'},
        { icon: { color: "", element: "mdi-clipboard-list-outline" }, title: this.$t('generalData'), link: 'generalData'},
        { icon: { color: "", element: "mdi-account-tie" }, title: this.$t('financials'), link: 'financials'},
        { icon: { color: "", element: "mdi-file-document-outline" }, title: this.$t('contractualData'), link: 'contractualData'},
        { icon: { color: "", element: "mdi-web" }, title: this.$t('marketingData'), link: 'marketingData'},
        { icon: { color: "", element: "mdi-map-marker-outline" }, title: this.$t('addresses'), link: 'addresses'},
        { icon: { color: "", element: "mdi-book-open-page-variant" }, title: this.$t('priceCatalogs'), link: 'priceCatalogs'},
        { icon: { color: "", element: "mdi-handshake-outline" }, title: this.$t('ownership'), link: 'ownerships'},
        { icon: { color: "", element: "mdi-bank" }, title: this.$t('iban', {r:''}), link: 'iban'},
      ]
    },
    noCeg(){
      return {
        search: "",
        headers: [
          { text: this.$t('code'), align: "start", value: "code" },
          { text: this.$t('description'), value: "description" },
          { text: this.$t('address'), value: "formatted_address" },
        ],
        collectionNoCeg: [
        ],
      }
    },
    colorTab(){
      return {
        search: "",
        headers: [
          { text: this.$t('code'), align: "start", value: "code" },
          { text: this.$t('description'), value: "description" },
          { text: this.$t('orders'), value: "orders" },
          { text: this.$t('status'), value: "status" },
        ],
        collection: [
        ],
      }
    },
    locationsHeaders(){
      return [
        { text: "#", value: "locationNumber" },
        { text: this.$t('address'), value: "address" },
        { text: this.$t('city'), value: "city" },
        { text: this.$t('province'), value: "provinceName" },
        { text: this.$t('country', {r:''}), value: "countryName" },
        { text: this.$t('default'), value: "default" },
        { text: this.$t('actions'), value: "actions", sortable: false, align: "center" },
      ]
    },
    customers: sync("customers/allCustomers"),
    dropdownAllCustomers: get("customers/dropdownAllCustomers"),
    dropdownCustomers: get("customers/dropdownCustomers"),
    dropdownCountries: get("geoname/dropdownCountriesIso"),
    dropdownRegions: get("geoname/dropdownRegions"),
    dropdownProvinces: get("geoname/dropdownProvinces"),
    countries: get("geoname/countries"),
    dropdownVatPercentages: get("vats/dropdownVatPercentages"),
    paymentTermsDropdown: get("orders/dropdownPaymentTerms"),
    paymentMethodsDropdown: get("orders/dropdownPaymentMethods"),
    agents: get("agents/agents"),
    commercialSpecialists: get("commercialSpecialists/commercialSpecialists"),
    commercialSpecialistsDropdown: get("commercialSpecialists/dropdownCommercialSpecialists"),
    agentsDropdown: get("agents/dropdownAgents"),
    customerOwnership: get("customers/customerOwnership"),
    businessTypes: sync("customers/businessTypes"),
    priceCatalogCollection: sync("priceCatalogs/priceCatalogs"),
    dropdownGroupIdentifiers: get("customers/dropdownGroupIdentifiers"),
    customerPurchaseCategoriesDropdown: get("customers/customerPurchaseCategoriesDropdown"),
    showSubAgent: get('tenantConfiguration/showSubAgent'),
    businessTypesDropdown() {
      return this.businessTypes.map((x) => ({ text: x.description, value: x.id }));
    },
    dropdownChannels: get("customers/dropdownChannels"),
    dropdownSectors: get("customers/dropdownSectors"),
    fiscalProfiles: sync("customers/fiscalProfiles"),
    fiscalProfilesDropdown() {
      return this.fiscalProfiles.map((x) => ({ text: x.description, value: x.id }));
    },
    priceCatalogDropdown() {
      return this.priceCatalogCollection.map((x) => ({ text: x.code, value: x.id }));
    },
    families: get("families/families"),
    invoicingCustomerLocationsDropdown() {
      return this.invoicingCustomerLocations.map((x) => ({ text: `${x.name} - ${x.address}`, value: x.id }));
    },
    shippingCustomerLocationsDropdown() {
      return this.shippingCustomerLocations.map((x) => ({ text: `${x.name} - ${x.address}`, value: x.id }));
    },
    showIban: get("tenantConfiguration/showIbanSelector"),
    showPaymentMethods: get("tenantConfiguration/showPaymentMethods"),
    showMarketingData: get("tenantConfiguration/showMarketingData"),
    showContractualData: get("tenantConfiguration/showContractualData"),
    userDefaultParameters: get("tenantConfiguration/userDefaultParameters"),
    customerDefaultParameters2Check: get("tenantConfiguration/customerDefaultParameters2Check"),
    rules4customerDefaultParameters2Check: get("tenantConfiguration/rules4customerDefaultParameters2Check"),
    isValidRules4customerDefaultParameters2Check: get("tenantConfiguration/isValidRules4customerDefaultParameters2Check"),
    printError4CustomerDefaultParameters2Check: get("tenantConfiguration/printError4CustomerDefaultParameters2Check"),
    geonameAdmin1Description: get("tenantConfiguration/geonameAdmin1Description"),
    geonameAdmin2Description: get("tenantConfiguration/geonameAdmin2Description"),
    dropdownTimezones: get("customers/dropdownTimezones")
  },
};
</script>

<style>
a {
  text-decoration: none;
}

.stepper-list {
  display: flex;
  padding: 3rem;
  overflow-x: scroll;
}

.stepper-list::-webkit-scrollbar {
  display: none;
}

.stepper {
  cursor: pointer;
  height: 120px;
  width: 100%;
  /* min-width: 200px; */
  padding: 1.5rem;
  border-radius: 16px;
  /* background: #17141d; */
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  margin: 0;
  scroll-snap-align: start;
  clear: both;
  position: relative;
  border: 2px solid rgba(52, 52, 56, 0.2);
}

.stepper:hover {
  background-color: #242f40;
  border-color: #242f40;
  color: #f1f2f2;
}

.selected {
  background-color: #242f40;
  border-color: #242f40;
  color: #f1f2f2;
  transform: translateY(-30px);
}

.colorSelected {
  color: #f1f2f2 !important;
}

.stepper:focus {
  background-color: #242f40;
  color: white;
}

.stepper:focus-within ~ .stepper,
.stepper:hover ~ .stepper {
  transform: translateX(130px);
}

.stepper:not(:first-child) {
  margin-left: -130px;
}

.stepper-header {
  margin-bottom: auto;
}

.stepper-header p {
  font-size: 14px;
  margin: 0 0 1rem;
  color: #7a7a8c;
}

.stepper-header h2 {
  font-size: 20px;
  margin: 0.25rem 0 auto;
  text-decoration: none;
  border: 0;
  display: inline-block;
  cursor: pointer;
}

.stepper-author {
  margin: 3rem 0 0;
  display: grid;
  grid-template-columns: 75px 1fr;
  align-items: center;
  position: relative;
}

.author-avatar {
  grid-area: auto;
  align-self: start;
  position: relative;
  box-sizing: border-box;
}

.author-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: grayscale(100%);
  display: block;
  overflow: hidden;
  margin: 16px 10px;
}

.author-name {
  grid-area: auto;
  box-sizing: border-box;
}

.author-name-prefix {
  font-style: normal;
  font-weight: 700;
  color: #7a7a8c;
}

.half-circle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 48px;
  fill: none;
  stroke: #ff8a00;
  stroke-width: 8;
  stroke-linecap: round;
  pointer-events: none;
}

.tags {
  margin: 1rem 0 2rem;
  padding: 0.5rem 0 1rem;
  line-height: 2;
  margin-bottom: 0;
}

.tags a {
  font-style: normal;
  font-weight: 700;
  font-size: 0.5rem;
  color: #7a7a8c;
  text-transform: uppercase;
  font-size: 0.66rem;
  border-radius: 2rem;
  padding: 0.2rem 0.85rem 0.25rem;
  position: relative;
}

.tags a:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  background-clip: text;
  border-color: white;
}

.table-width {
  width: 100%;
}

.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stepper-container-confirm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.confirm {
  border-color: #3ACA21;
  background: #3ACA21;
  color: white;
}

.confirm:hover {
  background: rgb(16, 161, 16);
  border-color: rgb(16, 161, 16);
  color: #f1f2f2;
}

  
</style>
