<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday" v-t="'today'"></v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev"><v-icon small>mdi-chevron-left</v-icon></v-btn>
          <v-btn fab text small color="grey darken-2" @click="next"><v-icon small>mdi-chevron-right</v-icon></v-btn>
          <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right class="mx-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'"><v-list-item-title>{{ $t('day') }}</v-list-item-title></v-list-item>
              <v-list-item @click="type = 'week'"><v-list-item-title>{{ $t('week') }}</v-list-item-title></v-list-item>
              <v-list-item @click="type = 'month'"><v-list-item-title>{{ $t('month') }}</v-list-item-title></v-list-item>
              <v-list-item @click="type = '4day'"><v-list-item-title>{{ $t('fourDays') }}</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
          <v-menu bottom right class="mr-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span v-t="'colors'"></span>
                <v-icon right>mdi-information-variant-circle-outline</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-content><v-list-item-title></v-list-item-title>{{ $t('completed') }}</v-list-item-content>
                <v-list-item-icon><v-icon color="green">mdi-circle</v-icon></v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><v-list-item-title></v-list-item-title>{{ $t('toDo') }}</v-list-item-content>
                <v-list-item-icon><v-icon color="orange">mdi-circle</v-icon></v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><v-list-item-title></v-list-item-title>{{ $t('expired') }}</v-list-item-content>
                <v-list-item-icon><v-icon color="red">mdi-circle</v-icon></v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><v-list-item-title></v-list-item-title>{{ $t('neverExpire') }}</v-list-item-content>
                <v-list-item-icon><v-icon color="blue">mdi-circle</v-icon></v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><v-list-item-title></v-list-item-title>{{ $t('other') }}</v-list-item-content>
                <v-list-item-icon><v-icon color="grey">mdi-circle</v-icon></v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar 
          ref="calendar" 
          v-model="focus" 
          color="primary" 
          :events="collection" 
          :event-color="getEventColor"
          :type="type" 
          @click:event="showEvent" 
          @click:more="viewDay" 
          @click:date="viewDay"
          @change="initialize"></v-calendar>
        <v-menu v-model="openNew" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-list>
              <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title class="text--primary" v-text="'Task: ' + selectedEvent.name"></v-list-item-title>
                      <v-list-item-subtitle class="subtitle-2 text--disabled" v-text="'Customer: ' + formattedNameC(selectedEvent.entityId)"></v-list-item-subtitle>
                      <v-list-item-subtitle class="fade" v-text="selectedEvent.description"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                      <v-list-item-action-text>{{ $t('applicant') }}: {{ this.formattedName(selectedEvent.createdBy) }}</v-list-item-action-text>
                      <v-list-item-action-text>{{ $t('executor') }}: {{ this.formattedName(selectedEvent.assignedTo) }}</v-list-item-action-text>
                      <v-list-item-action-text>{{ $t('expiration') }}: {{ selectedEvent.due | formatDate }}</v-list-item-action-text>
                  </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import {call, get, sync} from "vuex-pathify";
export default {
  name: "MFCalendar",
  props: {
    customerId: { type: Number }
  },
  computed: {
    formattedName: get("authUsers/formattedName"),
    formattedNameC: get("customers/formattedName"),
    typeToLabel(){
      return ({ 
        month: this.$t('month'), 
        week: this.$t('week'), 
        day: this.$t('day'), 
        '4day': $t('fourDays') 
      });
    }
  },
  data: () => ({
    focus: '',
    openNew: false,
    type: 'month',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    collection: []
  }),
  mounted() {
    this.$refs.calendar.checkChange();
    this.initialize();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    getEventColor(e) {
      return e.color;
    },
    getColor(t) {
      if(!!t?.ended) return 'green';
      if(!t?.due) return "blue";
      if(!t?.ended && this.$moment().isAfter(t?.due)) return 'red';
      if(!!t?.due) return 'orange';
      return 'grey';
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true; }))
      }
      if (!this.selectedOpen) open();
      else {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      }
      nativeEvent.stopPropagation()
    },
    initialize() {
      let promise = !!this.customerId? 
        this.apiTicketCustomerCustomerIdGet(+this.customerId)
        : this.apiTicketAssignedToGet();
      promise.then(response => {
        this.collection.length = 0;
        this.collection.push(...response.map((x) => ({
          name: x.name,
          start: new Date(x.startAt ?? x.createdAt),
          end: new Date(x.ended ?? x.due),
          color: this.getColor(x),
          timed: false,
          ...x
        })));
      })
      .catch((err) => {
          console.error(err);
      });
    },
    ...call("notifications/*"),
  },
}
</script>
