<template>
    <v-card class="my-3">
        <v-card-title v-t="'downloadDocuments'"></v-card-title>
        <v-card-subtitle>
            {{ $t('selectedOrders') }} : {{ orders.length }}
        </v-card-subtitle>

        <v-alert v-if="someTransmitted()" border="bottom" colored-border type="warning" elevation="2">
            {{ $t('alertMetodo') }}'TRANSMITTED'.
        </v-alert>
        
        <v-card-actions v-else>
            <v-btn-toggle class="float-right" v-model="icon" borderless>
                <v-btn value="left" @click="download('orders')">
                    <span class="hidden-sm-and-down" v-t="'orders'"></span>
                    <v-icon right>mdi-file-document-outline</v-icon>
                </v-btn>
                <v-btn value="center" @click="download('customers')">
                    <span class="hidden-sm-and-down" v-t="'customers'"></span>
                    <v-icon right>mdi-account</v-icon>
                </v-btn>
                <v-btn value="right" @click="download('locations')">
                    <span class="hidden-sm-and-down" v-t="'locations'"></span>
                    <v-icon right>mdi-map-marker</v-icon>
                </v-btn>
                <v-btn value="justify" @click="download('all')" disabled>
                    <span class="hidden-sm-and-down" v-t="'downloadAll'"></span>
                    <v-icon right>mdi-all-inclusive</v-icon>
                </v-btn>
            </v-btn-toggle>
        </v-card-actions>
    </v-card>
</template>

<script>
import {get, sync, call} from "vuex-pathify";

export default {
  name: "OrdersDownload4Metodo",
  props : {
    orders: Array 
  },
  data: () => ({
    icon: 'justify',
    localCustomers: [],
    localLocations: []
  }),
  computed: {
    customers: get('customers/bulkCustomers'),
    orderStatus: sync("orders/orderStatus"),
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
        // this.initBulkCustomerCollection();
        this.initOrderStatusCollection();
    },

    someTransmitted: function() {
        let statusCode = this.orderStatus.find(el => el.code == 'TRANSMITTED');
        return this.orders.find(el => el.orderStatusId == statusCode?.id);
    },

    download: function(param) {
        this.localCustomers.length = 0;
        this.localCustomers = {
          id: []
        }
        this.localLocations.length = 0;
        this.orders.forEach(el => {
          this.localCustomers.id.push(el.customerId);
          this.localLocations.push(el.customerId);
        });
        
        switch (param) {
            case 'orders': 
                this.apiOrderExportMetodoPost()
                .then((response) => {
                    this.downloadCsv("esportazione_ordini", response);
                })
                .catch((e) => e);
                break;
            case 'customers': 
                this.apiCustomersExportMetodoPost(this.localCustomers)
                .then((response) => {
                    this.downloadCsv("esportazione_clienti", response);
                })
                .catch((e) => e);
                break;
            case 'locations': 
                this.apiCustomerLocationsExportMetodoPost(this.localLocations)
                .then((response) => {
                    this.downloadCsv("esportazione_sedi_clienti", response);
                })
                .catch((e) => e);
                break;
            default : 
        }
    },

    purifyEliteStr: function(str) {
        return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },

    downloadCsv: async function(filename, csvContent) {
        var binaryData = [];
        binaryData.push(csvContent);

        var blob = new Blob(binaryData);
        const text = await blob.text();
        const purifiedText = this.purifyEliteStr(text);
        
        const url = window.URL.createObjectURL(new Blob([purifiedText], {type: blob.type}));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${filename}.txt`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    },

    ...call('customers', [
        'initBulkCustomerCollection',
        'apiCustomersExportMetodoPost',
    ]),
    ...call('orders', [
        'apiOrderExportMetodoPost',
        'initOrderStatusCollection',
    ]),
    ...call('customerLocations', [
        'apiCustomerLocationsExportMetodoPost'
    ]),
  }
}
</script>