<template>
  <section>
    <v-row class="pa-2">
      <v-col cols="12" md="4" lg="3" class="profile-Agent">
        <v-avatar size="200">
          <v-img :src="getPic()"></v-img>
        </v-avatar>

        <div class="mt-3 name-Agent">
          <TextField 
            @emitUpdate="handleAssignment" 
            :isEdit="editModeStatus" 
            :disabled="false"
            :value="getAgent()?.name" 
            :loading="false" 
            :field="$t('name')"
          ></TextField>

        </div>
        <p class="name-Agent">
          {{ $t('code') }} {{ getAgent()?.code }}
        </p>
      </v-col>
      <v-col cols="12" md="8">
        <div class="mt-3">
          <label>{{ $t('email', {r:''})}}</label>
          <div class="inf-font">
            <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                       :value="getAgent()?.email  " :loading="false" :field="$t('email')"></TextField>
          </div>
        </div>
        <div class="mt-3">
          <label>{{ $t('country', {r:''}) }}</label>
          <div class="inf-font">
            <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                       :value="getAgent()?.country " :loading="false" :field="$t('country', {r:''})"></TextField>
          </div>
        </div>
        <div class="mt-3">
          <div class="inf-font address-container">
            <div class="overwrite">
              <label class="address-label">{{ $t('address')}}</label>
              <TextField @emitUpdate="handleAssignment" class="address-text" :isEdit="editModeStatus" :disabled="false"
                         :value="getAgent()?.address " :loading="false" :field="$t('address')"></TextField>
            </div>
            <div class="overwrite">
              <label class="address-label">{{ $t('city')}}</label>
              <TextField @emitUpdate="handleAssignment" class="address-text" :isEdit="editModeStatus" :disabled="false"
                         :value="getAgent()?.city " :loading="false" :field="$t('city')"></TextField>
            </div>
            <div class="overwrite">
              <label class="address-label">{{ $t('postalCode')}}</label>
              <TextField @emitUpdate="handleAssignment" class="address-text" :isEdit="editModeStatus" :disabled="false"
                         :value="getAgent()?.postalCode " :loading="false" :field="$t('postalCode')"></TextField>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <label>{{ $t('cellphone')}}</label>
          <div class="inf-font">
            <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                       :value="getAgent()?.cellphone " :loading="false" :field="$t('cellphone')"></TextField>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="page-bg ">
      <v-tabs fixed-tabs class="page-bg">
        <v-tab class="page-bg">
          <div v-if="!$vuetify.breakpoint.mobile">
            <v-icon left>
              mdi-account
            </v-icon>
            {{ $t('informations')}}
          </div>
          <div v-else>
            <v-icon left>
              mdi-account
            </v-icon>
          </div>
        </v-tab>
        <v-tab class="page-bg">
          <div v-if="!$vuetify.breakpoint.mobile">
            <v-icon left>
              mdi-table-large
            </v-icon>
            {{ $t('tables')}}
          </div>
          <div v-else>
            <v-icon left>
              mdi-table-large
            </v-icon>
          </div>
        </v-tab>
        <v-tab class="page-bg">
          <div v-if="!$vuetify.breakpoint.mobile">
            <v-icon left>
              mdi-star
            </v-icon>
            {{ $t('goals')}}
          </div>
          <div v-else>
            <v-icon left>
              mdi-star
            </v-icon>
          </div>
        </v-tab>

        <v-tab-item class="page-bg pa-5">
          <v-row>
            <v-col cols="12">
              <material-card :heading="$t('note')" color="primary">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="mt-3">
                        <div class="inf-font">
                          <TextAreaField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                         :field="$t('notes')" :value="getAgent()?.notes " :loading="false"></TextAreaField>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

              </material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <material-card :heading="$t('generalData')" color="primary">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <div class="mt-3">
                        <label>{{ $t('name')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :value="getAgent()?.name" :field="$t('name')" :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('code')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="true"
                                     :value="getAgent()?.code" :field="$t('code')" :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('cellphone')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :field="$t('cellphone')" :value="getAgent()?.cellphone " :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('email', {r:''})}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :field="$t('email', {r:''})" :value="getAgent()?.email " :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('address')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :value="getAgent()?.address " :field="$t('address')" :loading="false"></TextField>
                        </div>
                      </div>


                    </v-col>
                    <v-col cols="12" md="4">
                      <div class="mt-3">
                        <label>{{ $t('city')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :value="getAgent()?.city " :field="$t('city')" :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('state')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :value="getAgent()?.state " :field="$t('state')" :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('postalCode')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :value="getAgent()?.postalCode " :field="$t('postalCode')" :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('country', {r:''})}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :field="$t('country', {r:''})" :value="getAgent()?.country " :loading="false"></TextField>
                        </div>
                      </div>

                    </v-col>
                    <v-col cols="12" md="4">


                      <div class="mt-3">
                        <label>{{ $t('fiscalCode', {r:''})}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :value="getAgent()?.fiscalCode " :field="$t('fiscalCode', {r:''})" :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('vatNumber', {r:''})}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :value="getAgent()?.vatNumber " :field="$t('vatNumber', {r:''})" :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('target')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :field="$t('target')" :value="getAgent()?.target " :loading="false"></TextField>
                        </div>
                      </div>

                      <div class="mt-4">
                        <label>{{ $t('active')}}</label>
                        <div class="inf-font">
                          <CheckBoxField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                         :value="getAgent()?.active" :field="$t('active')" :loading="false"></CheckBoxField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('enasarco')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :field="$t('enasarco')" :value="getAgent()?.enasarco " :loading="false"></TextField>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

              </material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <material-card :heading="$t('birthday')" color="primary">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">


                      <div class="mt-3">
                        <label>{{ $t('birthday')}}</label>
                        <div class="inf-font">
                          <DatePickerField
                              @emitUpdate="handleAssignment"
                              :isEdit="editModeStatus"
                              :disabled="false"
                              :value="getAgent()?.birthday"
                              :field="$t('birthday')"
                              :loading="false"
                          ></DatePickerField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('address')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :field="$t('address')" :value="getAgent()?.birthAddress "
                                     :loading="false"></TextField>
                        </div>
                      </div>


                    </v-col>
                    <v-col cols="12" md="4">
                      <div class="mt-3">
                        <label>{{ $t('country', {r:''})}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :field="$t('country', {r:''})" :value="getAgent()?.birthCountry "
                                     :loading="false"></TextField>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label>{{ $t('city')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :field="$t('city')" :value="getAgent()?.birthCity " :loading="false"></TextField>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="4">
                      <div class="mt-4">
                        <label>{{ $t('state')}}</label>
                        <div class="inf-font">
                          <TextField @emitUpdate="handleAssignment" :isEdit="editModeStatus" :disabled="false"
                                     :field="$t('county', {r:''})" :value="getAgent()?.birthCounty "
                                     :loading="false"></TextField>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

              </material-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="page-bg pa-5">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="7">
              <material-card :heading="$t('withoutParam', {param:$t('ceg')})" color="primary">
                <v-card-text>
                  <v-sheet>
                    <mf-simple-table :headers="noCeg.headers" :items="noCeg.collectionNoCeg">
                    </mf-simple-table>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="5">
              <material-card :heading="$t('withoutParam', {param:$t('orders')})" color="primary">
                <v-card-text>
                  <v-sheet>
                    <v-card-title>
                      <!-- <v-spacer></v-spacer> -->
                      <v-text-field v-model="colorTab.search" append-icon="mdi-magnify" label="Search" single-line
                                    hide-details>
                      </v-text-field>
                    </v-card-title>
                    <v-data-table :headers="colorTab.headers" :items="colorTab.collection" :search="colorTab.search"
                                  class="elevation-1">
                      <template v-slot:item.orders="{ item }">
                        <v-chip :color="getColor(item.orders)" dark>
                          {{ item.orders }}
                        </v-chip>
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="page-bg pa-5">

          <v-row>
            <v-col cols="12">
              <material-card :heading="'GOAL : ' + obiettivo.target+ ' $' " color="primary">
                <v-card-text>
                  <v-sheet>

                    <v-progress-linear :value="obiettivo.prc" color="grey" height="40" rounded>

                      <strong>{{ Math.ceil(obiettivo.gained) + " € / " + Math.ceil(obiettivo.target) + " €" }} </strong>

                    </v-progress-linear>
                    <br>
                    <v-progress-linear :value="obiettivo.prc" color="grey" height="40" rounded>
                      <strong>{{ Math.ceil((obiettivo.gained * 100) / obiettivo.target) }} %</strong>
                    </v-progress-linear>
                  </v-sheet>
                </v-card-text>

              </material-card>
            </v-col>
          </v-row>

        </v-tab-item>
      </v-tabs>

    </v-row>
  </section>
</template>

<script>
import { call, sync } from "vuex-pathify";
//import TextField from " ../utility/TextField.vue "
import TextField from '@/components/utility/TextField';
import DatePickerField from '@/components/utility/DatePickerField';
import CheckBoxField from '@/components/utility/CheckBoxField';
import IamUsersTable from '@/components/IamUsersTable';

export default {
  props: ["agentId"],
  components: {
    TextField,
    DatePickerField,
    CheckBoxField,
    IamUsersTable
  },
  name: "AgentView",
  data: () => ({
    currentAgent: {},
    editModeStatus: false,
    iamUserModalIsVisible: false,
    agentUsers: [],
    obiettivo: {
      target: 100000,
      prc: 34,
      gained: 34000,
      animate: true,
    },
    noCeg: {
      search: "",
      headers: [
        {
          text: "Code",
          align: "start",
          value: "code",
        },
        { text: "Description", value: "description" },
        { text: "Address", value: "formatted_address" },
      ],
      collectionNoCeg: [],
    },
    colorTab: {
      search: "",
      headers: [
        {
          text: "Code",
          align: "start",
          value: "code",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Orders",
          value: "orders",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      collection: [
      ],
    },
  }),
  created() {
    if (this.agentId === "new") {
      this.editModeStatus = true;
    }
    this.initAgentCollection();
  },
  methods: {
    getColor(param) {
      if (param == 0) return "error";
      else if (param < 4) return "warning";
      else return "success";
    },
    getPic() {
      return !!this.editAgent && !!this.editAgent.picture
        ? this.editAgent.picture
        : require("@/assets/profile_empty.svg");
    },
    getAgent: function () {
      if (this.agentId === "new") {
        if (Object.keys(this.currentAgent).length === 0) {
          this.currentAgent = {
            name: null,
            email: null,
            country: null,
            address: null,
            city: null,
            postalCode: null,
            cellphone: null,
          };
        }
      } else {
        this.currentAgent = this.agents.find((el) => el.id == this.agentId) || this.currentAgent;
      }
      return this.currentAgent;
    },
    invertEditMode: function () {
      this.editModeStatus = !this.editModeStatus;
    },

    saveIn: function () {
      this.$emit("saveIn", this.currentAgent);
      if (this.agentId === "new") {
        delete this.currentAgent.id;
        this.apiAgentPost(this.currentAgent).then((res) => {
          this.$router.push(`/agents/${res.id}`);
        });
      } else {
        this.apiAgentIdPut(this.currentAgent);
        this.editModeStatus = !this.editModeStatus;
      }
    },
    handleAssignment: function (obj) {
      this.currentAgent[obj.field] = obj.value;
    },
    isInRole: function (roles) {
      return this.$store.get("auth/isInRole", roles);
    },
    ...call("agents/*"),
    ...call("iam/*")
  },
  computed: {
    agents: sync("agents/agents"),
  },
};
</script>

<style>
.page-bg {
  background-color: #f5f5f5 !important;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: #f5f5f5 !important;
}

.profile-Agent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-Agent {
  display: flex;
  justify-content: center;
  font-size: 27px;
  font-weight: 600;
  text-align: center;
}

.inf-font {
  font-size: 18px;
  color: black;
  font-weight: 400;
}

.edit-button-container {
  height: 6vh;
  width: 8vw;
  position: absolute;
  left: 0;
}

.address-text {
  white-space: nowrap;
  margin-right: 2em;
}

.overwrite {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 3%;
  width: 30%;
}

.address-label {
  color: #333333;
  font-family: "Montserrat", sans serif !important;
  line-height: 1.5;
  font-size: 16px;
}

.address-container {
  display: flex;
}

@media screen and (max-width: 960px) {
  .address-container {
    flex-direction: column;
  }

  .overwrite {
    width: 100%;
  }
}

/*----*/
.page-bg {
  background-color: #f5f5f5 !important;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: #f5f5f5 !important;
}

.profile-Agent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-Agent {
  display: flex;
  justify-content: center;
  /* font-size: 27px; */
  font-weight: 600;
  text-align: center;
  padding: 0;
}

.inf-font {
  font-size: 18px;
  color: black;
  font-weight: 400;
}

.edit-button-container {
  height: 6vh;
  width: 8vw;
  position: absolute;
}

.address-text {
  white-space: nowrap;
  margin-right: 2em;
}

.overwrite {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 3%;
  width: 30%;
}

.address-label {
  color: #333333;
  font-family: "Montserrat", sans serif !important;
  line-height: 1.5;
  font-size: 16px;
}

.edit-container {
  position: absolute;
  z-index: 6;
}

.address-container {
  display: flex;
}

.header-container {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.border-isActive {
  border: 8px solid green;
}

.border-notActive {
  border: 8px solid rgb(192, 33, 33);
}

@media screen and (max-width: 960px) {
  .address-container {
    flex-direction: column;
  }

  .overwrite {
    width: 100%;
  }
}

.required {
  font-weight: 700;
}

.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

label {
  font-size: 1.2rem;
  font-weight: 500;
}

.card-content {
  height: 100%;
  display: flex;
  align-items: center;
}

.card-container {
  min-height: 60vh;
}
</style>
