<template>
    <v-alert border="bottom" colored-border outlined :type="type" elevation="1">
        {{ message }}
    </v-alert>
</template>
<script>
export default {
    name: "Alert",
    props: {
        message : { type: String, default: "Error! Try later" },
        type : { type: String, default: "error" }
    }
}
</script>