<template>
  <v-container>
    <v-timeline dense clipped class="logs">

      <v-timeline-item v-for="log in collection.slice().reverse()" :key="log.id" class="mb-4" :color="getColor(log)" small>
        <v-row v-if="getTagCollection(log).length">    
            <v-chip v-for="tag in getTagCollection(log)" :key="tag.code" class="white--text ml-0 mr-1" :color="tag.color" label small>{{ tag.description }}</v-chip>
        </v-row>  
        <v-row justify="space-between">
          <v-col cols="8">
            <h3>{{ log.title }}</h3>
            <p>{{ log.description }}</p>
          </v-col>
          <v-col class="text-right" cols="4">{{ getDate(log.creationTimestamp) }}</v-col>
        </v-row>
        <v-spacer />
      </v-timeline-item>

    </v-timeline>
  </v-container>
</template>
<script>
    export default {
        name: "MFLogs",
        data: () => ({
            collection: [
                { tags: [{id: "ABC", code: "ABC", description: "ABC", color: "primary"}], id: 1, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title__1", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 2, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title__2", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 3, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title__3", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 4, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title__4", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 5, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title__5", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [{id: "XYZ", code: "XYZ", description: "XYZ", color: "info"}], id: 6, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title__6", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 7, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title__7", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 8, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title__8", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 9, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title__9", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 10, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title_10", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [{id: "XYZ", code: "XYZ", description: "XYZ", color: "info"}, {id: "ABC", code: "ABC", description: "ABC", color: "primary"}], id: 11, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title_11", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 12, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title_12", description: "Cras ultricies ligula sed magna dictum porta.", closed: true },
                { tags: [], id: 13, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title_13", description: "Cras ultricies ligula sed magna dictum porta.", closed: false },
                { tags: [], id: 14, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title_14", description: "Cras ultricies ligula sed magna dictum porta.", closed: false },
                { tags: [{id: "XYZ", code: "XYZ", description: "XYZ", color: "info"}, {id: "ABC", code: "ABC", description: "ABC", color: "primary"}, {id: "QWERTY", code: "QWERTY", description: "QWERTY", color: "error"}, {id: "PIPPO", code: "PIPPO", description: "PIPPO", color: "warning"}], id: 15, creationTimestamp: "2021-12-01T00:00:00.000Z", title: "title_15", description: "Cras ultricies ligula sed magna dictum porta.", closed: false }
            ]
        }),
        props: {
            customerId: { type: Number },
            loading: { type: Boolean, default: false },
        },
        computed: {
            timeline () {
                return this.events.slice().reverse()
            },
        },
        methods: {
            getTagCollection: function(log) {
                return this.collection.find(el => el.id == log.id).tags
            },
            getColor: (log) => log.closed? "primary" : "info",
            getDate: function(date) { return this.$moment(date).format("DD-MM-YYYY") },
        },
    }
</script>
<style>
    .logs {
        max-height: 50vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .my-log-label {
        margin-right: 5px;
    }
</style>