<template>
    <v-alert prominent type="info">
        <v-row align="center">
            <v-col class="grow">{{ bodyMessage }}</v-col>
            <v-col class="shrink">
                <v-btn @click="action">{{ confirmMessage }}</v-btn>
            </v-col>
        </v-row>
    </v-alert>
</template>
<script>
export default {
    name: "Confirm",
    props: {
        bodyMessage : { type: String, default: "Are you sure?" },
        confirmMessage : { type: String, default: "CONFIRM" },
        action : { type: Function, default: () => {} },
    }
}
</script>