<template>
    <v-list two-line class="min-w-20">
        <v-list-item-group v-model="selected" active-class="pink--text" multiple>
            <template v-for="(item, index) in items">
                <v-list-item :key="item.description + index">
                    <template v-slot:default="{ active }">
                        <v-list-item-content @click="open(item)">
                            <v-list-item-title class="text--primary" v-text="'Task: ' + item.name"></v-list-item-title>
                            <v-list-item-subtitle class="subtitle-2 text--disabled"
                                v-text="'Customer: ' + item.target"></v-list-item-subtitle>
                            <v-list-item-subtitle class="fade" v-text="item.description"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>{{ $t('applicant') }}: {{ item.creator }}</v-list-item-action-text>
                            <v-list-item-action-text>{{ $t('executor') }}: {{ formattedName(item.assignedTo) }}</v-list-item-action-text>
                            <v-list-item-action-text>{{ $t('expiration') }}: {{ item.due | formatDate }}</v-list-item-action-text>
                            <v-btn icon small @click="open(item)">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                            <!-- <v-icon v-if="!active" color="grey lighten-3">mdi-check-outline</v-icon>
                            <v-icon v-else color="green darken-3">mdi-check</v-icon> -->
                        </v-list-item-action>
                    </template>
                </v-list-item>
                <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
            </template>
        </v-list-item-group>
        <v-list-item-group v-if="!items.length">
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon color="green">mdi-check</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-t="'allDone'"></v-list-item-title>
                    <v-list-item-subtitle v-t="'noTasks'"></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>
<script>
import { call, sync, get } from 'vuex-pathify';
export default {
    name: "MFNotificationsInner",
    data: () => ({
        selected: [],
        items: [],
    }),
    computed: {
        formattedName: get("authUsers/formattedName"),
        formattedNameC: get("customers/formattedName"),
    },
    methods: {
        open: function (task) {
            switch (task.entityTypeKey) {
                case "custo":
                    this.$window.location.assign(`/customers/${task.entityId}#tags`);
                    break;
            }
        },
        initialize: function () {
            this.initAuthUserCollection()
                .then(this.initBulkCustomersCollection)
                .then(this.apiTicketAssignedToGet)
                .then(response => {
                    this.items.length = 0;
                    this.items.push(...response
                        .filter(x => !x.ended)
                        .map(el => ({ ...el, creator: this.formattedName(el.createdBy), target: this.formattedNameC(el.entityId) })));
                });
        },
        ...call('notifications/*'),
        ...call("authUsers/*"),
        ...call("customers/*"),
    },
    mounted() {
        this.initialize();
    },
}
</script>
<style>
.menu-notifications {
    min-width: 0px !important;
    top: 8vh !important;
    right: 5vw !important;
    transform-origin: right top !important;
    z-index: 10001 !important;
}

.custom-header {
    margin-right: 10px;
}

.shake-it {
    animation: shake 1.5s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    animation-iteration-count: infinite;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}

.min-w-20 {
    min-width: 20vw;
    max-height: 80vh;
    overflow-y: scroll;
}

</style>