<template>
    <div class="pill">
        <v-btn :color="btn.btnColor" elevation="1" v-bind:key="btn.icon" v-for="btn in collection" @click="btn.action">
            <!--v-icon :color="btn.color">{{ btn.icon }}</v-icon-->
          <template v-if="btn.icon.includes('mdi-')">
            <v-icon :color="btn.color">{{ btn.icon }}</v-icon>
          </template>
          <template v-else>
            <img :src="btn.icon" style="width: 24px; height: 24px;" />
          </template>
          <span v-if="!!btn.text">{{ btn.text }}</span>
        </v-btn>
    </div>
</template>

<script>
    export default {
        name : "MFButtonPill",
        props: {
            collection : {
                type: Array,
                required: true
            }
        },
        data: () => ({}),
        computed: {},
        methods: {}
    }
</script>

<style>
    .pill {
        display: flex;
        margin: 0px;
        padding: 0px;
    }
    .pill > button {
        min-width: 30px!important;
    }
    /* .pill :first-child {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
    }
    .pill :last-child {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    } */
</style>
