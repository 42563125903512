<template>
    <material-card :heading="title" color="primary">
        <v-card-text>
            <v-row v-if="!collapseDonut">
                <v-col cols="12" md="4" v-for="(el, i) in getSeries()" v-bind:key="1234*i" >
                    <chart ref="chart" :title-text="el.name" :legend-position="'top'" :tooltip-visible="true" :tooltip-template="template" :theme="'sass'">
                        <chart-series-item
                            :type="'donut'"
                            :data="el.collection"
                            :labels-visible="true"
                            :labels-template="template"
                            :labels-position="'outsideEnd'"
                            :labels-background="'transparent'"
                            :labels-align="labelAlign">
                        </chart-series-item>
                    </chart>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" md="6" v-for="(el, i) in getTiny()" v-bind:key="321*i">
                    <chart ref="chart"
                            :title-text="el.name"
                            :title-position="'top'"
                            :legend-visible=false
                            :chart-area-background="''"
                            :series-defaults-type="'donut'"
                            :series-defaults-start-angle="150"
                            :series="el.collection"
                            :tooltip="tooltip"
                            :theme="'sass'">
                    </chart>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-btn-toggle v-model="toggleButton" class="float-right" dense background-color="primary" multiple>
                        <v-btn @click="handleZoom()"><v-icon>mdi-magnify</v-icon></v-btn>
                        <v-btn v-if="!showDataCollapsed4Continent" @click="handleTable()"><v-icon>mdi-earth-minus</v-icon></v-btn>
                        <v-btn v-else @click="handleTable()"><v-icon>mdi-earth-plus</v-icon></v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" v-if="showDataCollapsed4Continent">
                    <v-simple-table class="mt-10">
                        <template v-slot:default>
                            <tbody>
                                <tr class="custom-header">
                                    <td></td>
                                    <td v-bind:key="head+x" class="custom-header" v-for="(head, x) in getHeaders()">{{ head }}</td>
                                </tr>
                                <tr v-for="row in getRows()" :key="row" :class="getFlag(row)">
                                    <td>{{ row }}</td>
                                    <td v-bind:key="x" style="text-align:right;" v-for="(value, x) in getValues(row)">
                                        <span v-if="!isOdd(x)">€{{ getFormattedPrice(value) }}</span>
                                        <span class="text-left" v-else :class="colorized(value)" v-html="getFormattedPercentage(value)"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" md="12" v-else>
                    <v-simple-table class="mt-10">
                        <template v-slot:default>
                            <tbody>
                                <tr class="custom-header">
                                    <td></td>
                                    <td v-bind:key="head+x" class="custom-header" v-for="(head, x) in getHeaders()">{{ head }}</td>
                                </tr>
                                <tr v-for="row in getAltRows()" :key="row" :class="getFlag(row)">
                                    <td>{{ row }}</td>
                                    <td v-bind:key="x" style="text-align:right;" v-for="(value, x) in getAltValues(row)">
                                        <span v-if="!isOdd(x)">€{{ getFormattedPrice(value) }}</span>
                                        <span class="text-left" v-else :class="colorized(value)" v-html="getFormattedPercentage(value)"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
    </material-card>
</template>

<script>
import { Chart, ChartSeriesItem } from '@progress/kendo-charts-vue-wrapper';

export default {
    name: 'App',
    components: {
        'chart': Chart,
        'chart-series-item': ChartSeriesItem
    },
    props: {
        title: { type: String, default: "" },
        series: { type: [Array, Object, Function], required: true },
    },
    data: () => ({
        labelAlign: "circle", //"column",
        template: "#= category # #= kendo.format('{0:P}', percentage) # €#= kendo.format('{0:N0}', value) #",
        showDataCollapsed4Continent: true,
        collapseDonut: false,
        toggleButton: [],
        tooltip: {
            visible: true,
            template: "#= category # (#= series.name #): #= kendo.format('{0:P}', percentage) #"
        }
    }),
    methods: {
        handleZoom: function() {
            this.collapseDonut = !this.collapseDonut;
        },
        handleTable: function() {
            this.showDataCollapsed4Continent = !this.showDataCollapsed4Continent;
        },
        getSeries: function() {
            let response = [];
            Object.keys(this.series).forEach((el) => {
                response.push({
                    name : el,
                    collection : this.series[el].reduce((a, b) => {
                        let temp = a.find(x => x.category == (b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)));
                        if (!!temp) {
                            temp.value = temp.value || 0;
                            temp.value += +(b.totalAmount.toFixed()) || 0;
                        } else {
                            a.push({
                                category : (b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)),
                                value : +(b.totalAmount.toFixed())
                            });
                        }
                        return a;
                    }, [])
                    .filter(el => !!el.category)
                    .sort((a, b) => a.category < b.category? -1 : 1)
                });
            });
            return response;
        },
        getTiny: function() {
            let response = [],
                currentYear = `${this.$moment().year()}`;

            Object.keys(this.series).forEach((el) => {
                if (el !== currentYear) {
                    let current = this.series[currentYear].reduce((a, b) => {
                        let temp = a.find(x => x.category == (b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)));
                        if (!!temp) {
                            temp.value = temp.value || 0;
                            temp.value += +b.totalAmount || 0;
                        } else a.push({ category :(b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)), value : b.totalAmount });
                        return a;
                    }, []);
                    let other = this.series[el];
                    other.length = this.series[currentYear].length;
                    other = other.reduce((a, b) => {
                        let temp = a.find(x => x.category == (b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)));
                        if (!!temp) {
                            temp.value = temp.value || 0;
                            temp.value += +b.totalAmount || 0;
                        } else a.push({ category : (b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)), value : b.totalAmount });
                        return a;
                    }, []);

                    response.push({
                        name : `${el} VS ${currentYear}`,
                        collection : [
                            {
                                name : el,
                                data : other.sort((a, b) => a.category < b.category? -1 : 1)
                            },
                            {
                                name : currentYear,
                                data : current.sort((a, b) => a.category < b.category? -1 : 1),
                                labels: { visible: true, background: "transparent", position: "outsideEnd", template: "#= category # - #= kendo.format('{0:P}', percentage) # - € #= kendo.format('{0:N}', value) #" }
                            },
                        ]
                    });
                }
            });

            return response;
        },
        getKey: function() {
            return Math.floor(Math.random() * 99999);
        },
        // TABELLA
        getHeaders: function() {
            let response = [];
            for(let i = 0; i < this.getYears().length; i++){
                response.push(this.getYears()[i]);
                if (i < this.getYears().length -1) response.push("");
            }
            return response;
        },
        getRows: function() {
            let result = this.series[`${this.$moment().year()}`]?.reduce((a, b) => {
                if (!a.find(x => x == (b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)))) a.push((b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)));
                return a;
            }, [])
            .filter(el => !!el)
            .sort((a, b) => a < b? -1 : 1)
          return result;
        },
        getAltRows: function() {
            return this.series[this.$moment().year()]?.reduce((a, b) => {
                if (!a.find(x => x == b.countryCode)) a.push(b.countryCode);
                return a;
            }, [])
            .filter(el => !!el)
            .sort((a, b) => a < b? -1 : 1)
        },
        getContinentName: function(code) {
            switch (code) {
                case "AS" : return "Asia";
                case "EU" : return "Europe";
                case "AF" : return "Africa";
                case "SA" : return "South America";
                case "NA" : return "North America";
                case "OC" : return "Oceania";
                default : return "";
            }
        },
        getValues: function(row) {
            let data = JSON.parse(JSON.stringify(this.series)),
                response = [];

            Object.keys(data).forEach(el => {
                data[el] = data[el].reduce((a, b) => {
                    if(!b || !b.countryCode || !b.continent) return a;
                    let temp = a.find(x => x.category == (b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)));
                    if (!!temp) {
                        temp.value = temp.value || 0;
                        temp.value += +b.totalAmount || 0;
                    } else if (this.getContinentName(b.continent) !== "-") {
                        a.push({ category : (b.countryCode == 'Italy'? b.countryCode : this.getContinentName(b.continent)), value : b.totalAmount });
                    }
                    return a;
                }, [])
            });

          const years = this.getYears();

          if (years.length > 1) {
            for (let i = years[years.length - 1]; i > years[0]; i--) {
              const anteYearArray = data[i-1];
              const postYearArray = data[i];
              const anteYearData = anteYearArray.find(el => el.category == row)?.value;
              const postYearData = postYearArray.find(el => el.category == row)?.value;

              response.unshift(postYearData || 0);
              if (0 < i) response.unshift(Math.round((anteYearData? (postYearData-anteYearData)/anteYearData : 0)*100)/100 || 0);
            }

            const firstYearData = data[this.getYears()[0]].find(el => el.category == row)?.value;
            response.unshift(firstYearData);
          }

          return response;
        },
        getAltValues: function(row) {
            let data = JSON.parse(JSON.stringify(this.series)),
                response = [];

            Object.keys(data).forEach(el => {
                data[el] = data[el].reduce((a, b) => {
                    let temp = a.find(x => x.category == b.countryCode);
                    if (!!temp) {
                        temp.value = temp.value || 0;
                        temp.value += +b.totalAmount || 0;
                    } else {
                        a.push({
                            category : b.countryCode,
                            value : b.totalAmount
                        });
                    }
                    return a;
                }, [])
            });

            console.log("getYears", this.getYears());

          const years = this.getYears();

          if (years.length > 1) {
              for (let i = years[years.length - 1]; i > years[0]; i--) {
                const anteYearArray = data[i-1];
                const postYearArray = data[i];
                const anteYearData = anteYearArray.find(el => el.category == row)?.value;
                const postYearData = postYearArray.find(el => el.category == row)?.value;

                  response.unshift(postYearData || 0);
                if (0 < i) response.unshift(Math.round((anteYearData? (postYearData-anteYearData)/anteYearData : 0)*100)/100 || 0);
              }

              const firstYearData = data[this.getYears()[0]].find(el => el.category == row)?.value;
              response.unshift(firstYearData);
            }


          return response;
        },
        getYears: function() {
            return Object.keys(this.series);
        },
        getFormattedPercentage: function(value) {
            return value < 0? `${Math.round(value*100)} % &#9660;` : `+${Math.round(value*100)} % &#9650;`;
        },
        colorized: function(value) {
            return +value >= 0? 'greenText' : "redText";
        },
        isOdd: function(value) {
            return value % 2 !== 0;
        },
        getFormattedPrice: function(value) {
            return `${this.$numerals(value, `0,0`)}`.replaceAll(`,`, `.`);
        },
        getFlag: function(value) {
            switch (value) {
                case 'Italy': return 'italy';
                case 'Brazil': return 'brazil';
                case 'United States': return 'usa';
                default: return ""
            }
        },
    }
}
</script>
<style>
    .custom-header {
        text-align: right;
        font-size: large;
        font-style: oblique;
        font-weight: bolder!important;
    }
    .italy {
        background: -webkit-linear-gradient(left, rgba(22,222,22,0.4) 0%, rgba(222,222,222,0) 50%, rgba(222,22,22,0.4) 100%);
        background-attachment:fixed;
    }
    .brazil {
        background: -webkit-linear-gradient(left, rgba(0,168,89,0.4) 0%, rgba(255,204,41,0.4) 40%, rgba(62,64,149,0.4) 50%, rgba(255,204,41,0.4) 60%, rgba(0,168,89,0.4) 100%);
        background-attachment:fixed;
    }
    .usa {
        background: -webkit-linear-gradient(left, rgba(0, 33, 71, 0.4) 0%, rgba(0, 33, 71, 0.4) 5%,  rgba(255, 255, 255, 0) 10%,  rgba(0, 33, 71, 0.4) 15%,  rgba(255, 255, 255, 0) 20%, rgba(0, 33, 71, 0.4) 25%,  rgba(255, 255, 255, 0) 30%,  rgba(0, 33, 71, 0.4) 35%,  rgba(255, 255, 255, 0) 40%,  rgba(0, 33, 71, 0.4) 45%, rgba(187, 19, 62, 0.4) 50%, rgba(187, 19, 62, 0.4) 100%);
        background-attachment:fixed;
    }
</style>
