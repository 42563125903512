<template>
  <div class="text-center" v-if="show">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :text="typeText" :color="colorBtn" dark v-bind="attrs" v-on="on">{{ textBtn }}</v-btn>
      </template>
      <v-card>
        <v-card-text>
          <div>
            <div class="ma-auto position-relative" style="max-width: 300px">
              <v-otp-input autofocus v-model="otp" @finish="onFinish"></v-otp-input>
              <div>{{ $t('expectedValue') }}: <span class="font-weight-bold">{{ expectedOtp }}</span></div>
              <div class="text--caption" v-t="'typeOrCopyPast'"></div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    name: "MFClientOTP",
    props: {
      callback: { required: true, type: Function },
      typeText: { required: true, type: Boolean },
      show: { required: true, type: Boolean },
      textBtn: { required: true, type: String },
      colorBtn: { required: true, type: String },
      param: { required: false, default: null }
    },
    data: () => ({
      otp: '',
      expectedOtp: '123456',
      dialog: false,
    }),
    methods: {
      initialize () {
        this.otp = "";
        this.expectedOtp = this.getRand();
      },
      getRand(){
        let result= '',
            dictionary= '0123456789', // ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz
            charactersLength= 10;
        for ( var i = 0; i < 6; i++ ) { result += dictionary.charAt(Math.floor(Math.random() * charactersLength))}
        return result;
      },  
      onFinish (rsp) {
        if(this.otp == this.expectedOtp) {
          !!this.param? this.callback(this.param) : this.callback();
          this.dialog = false;
        }
        this.otp = '';
        this.initialize()
      },
    },
    created() {
        this.initialize();
    },
  }
</script>
<style scoped>
 .position-relative {
   position: relative;
 }
</style>
