<template>  
    <v-row>
        <v-data-table :headers="headers" :items="localcollection" :items-per-page="5" class="elevation-1 splash">
            <template v-slot:item.updateTimestamp="{ item }">
                <span :class="{ 'line-through' : !!item.ended  }">{{ item.startAt ?? item.updatedTimestamp | formatDate }}</span>
            </template>
            <template v-slot:item.name="{ item }">
                <span :class="{ 'line-through' : !!item.ended  }">{{ item.name }}</span>
            </template>
            <template v-slot:item.due="{ item }">
                <span :class="{ 'line-through' : !!item.ended  }">{{ item.due | formatDate }}</span>
            </template>
            <template v-slot:item.ended="{ item }">  
                <v-switch v-model="item.ended" inset color="success" @change="handleEnd(item)"></v-switch>
            </template>
            <template v-slot:item.createdBy="{ item }">  
                <span>{{ getUsersName(item) }}</span>
            </template>          
            <template v-slot:item.actions="{ item }">
                <MFButtonPill :collection="getCollections(item)"></MFButtonPill>
            </template>
        </v-data-table>
        <v-dialog persistent v-model="myDialog" width="60vw">
            <v-card>
                <v-card-title class="headline grey lighten-2">TASK</v-card-title>
                <v-card-text>
                    <v-row>

                        <v-col cols="12" sm="8">
                            <v-row>
                                <v-col cols="12" md="12" sm="12" v-if="!!selected.createdBy">
                                    <v-autocomplete
                                        v-model="selected.createdBy"
                                        :items="dropdownUsers"
                                        :label="$t('author')"
                                        dense
                                        outlined
                                        disabled
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-3">
                                    <v-text-field
                                        v-model="selected.name"
                                        outlined
                                        clearable
                                        dense
                                        hide-details="auto"
                                        :label="$t('title')"
                                        autocomplete="nope"
                                        @emitUpdate="handleInput"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" sm="12">
                                    <v-autocomplete
                                        v-model="selected.assignedTo"
                                        :items="dropdownUsers"
                                        :label="$t('assignedTo')"
                                        autocomplete="nope"
                                        hide-details="auto"
                                        dense
                                        clearable
                                        outlined
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="12" sm="12">
                                    <v-textarea
                                        outlined
                                        counter
                                        rows="10"
                                        :label="$t('description')"
                                        v-model="selected.description"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" sm="4">
                            <v-row>
                                <v-col cols="12" sm="12" class="my-calendar mb-5" v-if="!selected.id">
                                    <v-date-picker v-model="dates" range></v-date-picker>
                                </v-col>
                                <v-col cols="12" sm="12" class="disabled">
                                    <v-text-field 
                                        outlined 
                                        v-model="dateRangeText" 
                                        :label="$t('date')" 
                                        append-icon="mdi-calendar" 
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" sm="12" class="disabled">
                                    <v-checkbox
                                        v-model="switchExpiration"
                                        :label="$t('hasExpiration')"
                                        @change="handleExpiration()"
                                        dense
                                        disabled
                                        outlined
                                    ></v-checkbox>
                                </v-col>

                                <v-col cols="12" sm="12" v-if="isAuthorized() && !!selected.id">
                                    <v-text-field 
                                        outlined 
                                        type="date"
                                        v-model="selected.startAt" 
                                        :label="$t('newParam', {param:$t('startDate')})"
                                    ></v-text-field>
                                    <v-text-field 
                                        outlined 
                                        type="date"
                                        v-model="selected.due" 
                                        :label="$t('newParam', {param:$t('due')})" 
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="!selected.id">
                                    <v-checkbox
                                        v-model="repeat"
                                        :label="$t('repeated')"
                                        @change="showRepeat = true"
                                        dense
                                        outlined
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="!!repeat">
                                    <v-text-field
                                        v-model="howManyMonths"
                                        outlined
                                        clearable
                                        dense
                                        type="number"
                                        hide-details="auto"
                                        :label="$t('howManyMonths')"
                                        autocomplete="nope"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <MFClientOTP :show="!!selected.id && inEdit" :typeText="true" :callback="remove" textBtn="Delete" colorBtn="red lighten-2"></MFClientOTP>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="myDialog = false" v-t="'close'"></v-btn>
                    <v-btn v-if="inEdit" :disabled="!switchExpiration" color="primary" text @click="save">{{ !!selected.id? $t('update') : $t('confirm') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn color="primary" absolute top right fab @click="addTicket"><v-icon>mdi-plus</v-icon></v-btn>
        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" v-if="showAll" class="mx-15 bg-white" outlined color="success" absolute top right fab @click="showAll = false"><v-icon>mdi-eye-off</v-icon></v-btn>
            <v-btn v-bind="attrs" v-on="on" v-else class="mx-15 bg-white" outlined color="success" absolute top right fab @click="showAll = true"><v-icon>mdi-eye-check</v-icon></v-btn>
        </template>
        <span>{{ `${!showAll? $t('show') : $t('hide')} ${ $t('completedTasks') }` }}</span>
        </v-tooltip>
    </v-row>
</template>

<script>
    import TextField from '@/components/utility/TextField';
    import TextAreaFieldAutocomplete from '@/components/utility/TextAreaFieldAutocomplete';
    import MFClientOTP from '@/components/utility/MFClientOTP';
    import {call, get, sync} from "vuex-pathify";

    export default {
        name: "MFChats",
        components: {
            TextField,
            TextAreaFieldAutocomplete,
            MFClientOTP,
        },
        props: {
            customerId: { type: Number },
            loading: { type: Boolean, default: false },
        },
        data: () => ({
            showAll: false,
            myDialog: false,
            repeat: false,
            showRepeat: false,
            howManyMonths: 6,
            selected: { "name": "", "description": "", "due": null, "entityId": 0, "entityTypeKey": "custo"},
            inEdit: false,
            dateDue: null,
            // switchExpiration: false,
            authUserDialog: false,
            tempMessage: { title: "", body: "" },
            collection: [],
            messages: [],
            dates: [],
        }),
        created() {
            this.initialize();
        },
        computed: {
            headers(){
                return [
                    { text: this.$t('users'), value: 'createdBy' }, 
                    { text: this.$t('data'), value: 'updateTimestamp' }, 
                    { text: this.$t('title'), value: 'name' },  
                    { text: this.$t('due'), value: 'due' }, 
                    { text: this.$t('completed'), value: 'ended' },
                    { text: this.$t('actions'), value: 'actions', sortable: false, align: "center" },
                ]
            },
            localcollection () {
                return !!this.showAll? this.collection : this.collection.filter(x => !x.ended);
            },
            agentsDropdown: get("agents/dropdownActiveAgents"),
            formattedName: get("authUsers/formattedName"),
            dropdownUsers: get("authUsers/dropdownUsers"),
            dateRangeText () {
                return this.dates.map(el => this.$moment(el).format('DDMMYYYY')).join('..');
            },
            switchExpiration () {
                return this.dates.length > 1;
            },
            userProfile: get("auth/userProfile"),
            isInRole: get("auth/isInRole"),
            // messages: get("notifications/messages"),
        },
        methods: {
            getUsersName: function(item) {
                return `${this.formattedName(item.createdBy)} > ${this.formattedName(item.assignedTo)}`;
            },
            save: function(item = null) {

                let obj = {
                    // id
                    name: this.selected.name,
                    description: this.selected.description,
                    due: this.dates.length > 1? new Date(this.dates[1]) : null,
                    ended: null,
                    createdBy: "",
                    createdAt: new Date(),
                    assignedTo: this.selected.assignedTo,
                    tenantUuid: localStorage.getItem('tenantUuid'),
                    startAt: this.dates.length > 0? new Date(this.dates[0]) : new Date(),
                    updateTimestamp: null,
                    updateUser: null,
                    entityId: this.customerId, 
                    entityTypeKey: "custo"
                }

                let el = item.name? item : this.selected;
                let promise;

                // el.due = !this.switchExpiration? 
                //     null : this.$moment(el.due).isBefore(this.$moment().subtract(1, 'days'))? 
                //         this.$moment().add(1, 'days') : this.$moment(el.due);

                if (!el.id) {
                    if(this.repeat && this.showRepeat && this.howManyMonths) {
                        let clone = JSON.parse(JSON.stringify(el));
                        clone.startAt = this.$moment(el.startAt).add(this.howManyMonths, 'month');
                        clone.due = this.$moment(el.startAt).add(this.howManyMonths, 'month').add(7, 'day');
                        promise = Promise.all([
                            this.apiTicketCustomerCustomerIdPost(obj),
                            this.apiTicketCustomerCustomerIdPost(clone)
                        ])
                    } else {
                        promise = this.apiTicketCustomerCustomerIdPost(obj);
                    }
                } else {
                    el.entityId = this.customerId;
                    promise = this.apiTicketIdCustomerCustomerIdPut(el);
                }


                promise
                .then(response => { this.myDialog = false; })
                .catch(error => { console.error(error) })
                .finally(() => { this.initialize(); })
            },
            remove: function() {
                this.apiTicketIdDelete(this.selected.id)
                .then(response => {
                    this.myDialog = false;
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.initialize();
                })
            },
            getCollections: function (record) {
                let tempResponse = [
                    // { icon: "mdi-eye", color: "primary", action: () => this.openMessage(record, false) },
                    { icon: "mdi-pencil", color: "primary", action: () => this.openMessage(record, true) },
                ];
                return tempResponse;
            },
            openMessage: function(record, inEdit) {
                this.selected = record;
                // this.switchExpiration = !!this.selected.due;
                this.inEdit = inEdit;
                this.myDialog = true;

                if(this.selected.id) {
                    this.dates.length = 0;
                    this.dates.push(this.selected.startAt ?? this.selected.updateTimestamp);
                    if(this.selected.due) this.dates.push(this.selected.due);
                }
            },
            addTicket: function() {
                this.dates.length = 0;
                this.selected = { 
                    "name": "", 
                    "description": "", 
                    "entityId": this.customerId, 
                    "entityTypeKey": "custo",
                    "due": null
                };
                // this.switchExpiration = false;
                this.inEdit = true;
                this.myDialog = true;
            },
            handleInput: function(obj) {
                switch (obj.field) {
                    case "description":
                        this.selected["description"] = obj.value.content;
                        if(!!obj.value.tagCollection?.length) {
                        }
                        if(!!obj.value.refCollection?.length) {
                        }
                        break;
                    default: 
                        this.selected[obj.field] = obj.value;
                        break;
                }            
            },
            handleEnd: function(item) {
                item.ended = !!item.ended? new Date() : null;  
                this.save(item);
            },
            handleExpiration: function() {
                this.selected.due = !!this.selected.due? new Date() : null; 
            },
            initialize: function() {
                this.initAuthUserCollection();
                this.apiTicketCustomerCustomerIdGet(+this.customerId)
                // this.apiCustomersIdTicketsGet(+this.customerId)
                .then(response => {
                    this.collection.length = 0;
                    this.collection.push(...(response || []).sort((a, b) => b.id - a.id));
                })
                .catch((err) => {
                    console.error(err);
                });
                // this.apiCustomersIdMessagesGet(+this.customerId);
            },
        sendMessage: function() {},
        isAuthorized: function() {
            return this.userProfile.id === this.selected.createdBy || this.isInRole('TicketInspector')
        },
        ...call("notifications/*"),
        ...call("authUsers/*"),
        ...call("auth/*")
    }
}
</script>

<style>
    .splash { width: 100%; }
    .display-block { display: block; }
    .line-through { text-decoration: line-through; }
    .my-calendar {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .disabled {
        background-color: #eeeeee;
    }
</style>
