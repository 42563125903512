<template>
  <v-card>
    <v-form>
      <v-card-text class="mb-3">
        <v-row>
          <v-col cols="12" sm="2">
            <div style="display: flex; flex-direction: row">
              <v-text-field
                  v-model="filters.code"
                  outlined
                  clearable
                  dense
                  hide-details="auto"
                  :label="$t('code')"
                  autocomplete="nope"
                  @click:clear="clearedCode"
                  @keyup.enter="doApplyFilters">
              </v-text-field>
              <v-menu
                  v-model="codeMenu"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      v-bind="attrs"
                      dark
                      v-on="on"
                      dense
                      style="min-width: 25px !important; width: 25px !important;"
                      class="px-0"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-card style="height: 60px !important; overflow: hidden; padding: 18px;">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                          :label="$t('from')"
                          dense
                          v-model="codeFrom"
                          placeholer="1"
                          autocomplete="nope"
                          @change="codePopoverChanged"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                          :label="$t('to')"
                          dense
                          v-model="codeTo"
                          placeholer="1"
                          autocomplete="nope"
                          @change="codePopoverChanged"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12" sm="2">
            <DateFilter v-model="filters.orderDate" :onEnter="doApplyFilters" :onClear="clearedOrderDate" :label="$t('orderDate')" @change="doApplyFilters"></DateFilter>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
                v-model="filters.orderStatusId"
                :items="orderStatus"
                :label="$t('orderStatus')"
                multiple
                chips
                hide-details="auto"
                dense
                autocomplete="nope"
                clearable
                outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
                v-model="filters.customerPurchaseOrder"
                outlined
                clearable
                dense
                hide-details="auto"
                autocomplete="nope"
                :label="$t('po')"
                @keyup.enter="doApplyFilters">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
                v-model="filters.orderTypeId"
                :items="orderTypes"
                :label="$t('orderType')"
                multiple
                chips
                hide-details="auto"
                dense
                autocomplete="nope"
                clearable
                outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
                v-model="filters.familyId"
                :items="families"
                :label="$t('family')"
                multiple
                chips
                hide-details="auto"
                dense
                autocomplete="nope"
                clearable
                outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <div style="display: flex; flex-direction: row;">
              <v-autocomplete
                  v-model="filters.customerId"
                  :items="customers"
                  :label="$t('customer')"
                  multiple
                  chips
                  deletable-chips
                  hide-details="auto"
                  dense
                  clearable
                  autocomplete="nope"
                  outlined
                  v-if="customerSearchType === 'list'"
              ></v-autocomplete>
              <v-text-field
                  v-model="filters.customerSearchString"
                  outlined
                  clearable
                  dense
                  autocomplete="nope"
                  hide-details="auto"
                  :label="$t('customer')"
                  @keyup.enter="doApplyFilters"
                  v-if="customerSearchType === 'string'">
              </v-text-field>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      class="pa-0"
                      style="min-width: initial;"
                      v-bind="attrs"
                      v-on="on"
                      @click="switchCustomerSearchType">
                    <v-icon x-small v-if="customerSearchType === 'list'">mdi-format-list-checks</v-icon>
                    <v-icon x-small v-if="customerSearchType === 'string'">mdi-format-letter-starts-with</v-icon>
                  </v-btn>
                </template>
                <span v-if="customerSearchType === 'list'">{{ $t('switchToParam', {param:$t('stringSearch')}) }}</span>
                <span v-if="customerSearchType === 'string'">{{ $t('switchToParam', {param:$t('listSearch')}) }}</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols="12" sm="2" v-if="showSubAgent">
            <v-autocomplete
                v-model="filters.subagentId"
                :items="agents"
                :label="$t('subAgent')"
                multiple
                chips
                deletable-chips
                autocomplete="nope"
                hide-details="auto"
                dense
                clearable
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.agentId"
                :items="agents"
                :label="$t('agent')"
                multiple
                chips
                deletable-chips
                autocomplete="nope"
                hide-details="auto"
                dense
                clearable
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.supervisorAgentId"
                :items="agents"
                :label="$t('supervisorAgent')"
                multiple
                chips
                deletable-chips
                hide-details="auto"
                dense
                clearable
                autocomplete="nope"
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.chiefAgentId"
                :items="agents"
                :label="$t('chiefAgent')"
                multiple
                chips
                deletable-chips
                hide-details="auto"
                dense
                clearable
                autocomplete="nope"
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.commercialSpecialistId"
                :items="commercialSpecialists"
                :label="$t('commercialSpecialist')"
                multiple
                chips
                deletable-chips
                hide-details="auto"
                dense
                autocomplete="nope"
                clearable
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="8" style="display: flex; padding: 0;">
            <GeonameSelector
                :country="filters.countryIsoCode"
                :region="filters.regionIsoCode"
                :province="filters.provinceIsoCode"
                :city="filters.city"
                @update:country="filters.countryIsoCode = $event"
                @update:region="filters.regionIsoCode = $event"
                @update:province="filters.provinceIsoCode = $event"
                @update:city="filters.city = $event"
                ref="geonameSelector"
            ></GeonameSelector>
          </v-col>
          <!-- <v-col cols="12" sm="2" class="align-center">
            <v-select
                v-model="filters.customerBusinessTypeId"
                :items="businessTypes"
                :label="$t('businessType')"
                multiple
                chips
                hide-details="auto"
                dense
                autocomplete="nope"
                clearable
                outlined
            ></v-select>
          </v-col> -->
          <v-col cols="12" sm="2">
            <div style="display: flex; flex-direction: row;">
              <v-text-field
                  v-model="filters.estimatedDeliveryDate"
                  outlined
                  clearable
                  dense
                  autocomplete="nope"
                  hide-details="auto"
                  @keyup.enter="doApplyFilters"
                  @click:clear="clearedShipDate"
                  :label="$t('shipmentDate')">
              </v-text-field>
              <v-menu
                  v-model="shipmentDateMenu"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      v-bind="attrs"
                      dark
                      v-on="on"
                      dense
                      style="min-width: 25px !important; width: 25px !important;"
                      class="px-0"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-card style="height: 60px !important; overflow: hidden; padding: 18px;">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <DatePickerField
                          :isEdit="true"
                          :isDisabled="false"
                          :value="shipDateFrom"
                          :field="'shipDateFrom'"
                          :loading="false"
                          @emitUpdate="shipDatePopoverChanged"
                      ></DatePickerField>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <DatePickerField
                          :isEdit="true"
                          :isDisabled="shipDateToDisabled"
                          :value="shipDateTo"
                          :field="'shipDateTo'"
                          :loading="false"
                          @emitUpdate="shipDatePopoverChanged"
                      ></DatePickerField>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.customerStatisticalTagId"
                :items="dropdownGroupIdentifiers"
                :label="$t('groupIdentifier')"
                multiple
                chips
                deletable-chips
                hide-details="auto"
                dense
                clearable
                autocomplete="nope"
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filters.parentCode"
              :items="parents"
              :label="$t('parent')"
              deletable-chips
              hide-details="auto"
              dense
              clearable
              autocomplete="nope"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
                v-model="filters.alsoHistorical"
                :items="twoWays"
                :label="$t('historicalOrder')"
                hide-details="auto"
                dense
                autocomplete="nope"
                clearable
                outlined
            ></v-select>
          </v-col>
          <!-- <v-col cols="12" sm="2">
            <v-select
                v-model="filters.hasEngraver"
                :items="threeWays"
                label="Has Engraver"
                hide-details="auto"
                dense
                autocomplete="nope"
                clearable
                outlined
            ></v-select>
          </v-col> -->
          <v-col cols="12" sm="1" offset="10">
            <v-btn class="float-right" @click="clearFilters" v-t="'clear'"></v-btn>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn block @click="doApplyFilters" v-t="'apply'"></v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {call, get, sync} from "vuex-pathify";
import DatePickerField from "../utility/DatePickerField";
import GeonameSelector from "./GeonameSelector";
import DateFilter from "./DateFilter.vue";

export default {
  name: "OrderFilters",
  components: {
    DatePickerField,
    GeonameSelector,
    DateFilter
  },
  data: () => ({
    selectedCountry: null,
    selectedRegion: null,
    selectedProvince: null,
    customerSearchType: 'list',
    countries: [],
    country_regions: [],
    region_provinces: [],
    codeTo: '',
    codeFrom: '',
    orderDateFrom: null,
    orderDateTo: null,
    orderDateToDisabled: true,
    orderDateMenu: null,
    codeMenu: null,
    shipmentDateMenu: null,
    shipDateFrom: null,
    shipDateTo: null,
    shipDateToDisabled: true,
  }),
  computed: {
    twoWays(){
      return [
        {text: this.$t('yes'), value: true },
        {text: this.$t('no'), value: null }
      ]
    },
    threeWays(){
      return [
        {text: this.$t('unset'), value: null},
        {text: this.$t('yes'), value: true},
        {text: this.$t('no'), value: false}
      ]
    },
    dropdownActive(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('active'), value: true},
        {text: this.$t('inactive'), value: false}
      ]
    },
    dropdownStatus(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('incomplete'), value: '"INCOMPLETO"'},
        {text: this.$t('canceled'), value: '"ANNULLATO"'},
        {text: this.$t('complete'), value: '"COMPLETO"'},
        {text: this.$t('processed'), value: '"PROCESSATO"'}
      ]
    },
    families: get('families/dropdownFamilies'),
    customers: get('customers/dropdownAllBulkCustomers'),
    parents: get('customers/dropdownParents'),
    agents: get('agents/dropdownActiveAgents'),
    orderTypes: get('orders/dropdownOrderTypes'),
    commercialSpecialists: get('commercialSpecialists/dropdownCommercialSpecialists'),
    orderStatus: get('orders/dropdownOrderStatus'),
    zones: get('geoname/dropdownZones'),
    filters: sync('orders/filters'),
    businessTypes: get('customers/dropdownBusinessTypes'),
    dropdownGroupIdentifiers: get('customers/dropdownGroupIdentifiers'),
    showSubAgent: get('tenantConfiguration/showSubAgent')
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.initFamilyCollection();
      this.initBulkCustomersCollection();
      this.initAgentCollection();
      this.orderTypesGet();
      this.initCommercialSpecialistCollection();
      this.initZoneCollection();
      this.initBusinessTypeCollection();
      this.apiCustomerStatisticalTagGet();
      this.clearedOrderDate();
    },
    doApplyFilters() {
      // this.applyFilters(this.filters);
      this.$emit('applyFilterEvent');
    },
    clearFilters() {
      this.clearedCode();
      this.clearedShipDate();
      this.filters = {
        code: null,
        orderDate: null,
        orderTypeId: [],
        customerId: [],
        customerSearchString: null,
        customerLocationId: [],
        commercialSpecialistId: [],
        agentId: [],
        supervisorAgentId: [],
        chiefAgentId: [],
        customerPurchaseOrder: null,
        offerExpirationDate: null,
        estimatedDeliveryDate: null,
        paymentTermId: [],
        documentCurSubtotal: null,
        collectionCharges: null,
        deliveryCharges: null,
        documentCurRowsTotal: null,
        taxable: null,
        vatPercentageId: [],
        taxes: null,
        cashOnDeliveryDiscount: null,
        orderDiscountPercentage: null,
        documentCurOrderDiscountAbsolute: null,
        total: null,
        contextualDeposit: null,
        orderStatusId: null,
        notes: null,
        privateNotes: null,
        familyId: [],
        priceCatalogId: [],
        customerStatisticalTagId: []
      };
      this.$refs.geonameSelector.clear();
      this.clearedOrderDate();
      this.$emit('clearFilterEvent');
    },
    clearedCode() {
      this.codeFrom = '';
      this.codeTo = '';
    },
    codePopoverChanged() {
      this.filters.code = this.codeFrom;
      if (this.codeTo !== '') {
        this.filters.code = `${this.filters.code}..${this.codeTo}`;
      }
    },
    clearedOrderDate() {
      let startDate = this.$moment().startOf('year'),
          todayDate = this.$moment();
      this.filters.orderDate = `${startDate.format("DDMMYYYY")}..${todayDate.format("DDMMYYYY")}`;
      this.orderDateFrom = new Date(startDate.format("YYYY-MM-DD"));
      this.orderDateTo = new Date(todayDate.format("YYYY-MM-DD"));
      this.orderDateToDisabled = false;
    },
    orderDatePopoverChanged(e) {
      this[e.field] = new Date(e.value);
      if (this.orderDateFrom === null) {
        this.orderDateToDisabled = true;
      } else {
        this.orderDateToDisabled = false;
        let orderDateFromDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.orderDateFrom);
        let orderDateFromMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.orderDateFrom);
        let orderDateFromYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.orderDateFrom);
        this.filters.orderDate = `${orderDateFromDay}${orderDateFromMonth}${orderDateFromYear}`;
        if (this.orderDateTo !== null) {
          let orderDateToDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.orderDateTo);
          let orderDateToMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.orderDateTo);
          let orderDateToYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.orderDateTo);
          this.filters.orderDate = `${this.filters.orderDate}..${orderDateToDay}${orderDateToMonth}${orderDateToYear}`;
        }
      }
    },
    clearedShipDate() {
      this.shipDateFrom = null;
      this.shipDateTo = null;
      this.shipDateToDisabled = true;
    },
    shipDatePopoverChanged(e) {
      this[e.field] = new Date(e.value);
      if (this.shipDateFrom === null) {
        this.shipDateToDisabled = true;
      } else {
        this.shipDateToDisabled = false;
        let shipDateFromDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.shipDateFrom);
        let shipDateFromMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.shipDateFrom);
        let shipDateFromYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.shipDateFrom);
        this.filters.estimatedDeliveryDate = `${shipDateFromDay}${shipDateFromMonth}${shipDateFromYear}`;
        if (this.shipDateTo !== null) {
          let shipDateToDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.shipDateTo);
          let shipDateToMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.shipDateTo);
          let shipDateToYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.shipDateTo);
          this.filters.estimatedDeliveryDate = `${this.filters.estimatedDeliveryDate}..${shipDateToDay}${shipDateToMonth}${shipDateToYear}`;
        }
      }
    },
    mandatoryShippingChanged(e) {
      this.filters[e.field] = e.value;
    },
    changedGeonameSelector(e) {
    },
    switchCustomerSearchType() {
      this.customerSearchType = this.customerSearchType === 'list' ? 'string' : 'list';
      if (this.customerSearchType === 'string') {
        this.filters.customerId = [];
      } else if (this.customerSearchType === 'list') {
        this.filters.customerSearchString = null;
      }
    },
    // applyFilters: call('orders/applyFilters'),
    ...call('families', ['initFamilyCollection']),
    ...call('customers', ['initBulkCustomersCollection', 'apiCustomerStatisticalTagGet']),
    ...call('agents', ['initAgentCollection']),
    ...call('orders', ['orderTypesGet']),
    ...call('commercialSpecialists', ['initCommercialSpecialistCollection']),
    ...call('geoname', ['initZoneCollection']),
    ...call('customers', ['initBusinessTypeCollection'])
  }
}
</script>
