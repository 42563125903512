<template>
    <v-data-table :headers="headers" :items="collection" :single-expand="true" :item-key="'zoneCode'" :expanded.sync="expanded" show-expand class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title v-t="'unifyScale'"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-switch
                    v-model="unifyScale.value"
                    :label="$nextTick('unified')"
                    class="mt-2"
                ></v-switch>
            </v-toolbar>
        </template>
        <template v-slot:item.chart="{ item }">
            <MFBullet :valueAxisMax="valueAxisMax" :target="item.targetAmount" :actual="item.salesAmount" :height="65"></MFBullet>
        </template>
        <template v-slot:item.machine="{ item }">
            <MFMachineTrafficLight 
                :key="item.totalAmount"
                :actualLoan="item.salesQty"
                :targetLoan="item.targetQty"
                :actualBuy="item.salesQty"
                :targetBuy="item.targetQty"
            ></MFMachineTrafficLight>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="my-5 py-5">
                {{ $t('totalAmount') }} {{ item.totalAmount }}<br />
                {{ $t('totalQuantity') }} {{ item.totalQuantity }}
            </td>
        </template>
    </v-data-table>
</template>

<script>
    import MFBullet from './MFBullet.vue';
    import MFMachineTrafficLight from './MFMachineTrafficLight.vue';
    export default {
        name : "GoalExpandableTable",
        components: {
            MFBullet,
            MFMachineTrafficLight
        },
        props: {
            collection: { type: Array, required: false, default: () => ([]) }
        },
        computed: {
            headers(){
                return [
                    { text: this.$t('zone'), align: 'left', sortable: true, value: 'zoneName', },
                    { text: this.$t('sales'), align: 'center', value: 'chart', sortable: false },
                    { text: this.$t('machine'), align: 'center', value: 'machine', sortable: false },
                    { text: this.$t('info'), value: 'data-table-expand' },
                ]
            }
        },
        data: function() {
            return {
                expanded: [],
                singleExpand: false,
                valueAxisMax: null,
                unifyScale: new Proxy({ value : false }, {
                    set : (t, p, v) => {
                        t[p] = v;
                        if (!!v) {
                            let targetAxisMax = this.collection.reduce((a, b) => { a = b.targetAmount > a ? b.targetAmount : a; return a; }, null),
                                salesAxisMax = this.collection.reduce((a, b) => { a = b.salesAmount > a ? b.salesAmount : a; return a; }, null);
                            this.valueAxisMax = (targetAxisMax < salesAxisMax? salesAxisMax : targetAxisMax);
                            this.valueAxisMax += this.valueAxisMax * 0.03;  
                        } else {
                            this.valueAxisMax = null;
                        }
                        return true;
                    }
                }),
            }
        },
    }
</script>