<template>
    <div id="notification-wrapper">
        <v-menu top offset-y >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="custom-header" v-bind="attrs" v-on="on" elevation="0" fab @click="handleClick()">
                    <v-badge :content="items.length" inline>
                        <v-icon v-if="!hasNotifications" >mdi-bell-outline</v-icon>
                        <v-icon v-if="!!hasNotifications" color="red" class="shake-it">mdi-bell-ring-outline</v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <MFNotificationsInnerVue></MFNotificationsInnerVue>
        </v-menu>
        <v-dialog v-model="showRelease" width="800" persistent>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2" v-t="'releaseNotes'"></v-card-title>
                <v-card-text>
                    <v-expansion-panels multiple v-model="panel">
                        <v-expansion-panel v-for="(item,i) in releaseNotes" :key="i">
                            <v-expansion-panel-header>{{ item.code }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p v-for="(row,i) in item.description" :key="i">
                                    {{ row }}
                                </p>                                
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dismissRelease" v-t="'close'"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {call, sync, get} from 'vuex-pathify';
import MFNotificationsInnerVue from './MFNotificationsInner.vue';
export default {
    name: "MFNotifications",
    data: () => ({
        items: [],
    }),
    components: { MFNotificationsInnerVue },
    computed: {
        hasNotifications: function() {
            return !!this.items && !!this.items?.find(x => this.$moment(x.startDate).isBefore(this.$moment()) && this.$moment(x.due).isAfter(this.$moment()));
        },
        releaseNotes: get("notifications/releaseNotes"),
        showRelease: get("notifications/showRelease"),
        panel(){
            return [0];
        }
    },
    methods: {
        handleClick: function() { this.initialize(); },
        initialize: function() {
            this.apiReleaseNoteGet();
            this.initAuthUserCollection()
            .then(this.initBulkCustomersCollection)
            .then(this.apiTicketAssignedToGet)
            .then(response => {
                this.items.length = 0;
                this.items.push(...response
                    .filter(x => !x.ended));
            });
        },
        ...call('notifications/*'),
        ...call("authUsers/*"),
        ...call("customers/*"),
    },
    mounted() {
        this.initialize();
    },
}
</script>
<style>
    .menu-notifications {
        min-width: 0px!important;
        top: 8vh!important;
        right: 5vw!important;
        transform-origin: right top!important;
        z-index: 10001!important;
    }
    .custom-header {
        margin-right: 10px;
    }
    .shake-it {
        animation: shake 1.5s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        animation-iteration-count: infinite;
    }

    @keyframes shake {
        10%, 90% { transform: translate3d(-1px, 0, 0); }
        20%, 80% { transform: translate3d(1px, 0, 0); }
        30%, 50%, 70% { transform: translate3d(-2px, 0, 0); }
        40%, 60% { transform: translate3d(2px, 0, 0); }
    }

    .min-w-20 {
        min-width: 20vw;
    }
</style>