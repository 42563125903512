<template>
    <material-card :heading="title" color="primary">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="12">
                    <chart ref="chart"
                        :title-text="title"
                        :legend-position="'bottom'"
                        :series-defaults-type="'area'"
                        :series-defaults-stack-type="'100%'"
                        :value-axis-line-visible="false"
                        :category-axis-categories="categories"
                        :category-axis-major-grid-lines-visible="false"
                        :tooltip-visible="true"
                        :tooltip-template-format="'{0}%'"
                        :tooltip-template="tooltipTemplate"
                        :theme="'sass'">
                        <chart-series-item v-bind:key="x" v-for="(collection, x) in getCollections()" :name="collection.name" :data="collection.data"></chart-series-item>
                    </chart>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center"></th>
                                <th style="text-align:right;" v-bind:key="i" v-for="(head, i) in getHeaders()">{{ head }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in getRows()" :key="i">
                                <td class="text-center">{{ row }}</td>
                                <td style="text-align:right;" v-bind:key="x"  v-for="(value, x) in getValues(row)">
                                    <span v-if="!isOdd(x)">€{{ getFormattedPrice(value) }}</span>
                                    <span class="text-left" v-else-if="!!value" :class="colorized(value)" v-html="getFormattedPercentage(value)"></span>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
    </material-card>
</template>

<script>
import { Chart, ChartSeriesItem } from '@progress/kendo-charts-vue-wrapper';

export default {
    name: 'App',
    components: {
        'chart': Chart,
        'chart-series-item': ChartSeriesItem
    },
    props: {
        title: { type: String, default: "" },
        series: { type: [Object, Array, Function], required: true },
    },
    data: () => ({
        tooltipTemplate: "#= series.name #: #= kendo.format('{0:N}', value) #",
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        quarters: [ { label : 'I', min: 0, max : 2 }, { label : 'II', min: 3, max : 5 }, { label : 'III', min: 6, max : 8 }, { label : 'IV', min: 9, max : 11 }],
    }),
    methods: {
        getCollections: function() {
            return Object.keys(this.collections).map(region => ({
                name : region,
                data : this.collections[region].map(x => x.totalAmount)
            })).filter(el => !!el.name);
        },
        getHeaders: function() {
            let response = [];
            for(let i = 0; i < this.getYears().length; i++){
                response.push(this.getYears()[i]);
                if (i < this.getYears().length -1) response.push("");
            }
            return response;
        },
        getYears: function() {
            return Object.keys(this.series);
        },
        getRows: function() {
            return (this.series[this.$moment().year()] || []).reduce((a, b) => { if (!a.includes(b.regionCode)) a.push(b.regionCode); return a; }, []).filter(el => !!el);
        },
        getValues: function(regionCode) {
            let response = [],
                data = this.collections[regionCode].reduce((a, b) => {
                    a[b.year] = a[b.year] || 0;
                    a[b.year] += b.totalAmount;
                    return a;
                }, {});
            Object.keys(data).forEach(el => {
                if ((this.$moment().year() - 2) < el) response.push(Math.round((data[el]? (data[el]-data[el-1])/data[el-1] : 0)*100)/100 || 0);
                response.push(data[el] || 0);
            });
            return response;
        },
        getFormattedPercentage: function(value) {
            return value < 0? `${Math.round(value*100)} % &#9660;` : `+${Math.round(value*100)} % &#9650;`;
        },
        isOdd: function(value) {
            return value % 2 !== 0;
        },
        getFormattedPrice: function(value) {
            return `${this.$numerals(value, `0,0`)}`.replaceAll(`,`, `.`);
        },
        colorized: function(value) {
            return +value >= 0? 'greenText' : "redText";
        },
    },
    computed: {
        collections: function() {
            let result = {},
                regions = this.series[this.$moment().year()]?.reduce((a, b) => { if (!a.includes(b.regionCode)) a.push(b.regionCode); return a; }, []);
            if (!!regions && !!regions.length) regions?.forEach(reg => { 
                result[reg] = Object.keys(this.series)
                    .map(year => this.series[year]
                        .filter(obj => obj.regionCode == reg)
                        .sort((a, b) => a.month - b.month)   
                    ).flat()});
            return result;
        },
        categories: function() {
            return Object.keys(this.series).map(el => this.months.map((x,i) => `${i+1}/${el.substr(2, 2)}`)).flat();
            // return Object.keys(this.series)
            //     .map(year => this.quarters.map(x => `${x.label} ${year}`))
            //     .flat();
        }
    }
}
</script>

<style>
    .greenText {
        color: green;
    }
    .redText {
        color: red;
    }
</style>
