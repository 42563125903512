<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      {{ isClone? $t('clone') : $t('update') }} {{ $t('order') }}
    </v-card-title>
    <v-card-text v-if="clone.id">
      <v-row class="mt-5">
        <!-- AGENT -->
        <v-col cols="12" md="8">
          <v-autocomplete clearable outlined :label="$t('subAgent')" v-model="clone.subagentId" :items="agentsDropdown"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="8">
          <v-autocomplete clearable outlined :label="$t('agent')" v-model="clone.agentId" :items="agentsDropdown" @change="changedAgent"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" v-if="!!clone.priceCatalogId && !!priceCatalogDropdown.length">
          <!-- <h3 class="float-right">{{ getListName(clone.priceCatalogId) || "" }}</h3> -->
          <v-autocomplete outlined :label="$t('priceCatalog')" v-model="clone.priceCatalogId" :items="priceCatalogDropdown"></v-autocomplete>
        </v-col>
        <!-- CUSTOMER & LOCATION -->
        <v-col cols="12" md="6">
          <v-autocomplete clearable outlined :label="$t('customer')" v-model="clone.customerId" :items="customersDropdown" @change="changedCustomer"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="5">
          <v-autocomplete clearable outlined :label="$t('location')" v-model="clone.customerLocationId" :items="customerLocationsDropdown"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="1">
          <v-dialog v-model="dialog" width="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab color="primary" outlined dark v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">{{ $t('addParam', {param:$t('location')}) }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" class="mb-3"></v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label>{{ $t('country', {r:''}) }}</label>
                      <div class="inf-font">
                        <AutoComplete :value="selectedLocation.geonameCountryCode"
                          :items="dropdownCountries" :field="'geonameCountryCode'"
                          @emitUpdate="handleLocation" :isEdit="true"></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="10" md="3" v-if="mappedPostalCodes.length > 0">
                    <div>
                      <label>{{ $t('postalCode') }}: {{ mappedPostalCodes.length }}</label>
                      <div class="inf-font">
                        <AutoComplete @emitUpdate="handleLocation" class="address-text" :isEdit="true"
                          clearable :disabled="!selectedLocation.geonameCountryCode"
                          :value="selectedLocation.postalCode" :loading="isLoadingPostalCodes"
                          autocomplete="nope" :field="'postalCode'" :items="mappedPostalCodes"
                          :placeholder="$t('postalCode')"></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2" md="1" v-if="mappedPostalCodes.length > 0">
                    <v-icon @click="clearPostalCodes()">mdi-close</v-icon>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" v-else>
                    <div>
                      <label v-t="'postalCode'"></label>
                      <div class="inf-font">
                        <TextField @emitUpdate="handleLocation" class="address-text" clearable
                          :isEdit="true" :disabled="!selectedLocation.geonameCountryCode"
                          :value="selectedLocation.postalCode" autocomplete="nope" :loading="false"
                          :field="'postalCode'" :placeholder="$t('postalCode')"></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label v-t="'city'"></label>
                      <div class="inf-font">
                        <TextField :value="selectedLocation.city" @emitUpdate="handleLocation"
                          :field="'city'" :isEdit="true"></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label style="text-transform: capitalize">{{ $t(geonameAdmin1Description) }}</label>
                      <div class="inf-font">
                        <AutoComplete :value="selectedLocation.geonameRegionCode" :items="dropdownRegions"
                          :field="'geonameRegionCode'" @emitUpdate="handleLocation" :isEdit="true">
                        </AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label style="text-transform: capitalize">{{ $t(geonameAdmin2Description) }}</label>
                      <div class="inf-font">
                        <AutoComplete :value="selectedLocation.geonameProvinceCode"
                          :items="dropdownProvinces" :field="'geonameProvinceCode'"
                          @emitUpdate="handleLocation" :isEdit="true"></AutoComplete>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      <label v-t="'address'"></label>
                      <div class="inf-font">
                        <TextField :value="selectedLocation.address" @emitUpdate="handleLocation"
                          :field="'address'" :isEdit="true"></TextField>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <div>
                      <label v-t="'contactName'"></label>
                      <div class="inf-font">
                        <TextField @emitUpdate="handleLocation" class="address-text" clearable
                          :isEdit="true" :value="selectedLocation.name" autocomplete="nope" :loading="false"
                          :field="'name'" :placeholder="$t('contactName')"></TextField>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="dialog = false" v-t="'close'"></v-btn>
                <v-btn color="primary" text @click="saveLocation" v-t="'confirm'"></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" md="6">
          <v-row>
            <!-- INVOICING -->
            <v-col cols="12" sm="12">
              <v-switch v-model="clone.hasDifferentInvoicingCustomer" :label="$t('invoiceToDifferentCustomer')" @change="changedHasDifferentInvoicingCustomer"></v-switch>
            </v-col>
            <v-col cols="12" md="12" v-if="clone.hasDifferentInvoicingCustomer">
              <v-autocomplete clearable outlined :label="$t('invoicingCustomer', {r:''})" v-model="clone.invoicingCustomerId" :items="dropdownAllCustomers" @change="changedInvoicingCustomer"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" v-if="clone.hasDifferentInvoicingCustomer">
              <v-autocomplete clearable outlined :label="$t('location')" v-model="clone.invoicingCustomerLocationId" :items="invoicingCustomerLocationsDropdown"></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" md="6">
          <v-row>
            <!-- SHIPPING -->
            <v-col cols="12" sm="12">
              <v-switch v-model="clone.hasDifferentShippingCustomer" :label="$t('shipToDifferentCustomer')" @change="changedHasDifferentShippingCustomer"></v-switch>
            </v-col>
            <v-col cols="12" md="12" v-if="clone.hasDifferentShippingCustomer">
              <v-autocomplete clearable outlined :label="$t('shippingCustomer')" v-model="clone.shippingCustomerId" :items="dropdownAllCustomers" @change="changedShippingCustomer"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" v-if="clone.hasDifferentShippingCustomer">
              <v-autocomplete clearable outlined :label="$t('location')" v-model="clone.shippingCustomerLocationId" :items="shippingCustomerLocationsDropdown"></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="closeCloneOrder" v-t="'close'"></v-btn>
      <v-btn color="primary" text @click="cloneOrder" v-if="isClone" v-t="'clone'">CLONE</v-btn>
      <v-btn color="primary" text @click="updateOrder" v-if="!isClone" v-t="'update'">UPDATE</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {call, get, sync} from "vuex-pathify";

export default {
  name: "UpdateOrderHeader",
  props: {
    order: {type: Object, required: true},
    isClone: { type: Boolean }
  },
  components: {},
  data: () => ({
    clone: {},
    customers: [],
    customersDropdown: [],
    customerLocationsDropdown: [],
    invoicingCustomerLocationsDropdown: [],
    shippingCustomerLocationsDropdown: [],
    dialog: false,
    originalPostalCodes: [],
    isLoadingPostalCodes: false,
    selectedLocation: {
      geonameCountryCode: null,
      postalCode: '',
      geonameProvinceCode: null,
      geonameRegionCode : null,
      geonameAdmin1Description: null,
      geonameAdmin2Description: null,
    },
    mappedPostalCodes: [],
  }),
  created() {
    this.clone = {...this.order};
    this.initAgentCollection();
    this.initPriceCatalogCollection();
    this.initCustomerCollection();
    this.reloadCustomers();
    this.reloadLocations();
    this.reloadInvoicingLocations();
    this.reloadShippingLocations();
    this.initCountryCollection();
  },
  methods: {
    changedAgent: function () {
      this.reloadCustomers();
      this.clone.customerId = null;
      this.clone.customerLocationId = null;
      this.clone.invoicingCustomerId = null;
      this.clone.invoicingCustomerLocationId = null;
      this.clone.shippingCustomerId = null;
      this.clone.shippingCustomerLocationId = null;
    },
    changedCustomer: function () {
      if (!!this.clone.customerId) {
        let customer = this.customers.find(el => el.id == this.clone.customerId);
        this.clone.invoicingCustomerId = customer.defaultInvoicingCustomerId || null;
        this.clone.shippingCustomerId = customer.defaultShippingCustomerId || null
        this.clone.vatPercentageId = customer.defaultVatRate || this.order.vatPercentageId;
        this.clone.paymentTermId = customer.defaultPaymentTermId || this.order.paymentTermId;
        this.hasDifferentShippingCustomer = !!customer.defaultShippingCustomerId;
        this.hasDifferentInvoicingCustomer = !!customer.defaultInvoicingCustomerId;
        this.clone.hasDifferentShippingCustomer = !!customer.defaultShippingCustomerId;
        this.clone.hasDifferentInvoicingCustomer = !!customer.defaultInvoicingCustomerId;
        this.reloadLocations();
        this.changedInvoicingCustomer();
        this.changedShippingCustomer();
      } else {
        this.clone.customerLocationId = null
      }
      this.reloadPriceCatalog();
    },
    changedInvoicingCustomer: function () {
      if (!!this.clone.invoicingCustomerId) this.reloadInvoicingLocations();
      else {
        this.clone.invoicingCustomerLocationId = null
      }
    },
    changedShippingCustomer: function () {
      if (!!this.clone.shippingCustomerId) this.reloadShippingLocations();
      else {
        this.clone.shippingCustomerLocationId = null
      }
    },
    reloadCustomers: function () {
      if (!!this.clone.agentId) {
        this.getCustomersForNewOrder({agentId: this.clone.agentId, familyId: this.clone.familyId})
        .then((res) => {
          this.customers.length = this.customersDropdown.length = 0;
          this.customers.push(...res);
          this.customersDropdown.push(...res.map(el => ({text: `${el.code} - ${el.businessName}`, value: el.id})))
        })
      }
    },
    reloadPriceCatalog: function () {
      if (!!this.clone.customerId) {
        this.apiCustomersIdPriceCatalogFamilyIdGet({familyId: this.clone.familyId, customerId: this.clone.customerId})
        .then((result) => {
          this.clone.priceCatalogId = result.find(x => !!x.isDefault)?.priceCatalogId || this.priceCatalogDropdown[0]?.value || null;
        });
      }
    },
    reloadLocations: function () {
      if (!!this.clone.customerId) {
        this.apiCustomersIdLocationsGet(this.clone.customerId)
        .then((res) => {
          this.customerLocationsDropdown = res.map((item) => ({
            text: `${item.name? `${item.name} - ` : ``}${item.address}`,
            value: item.id
          }));
          this.clone.customerLocationId = (res.find(x => !!x.default) || {}).id || null;
        });
      }
    },
    reloadInvoicingLocations: function () {
      if (!!this.clone.invoicingCustomerId) {
        this.apiCustomersIdLocationsGet(this.clone.invoicingCustomerId)
        .then((res) => {
          this.invoicingCustomerLocationsDropdown = res.map((item) => ({
            text: `${item.name? `${item.name} - ` : ``}${item.address}`,
            value: item.id
          }));
          this.clone.invoicingCustomerLocationId = (res.find(x => !!x.default) || {}).id || null;
        });
      }
    },
    reloadShippingLocations: function () {
      if (!!this.clone.shippingCustomerId) {
        this.apiCustomersIdLocationsGet(this.clone.shippingCustomerId)
        .then((res) => {
          this.shippingCustomerLocationsDropdown = res.map((item) => ({
            text: `${item.name? `${item.name} - ` : ``}${item.address}`,
            value: item.id
          }));
          this.clone.shippingCustomerLocationId = res[0]?.id || null;
        });
      }
    },
    changedHasDifferentInvoicingCustomer: function () {
      if (!this.clone.hasDifferentInvoicingCustomer) {
        this.invoicingCustomerId = null;
        this.invoicingCustomerLocationId = null;
        this.clone.invoicingCUstomerId = null;
        this.clone.invoicingCustomerLocationId = null;
      }
    },
    changedHasDifferentShippingCustomer: function () {
      if (!this.clone.hasDifferentShippingCustomer) {
        this.shippingCustomerId = null;
        this.shippingCustomerLocationId = null;
        this.clone.shippingCustomerId = null;
        this.clone.shippingCustomerLocationId = null;
      }
    },
    cloneOrder: function () {
      this.apiOrderIdDuplicatePost(this.clone.id)
      .then((clonedOrder) => {
        clonedOrder.customerId = this.clone.customerId;
        clonedOrder.customerLocationId = this.clone.customerLocationId;
        clonedOrder.invoicingCustomerId = this.clone.invoicingCustomerId;
        clonedOrder.invoicingCustomerLocationId = this.clone.invoicingCustomerLocationId;
        clonedOrder.shippingCustomerId = this.clone.shippingCustomerId;
        clonedOrder.shippingCustomerLocationId = this.clone.shippingCustomerLocationId;
        clonedOrder.vatPercentageId = this.clone.vatPercentageId;
        clonedOrder.paymentTermId = this.clone.paymentTermId;
        clonedOrder.priceCatalogId = this.clone.priceCatalogId;

        clonedOrder.orderDate = `${this.$moment(this.clone.orderDate).startOf('day').format("YYYY-MM-DDThh:mm:00.000")}Z`;
        clonedOrder.estimatedDeliveryDate = `${this.$moment(this.clone.estimatedDeliveryDate).startOf('day').format("YYYY-MM-DDThh:mm:00.000")}Z`;

        this.editOrder(clonedOrder)
        .then(() => {
          this.closeCloneOrder();
          this.initOrderCollection(true);
        });
      });
    },
    updateOrder: function() {
      this.editOrder(this.clone)
      .then(() => {
        this.closeCloneOrder();
        this.initOrderCollection(true);
      })
    },
    closeCloneOrder: function () {
      this.$emit("closeCloneOrderDialog");
    },
    saveLocation: function () {
      this.selectedLocation.country = this.countries?.find((el) => el.iso == this.selectedLocation.geonameCountryCode)?.country ||
        this.selectedLocation.country;
      if (typeof this.selectedLocation.postalCode == "number") {
        let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
        this.selectedLocation.city = postalCode.placeName;
        this.selectedLocation.geometry = `{ "type" : 'Point', "coordinates" : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
        this.selectedLocation.geonameRegionCode =
          this.selectedLocation.geonameRegionCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
        this.selectedLocation.geonameProvinceCode =
          this.selectedLocation.geonameProvinceCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
        this.selectedLocation.postalCode = postalCode.postalCode;
      }
      this.selectedLocation.locationNumber = 0;
      this.selectedLocation.active = true;
      this.selectedLocation.default = false;
      this.selectedLocation.visibility = true;
      this.customerLocationsPost({ ...this.selectedLocation, customerId: +(this.clone.customerId) })
      .then(() => this.apiCustomersIdLocationsGet(this.clone.customerId))
      .then((res) => {
        this.clone.hasDifferentShippingCustomer = true;
        this.clone.shippingCustomerId = this.clone.customerId;
        this.clone.shippingCustomerLocationId = res[0]?.id;
        this.reloadShippingLocations();
        this.customerLocationsDropdown = res.map(item => ({ text: `${item.name? `${item.name} - ` : ``}${item.address}`, value: item.id }));
        this.dialog = !this.dialog;
      });
    },
    handleLocation: function (obj) {
      this.selectedLocation[obj.field] = obj.value;
      switch (obj.field) {
        case "geonameCountryCode":
          if (!!this.selectedLocation.geonameCountryCode) {
            this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
            if (!!this.selectedLocation.geonameRegionCode)
              this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          }
          break;
        case "geonameRegionCode":
          if (!!this.selectedLocation.geonameRegionCode)
            this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          break;
        case "postalCode":
          if (!obj.value || obj.value.length < 4) this.mappedPostalCodes.length = 0;
          else if (!!obj.value && obj.value.length >= 4) {
            this.isLoadingPostalCodes = true;
            window.clearTimeout(this.timer);
            this.timer = window.setTimeout(() => {
              this.apiGeonamePostalCodeGet({
                countryIsoCode: this.selectedLocation.geonameCountryCode,
                postalCode: obj.value,
              }).then((response) => {
                this.isLoadingPostalCodes = false;
                this.selectedLocation.postalCode = response[0]?.id || this.selectedLocation.postalCode;
                this.originalPostalCodes.push(...response);

                if (typeof this.selectedLocation.postalCode == "number") {
                  let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
                  this.selectedLocation.geometry = `{ type : 'Point', coordinates : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
                  this.selectedLocation.geonameRegionCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
                  this.selectedLocation.geonameProvinceCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
                }

                if (!!this.selectedLocation.geonameCountryCode)
                  this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
                if (!!this.selectedLocation.geonameRegionCode)
                  this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);

                let mappedResponse = response.map((el) => ({
                  text: `${el.postalCode} - ${el.placeName}`,
                  value: el.id,
                }));
                this.mappedPostalCodes.push(...mappedResponse);
                this.selectedLocation.city = this.originalPostalCodes.find(
                  (el) => el.id == this.selectedLocation.postalCode
                )?.placeName;
              });
            }, 2000);
          }

          break;
        // default:
      }
    },
    clearPostalCodes: function () {
      this.mappedPostalCodes.length = 0;
      this.selectedLocation.postalCode = null;
      this.selectedLocation = { geonameCountryCode: this.selectedLocation.geonameCountryCode, active: true, default: true, visibility: true };
    },
    ...call("customers/*"),
    ...call("customerLocations/*"),
    ...call("agents/*"),
    ...call("orders/*"),
    ...call("priceCatalogs/*"),
    ...call("geoname/*"),
    ...call("customerLocations/*")
  },
  computed: {
    agentsDropdown: get("agents/dropdownActiveAgents"),
    agents: get("agents/agents"),
    priceCatalogDropdown: get("priceCatalogs/dropdownPriceCatalogsForOrderEdit"),
    priceCatalogs: sync("priceCatalogs/priceCatalogs"),
    dropdownAllCustomers: get("customers/dropdownAllCustomers"),
    countries: get("geoname/countries"),
    dropdownCountries: get("geoname/dropdownCountriesIso"),
    dropdownRegions: get("geoname/dropdownRegions"),
    dropdownProvinces: get("geoname/dropdownProvinces"),
    geonameAdmin1Description: get("tenantConfiguration/geonameAdmin1Description"),
    geonameAdmin2Description: get("tenantConfiguration/geonameAdmin2Description"),
  }
}
</script>


