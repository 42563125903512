<template>
    <div style="width:5%">
        <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>
        <v-text-field
            v-else-if="isEdit && !loading"
            @blur="clickOff"
            @focus="editIn"
            :value="value"
            type="number"
            :readonly="disabled"
            autofocus
            :min="minValue"
            :max="maxValue"
            :step="step"
          ></v-text-field>
        <h3 v-else @click="clickToEdit" > {{ this.value }} </h3>
    </div>
</template>

<script>
    export default {

        name : "NumericField",
        props: {
            disabled: Boolean,
            value:Number,
            maxValue:Number,
            minValue:Number,
            step:Number,
            loading: Boolean
        },
        data: () => ({
            isEdit: false,
          
        }),
        methods: {
           clickToEdit: function() {
                if (!this.disabled) 
                    this.isEdit = true;
           },
           editIn: function() {
               this.$emit('onEdit')
            },
           clickOff: function() {
                this.isEdit= false;
                this.$emit('outEdit')
           }
        }
    }
</script>