<template>
    <material-card :heading="title" color="primary" :key="getKey()">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="12">
                    <chart ref="chart"
                        :legend-position="'top'"
                        :chart-area-background="''"
                        :series-defaults-type="'line'"
                        :series-defaults-style="'smooth'"
                        :value-axis-labels-format="'{0:N0}'"
                        :value-axis-line-visible="false"
                        :value-axis-axis-crossing-value="'-10'"
                        :category-axis-categories="xAxis"
                        :category-axis-major-grid-lines-visible="false"
                        :category-axis-labels-rotation="'auto'"
                        :tooltip-visible="true"
                        :tooltip-format="'{0}'"
                        :tooltip-template="tooltiptemplate"
                        :theme="'sass'"
                    >
                        <chart-series-item v-for="(serie, i) in getSeries()" :color="seriesColors[i]" :key="i" :name="serie.name" :data="serie.collection" :markers-visible="true"></chart-series-item><!--  :k-style="'step'" -->
                        <chart-series-item v-for="(serie, i) in getSteps()" :color="serie.color" :key="1000 + i" :k-style="'step'" :name="serie.name" :data="serie.collection" :markers-visible="true"></chart-series-item>
                    
                        <chart-series-item 
                            :dash-type="'dot'" 
                            :color="seriesColors[getSeries().length -1]" 
                            key="present-day" 
                            name="present-day" 
                            :data="getCurrentMonth().collection" 
                            :markers-visible="true"
                        ></chart-series-item>
                    
                    </chart>
                </v-col>
                <v-col cols="12" md="12">
                    <v-btn-toggle v-model="toggleButton" class="float-right" dense background-color="primary" multiple>
                        <v-btn @click="handleReduce()">
                            <v-icon>mdi-calendar-month</v-icon>
                        </v-btn>
                        <v-btn @click="handleTable()">
                            <v-icon>mdi-table-eye</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
                <v-col cols="12" md="12" v-if="showTable">
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center"></th>
                                <th style="text-align:right;" v-bind:key="i" v-for="(head, i) in getHeaders()">{{ head }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in getRows()" :key="i">
                                <td class="text-center">{{ row }}</td>
                                <td style="text-align:right;" v-bind:key="x"  v-for="(value, x) in getValues(i)">
                                    <span v-if="!isOdd(x)">€{{getFormattedPrice(value) }}</span>
                                    <span v-else-if="!!value" :class="colorized(value)" v-html="getFormattedPercentage(value)"></span>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
    </material-card>
</template>

<script>
import { Chart, ChartSeriesItem } from '@progress/kendo-charts-vue-wrapper';

export default {
    name: 'MFLineChart',
    props: {
        title: { type: String, default: "" },
        xAxis: { type: [Array, Function], required: true },
        /**
         * collections : [{ name : "", collection : []}, { name : "", collection : []}, { name : "", collection : []}]
         *  */ 
        collections: { type: [Array, Function], required: true },
        steps: { type: [Array, Function], required: false },
    },
    components: {
        'chart': Chart,
        'chart-series-item': ChartSeriesItem
    },
    data: () => ({
        tooltiptemplate: "#= series.name #: #= kendo.format('{0:N2} M€', ((value || 0)/1000000)) #",
        seriesColors: [ "#ff7d36", "#36ffa9",  "#369fff", "#9836ff", "#ff36b0", "#fdff36" ],
        showTable : true,
        showIncrementalValues: false,
        toggleButton: [],
    }),
    methods: {
        getHeaders: function() {
            let response = [];
            for(let i = 0; i < this.getYears().length; i++){
                response.push(this.getYears()[i]);
                if (i < this.getYears().length -1) response.push("");
            }
            return response;
        },
        getYears: function() {
            return this.collections.map(el => el.name);
        },
        getRows: function() {
            return this.xAxis;
        },
        getValues: function(row) {
            let data = this.collections.map(el => el.collection[row]),
                response = [];
            for(let i = 0; i < data.length; i++){
                if (0 < i) response.push(Math.round((data[i]? (data[i]-data[i-1])/data[i-1] : 0)*100)/100);
                response.push(data[i]);
            }
            return response;
        },
        getFormattedPercentage: function(value) {
            return value < 0? `${Math.round(value*100)} % &#9660;` : `+${Math.round(value*100)} % &#9650;`;
        },
        isOdd: function(value) {
            return value % 2 !== 0;
        },
        getFormattedPrice: function(value) {
            return `${this.$numerals(value, `0,0`)}`.replaceAll(`,`, `.`);
        },
        getSeries: function() {
            let currentYear = this.$moment().year();
            let response = !this.showIncrementalValues? 
                // mostro la collezione grouped come previsto 
                JSON.parse(JSON.stringify(this.collections)).map(el => {
                    if (el.name == currentYear) el.collection.length --;
                    return el;
                })
                // in alternativa clono la collezione e ne mappo gli elementi 
                // modificando i valori contenuti nelle subcollections con il valore attuale (+ la somma dei precenti) 
                : JSON.parse(JSON.stringify(this.collections)).map(el => {
                    el.collection = el.collection.map((elem, index) => {
                        return index == 0? +elem : (+elem + el.collection.reduce((a, b, i) => { a = a || 0; a += i < index ? +b : 0; return a; }, 0))
                    });
                    if (el.name == currentYear) el.collection.length --;
                    return el;
                });
            return response;
        },
        getCurrentMonth: function() {
            let currentYear = this.$moment().year();
            let currentObj = JSON.parse(JSON.stringify(this.collections)).find(el => el.name == currentYear);
            if(!currentObj) return {};
            let collection = !this.showIncrementalValues? 
                currentObj.collection
                : currentObj.collection = currentObj.collection.map((elem, index) => index == 0? +elem : (+elem + currentObj.collection.reduce((a, b, i) => { a = a || 0; a += i < index ? +b : 0; return a; }, 0)));
            collection = collection.map((el, i) => { if(i == currentObj.collection.length -1 || i == currentObj.collection.length -2) return el; return null; });
            let response = {
                name : currentObj.name,
                collection : collection
            }
            return response;
        },
        getSteps: function() {
            const response = [];
            if (!this.showIncrementalValues) {
                response.length = 0;
                // this.steps.forEach(element => {
                //     let partial = Math.round(element.value / this.xAxis.length);
                //     let collection = [];
                //     this.xAxis.forEach(el => collection.push(partial));
                //     response.push({ ...element, collection : collection });
                // });
            } else {
                response.length = 0;
                this.steps.forEach((element, i) => {
                    // let partial = Math.round(element.value / this.xAxis.length);
                    let collection = [];
                    this.xAxis.forEach(el => collection.push(element.value));
                    // this.xAxis.forEach((el, x) => collection.push(partial * (x+1)));
                    response.push({ ...element, collection : collection });
                });
            }
            return response;
        },
        getKey: function() {
            return Math.floor(Math.random() * 99999);
        },
        handleReduce: function() {
            this.showIncrementalValues = !this.showIncrementalValues;
        },
        handleTable: function() {
            this.showTable = !this.showTable;
        },
        getDiff: function(year, month) {
            return this.diff[year][month];
        },
        colorized: function(value) {
            return +value >= 0? 'greenText' : "redText";
        },
    },
    computed : {
        diff : function() {
            let copy = JSON.parse(JSON.stringify(this.collections)).reduce((a, b) => { a[b.name] = b.collection; return a; }, {});
            let response = JSON.parse(JSON.stringify(this.collections)).reduce((a, b) => { a[b.name] = b.collection; return a; }, {});           
            Object.keys(response).map((el, i) => {
                response[el] = i == 0? 
                    response[el].map(x => null) 
                    : response[el].map((x, j) => {
                        let newValue = Math.round((((+x) - +(copy[`${+el-1}`][j]))/+x)*100)/100; 
                        return newValue;
                        // (Math.round((+x - +response[`${+el-1}`][j]) / +x)*100 )/100;
                    });
            })            
            return response;
        }
    }
}
</script>
<style>
    .greenText {
        color: green;
    }
    .redText {
        color: red;
    }
</style>
