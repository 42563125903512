<template>
    <v-chip :color="getColor()" dark>
        <span v-if="showValue">{{ actual }} / {{ target }}</span>
        <span v-else style="min-width:10px;"></span>
    </v-chip>
</template>

<script>
export default {
    name: 'Light',
    props: {
        actual: { type: Number, required: true },
        target: { type: Number, required: true },
        showValue: { type: Boolean, required: false, default: true }
    },
    methods: {
        getColor() {
            return this.actual-this.target >= 0? 'success' : 'error';
        }
    }
}
</script>