<template>
    <div>
        <v-progress-linear indeterminate color="primary" v-if="loading" ></v-progress-linear>
        <v-text-field v-else-if="isEdit && !loading" dense v-model="internalValue" type="number" :min="minValue" :max="maxValue"></v-text-field>
        <p v-else > {{ this.value||  this.text }} </p>
     </div>
</template>

<script>
    export default {

        name : "NumericField",
        props: {
            disabled:Boolean,
            value:Number,
            maxValue:Number,
            minValue:Number,
            loading: Boolean,
            isEdit: Boolean,
            field:String
        },
        data: () => ({
            text: '----',
        }),
        computed: {
            internalValue: {
                get: function() {
                    return this.value;
                },
                set: function(newValue) {
                   this.$emit('emitUpdate', { field: this.field, value : newValue})
                }
            },
        },
    }
</script>