<template>
<div>
    <v-btn icon raised color="secondary" @click="trigger">
                <v-icon dark>mdi-tag-plus-outline</v-icon>
            </v-btn>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Customer Tags</v-card-title>
        <v-card-text style="overflow-y: scroll; height: 70vh;" class="mt-3">
            <v-row v-for="row in getIterableTagCollection()" :key="row">
                <v-col cols="12" sm="12">
                    <p style="font-size: 1.15rem; color: black;">{{ row.replaceAll("_", " ") }}</p>
                    <v-tooltip bottom v-for="(el, i) in getValues(row)" :key="i" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                class="mx-1 my-1" 
                                rounded color="primary" 
                                :outlined="!isSelected(el)" value="left" 
                                v-on="on"
                                v-bind="attrs"
                                @click="select(el)">
                            {{ el.value }}
                            </v-btn>
                        </template>
                        <span>{{ el.description ?? '' }}</span>
                    </v-tooltip>
                    <v-btn class="mx-2" fab dark small color="error" v-if="selectedRow(row)" @click="remove(row)">
                        <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
import {call, get, sync} from "vuex-pathify";
export default {
    name: "MFCustomerTags",
    props: {
        customerId: {
            type: String || Number,
            required: true
        }
    },
    data: () => ({
        dialog: false,
        tags: [],
        model: {}
    }),
    computed: {
        tagCollection: sync("tags/tags"),
    },
    methods: {
        initialize: function(emit = true) {
            if(emit) this.$emit("updatedCollection", true);
            this.initTagCollection(false);
            this.apiCustomersIdTagsGet(this.customerId)
            .then((response) => {
                this.tags.length = 0;
                this.tags.push(...response);
            });
        },
        trigger(){
            this.initialize();
            this.dialog = !this.dialog;
        },
        getIterableTagCollection: function() {
            return this.tagCollection.reduce((a, b, i) => {
                a = a || [];
                if(!a.find(el => el == b.key)) a.push(b.key);
                return a;
            }, [])
        },
        getValues: function(key) {
            return this.tagCollection.filter(el => el.key == key);
        },
        isSelected: function(elem) {
            return !!this.tags.find(el => el.tag.id == elem.id);
        },
        selectedRow: function(row) {
            return !!this.tags.find(el => el.tag.key == row);
        },
        select: function(tag) {
            this.apiCustomersIdTagsTagIdPut({ id: +this.customerId, tagId: +tag.id})
            .catch(console.error)
            .finally(this.initialize);
        },
        remove: function(row) {
            this.apiCustomersIdTagsTagIdDelete({ id: +this.customerId, tagId: +(this.tags.find(el => el?.tag?.key == row)?.tag?.id)})
            .catch(console.error)
            .finally(this.initialize);
        },
        ...call("customers", ["apiCustomersIdTagsGet", "apiCustomersIdTagsTagIdPut", "apiCustomersIdTagsTagIdDelete"]),
        ...call("tags", ["initTagCollection"]),
    },
    created(){
        this.initialize(false);
    }

}
</script>

<style>
</style>
