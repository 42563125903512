<template>
    <div style="width:100%;">
        <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
        <v-textarea rows="1" block v-else-if="isEdit && !loading" filled v-model="internalValue" :placeholder="placeholder"></v-textarea>
        <p  v-else > {{ this.value ||  this.text }} </p>
    </div>
</template>

<script>
    export default {
        name : "TextAreaField",
        props: {
            disabled: Boolean,
            value:String,
            loading: Boolean,
            isEdit: Boolean,
            field:String,
            placeholder:{ type: String, default: "" },
        },
        data: () => ({
            text: '----',
        }),
        computed: {
            internalValue: {
                get: function() {
                    return this.value;
                },
                set: function(newValue) {
                   this.$emit('emitUpdate', { field: this.field, value : newValue})
                }
            },
        },
        inject: {
            theme: {
                default: { isDark: false },
            },
        },
    }
</script>
