<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="1200"
      v-model="show"
      persistent
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-t="'orderRows'"></v-toolbar-title>
        <v-switch class="ml-8" dark v-model="explodeGroupsCollections" :label="$t('explodeGroupCollection')" hide-details @change="emitExplodeEvent()"></v-switch>
      </v-toolbar>
        <OrderRowsTable :orderRows="orderStatisticalRowCollection" :explodeGroupsCollections="explodeGroupsCollections"></OrderRowsTable>
    </v-card>
  </v-dialog>
</template>

<script>

import {sync} from "vuex-pathify";
import OrderRowsTable from '../../components/table/OrderRowsTable.vue';

export default {
  components: {
    OrderRowsTable,
  },
  name: "ModalOrderRows",
  data: () => ({
    explodeGroupsCollections: false,
    show: true,
  }),

  computed: {
    orderStatisticalRowCollection: sync("statistics/orderStatisticalRowCollection"),
  },
  methods: {
    close() {
      this.orderStatisticalRowCollection = [];
      this.$emit('close');
    },
    emitExplodeEvent() {
      this.$emit('explode', this.explodeGroupsCollections);
    }
  },
}
</script>
<style>

</style>
