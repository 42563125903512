<template>
    <div style="display:flex; width: 100%">
      <v-col cols="12" sm="4">
        <v-select
            :label="$t('family')"
            :items="dropdownFamilies"
            v-model="selectedFamilies"
            @change="onFamiliesChange"
            outlined
            multiple
            clearable
            dense
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
            :label="$t('group')"
            :items="getDropdownGroupsFiltered()"
            v-model="selectedGroups"
            :disabled="disabledGroupsSelection"
            @change="onGroupsChange"
            outlined
            multiple
            clearable
            dense
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
            :label="$t('collection')"
            :items="getDropdownCollectionsFiltered()"
            :disabled="disabledCollectionsSelection"
            v-model="selectedCollections"
            @change="onCollectionsChange"
            outlined
            multiple
            clearable
            dense
        ></v-select>
      </v-col>
    </div>
  </template>
  
  <script>
  import {call, get} from 'vuex-pathify';
  
  export default {
    name: "FamilyGroupCollectionSelector",
    props: {
      families : { type: Array, required: false, dafault: null },
      groups : { type: Array, required: false, dafault: null },
      collections : { type: Array, required: false, dafault: null }
    },
    data: () => ({
      selectedFamilies: null,
      selectedGroups: null,
      selectedCollections: null,
      disabledFamiliesSelection: false,
      disabledGroupsSelection: true,
      disabledCollectionsSelection: true,
    }),
    computed: {
      dropdownFamilies: get("families/dropdownFamilies"),
      dropdownGroups: get("groups/dropdownGroups"),
      dropdownCollections: get("collections/dropdownCollections"),
    },
    mounted() {
      if (!this.dropdownFamilies.length) this.familyGet().then(() => this.$emit('familiesLoaded'));
      else this.$emit('familiesLoaded');
      
      this.selectedFamilies = this.families;
      this.selectedGroups = this.groups;
      this.selectedCollections = this.collections;
  
    },
    methods: {
      onFamiliesChange() {
        if (!!this.selectedFamilies) {
          this.disabledGroupsSelection = false;
          this.itemGroupGet().then(() => this.$emit('groupsLoaded'));
        } else {
          this.disabledGroupsSelection = true;
          this.disabledCollectionsSelection = true;
          this.selectedGroups = null;
          this.selectedCollections = null;
        }
        this.emitUpdate();
      },
  
      onGroupsChange() {
        if (!!this.selectedGroups) {
          this.disabledCollectionsSelection = false;
          this.apiItemCollectionGet()
          .then(() => this.$emit('collectionsLoaded'));
        } else {
          this.disabledCollectionsSelection = true;
          this.selectedCollections = null;
        }
        this.emitUpdate();
      },
  
      onCollectionsChange() {
        this.emitUpdate();
      },
  
      emitUpdate() {
        this.$emit("update:families", this.selectedFamilies);
        this.$emit("update:groups", this.selectedGroups);
        this.$emit("update:collections", this.selectedCollections);
      },

      getDropdownGroupsFiltered() {
        return !!this.selectedFamilies?.length? this.dropdownGroups.filter(el => this.selectedFamilies?.includes(el.familyId)) : [];
      },
      getDropdownCollectionsFiltered() {
        return !!this.selectedFamilies?.length && !!this.selectedGroups?.length? this.dropdownCollections.filter(el => this.selectedGroups?.includes(el.itemGroupId)) : [];
      },
  
      clear() {
        this.selectedFamilies = null;
        this.selectedGroups = null;
        this.selectedCollections = null;
        this.disabledGroupsSelection = true;
        this.disabledCollectionsSelection = true;
        this.emitUpdate();
      },
      ...call("families/*"),
      ...call("groups/*"),
      ...call("collections/*"),
    }
  }
  </script>